import wrapper from './wrapper';
import UAParser from 'ua-parser-js';
import { ErrorType } from '../utils/enums';
import {store} from '../../app/store';

export default class GeneralClient {

  /* validate discord user */
  public static async validateDiscordUser(username: string): Promise<any> {
  
    const query = `
    query ($username: String!) {
      validateDiscordUser(username: $username) {
        userId,
        isValid
      }
    }    
    `;

    let {validateDiscordUser} :any = await wrapper.grapql(query,{username});
    
    return {isValid: validateDiscordUser.isValid, userId: validateDiscordUser.userId};
  }
  /* get access based on area and user */
  public static async reportError(desc: string, type: ErrorType, params:any = {}): Promise<boolean> {
    
    const route = store.getState().router.location.pathname.slice(1);
    const meta = JSON.stringify(new UAParser().getResult());
    params = JSON.stringify(params);

    /* access list query */
    const mutation = `mutation ($route: String, $query: String, $params: AWSJSON, $meta: AWSJSON,
                               $trace: String, $time:String, $type:ErrorType ) {
      FrontEndErrorReporter(route: $route, query: $query, params: $params, meta: $meta,
                          trace: $trace, time:$time, type:$type)
    }`;

    /* execute with query with map */
    let result :any = await wrapper.grapql(mutation,{
      route,
      query: type === ErrorType.API?desc.split('|')[0]:"",
      params,
      meta,
      trace: type === ErrorType.UNHANDLED?desc:desc.split('|')[1],
      type,
      time: new Date().toString()
    });
    return true;
  }

}