import APIWrapper from "../../../../lib/api/wrapper";
import {TopicBase, pfx,
  DirectMsgType,
  DirectTokenRefresedMsg,
  SystemRefreshAccessMsg,
  SystemMsgType} from "./pubsubEnums";
import stringHash from '@sindresorhus/string-hash';
import { store } from '../../../../app/store';

export default class Pub {

  /* the subscriptions set */
  public static sendTokenRefreshedMsg(token: string): any {
    /* get user */
    const {user} = store.getState().auth;
    /* the message */
    const msg:DirectTokenRefresedMsg = {
      type: DirectMsgType.TOKEN_REFRESHED,
      hash: stringHash(token)
    } 
    /* publish messages directly */
    APIWrapper.publish(`${pfx}${TopicBase.DIRECT}/${user?.sub}`, msg);
  }
  /* the subscriptions set */
  public static sendRefreshAccessMsg(): any {
    /* the message */
    const msg:SystemRefreshAccessMsg = {
      type: SystemMsgType.REFRESH_ACCESS
    }
    /* publish messages directly */
    APIWrapper.publish(`${pfx}${TopicBase.SYSTEM}`, msg);
  }
}