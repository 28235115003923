import React, { useEffect, useState } from 'react';
import { Card, Switch, Form, Input, Select, Tag, 
  Space, InputNumber, Button, Upload, Row, Col, Badge, Divider, Radio } from 'antd';
  
import MDEditor from '@uiw/react-md-editor';
import {  CourseDataType, CourseInput, CourseLevelType,CourseModuleElementInput, 
          CourseModuleInput, Language, LanguageMap, ThumbnailStatus, VideoStatus } from '../../../../../models';
import { HubButton, HubGradientText, HubPopConfirm } from '../../../../commons/misc/front/hubComponents';
import {PictureOutlined, VideoCameraOutlined, CheckCircleOutlined, PlusOutlined, DeleteOutlined} from '@ant-design/icons';
import videoIcon from '../../../../../media/images/video.png';
import { CanvasUtils, Helpers } from '../../../../../lib/utils';
import {CourseClient} from '../../../../../lib/api';
import { Attention } from '../../../../../lib/utils/notification';
import { FileClient } from '../../../../../lib/api';
import { block } from '../../../../commons/misc/front/miscSlice';
import ImageEditModal from '../../../../commons/misc/front/imageEditModal';


type CourseDetailProps = {
  data: any,
  type: CourseDataType,
  onFinish: (data:any, type: CourseDataType, existing: boolean)=>void,
  onCancel: ()=>void
}

const { Option } = Select;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const colorMap:any = {
  [CourseDataType.course]: "magenta",
  [CourseDataType.module]: "green",
  [CourseDataType.element]: "geekblue"
}

const CourseDetail = ({data, type, onFinish, onCancel}: CourseDetailProps) => {

  const [lang, setLang] = useState<Language>(Language.espanol);
  const [description, setDescription] = useState<any>({es:"emtpy", en:"emtpy"});
  const [title, setTitle] = useState<any>({es:"emtpy", en:"emtpy"});
  const [subtitles, setSubtitles] = useState(new Map<string, string>());
  const [thumbnailFile, setThumbnailFile] = useState<any>([]);
  const [thumbnailSrc, setThumbnailSrc] = useState<string|null>(null);
  const [thumbnailUploading, setThumbnailUploading] = useState<boolean>(false);
  const [videoFile, setVideoFile] = useState<any>([]);
  const [videoFileUploading, setVideoFileUploading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data);
    setTitle(data.title);
    setDescription(data.description);
    decodeExistingThumbnailFile();
    decodeExistingVideoFile();
    fetchCourseSubtitles();
   }, [data]);

  const setMarkdownDescription = (desc:string) =>{
    setDescription({
      ...description,
      [lang]: desc
    })
  }

  const setTitleFromInput = (ttl:string) =>{
    setTitle({
      ...title,
      [lang]: ttl
    })
  }

  const fetchCourseSubtitles = async () =>{
    const root = Helpers.buildStoredSubtitleRoot(data, type);
    let subs = await FileClient.listDirectoryFiles(root);
    let present = new Map<string, string>();
    for(let s of subs){
      let lang = s.replace(/^.*[\\\/]/, '').split('.')[0];
      present.set(lang,s);
    }
    /* set the new map */
    setSubtitles(present);
  }
  const decodeExistingThumbnailFile = async()=>{
    if(data.thumbnail == ThumbnailStatus.None){
      setThumbnailFile([]);
      return;
    }
    /* build key */
    let key = Helpers.buildCourseMediaURL(data, type);
    let blob = await FileClient.getStoredMedia(key,true);
    let url = await FileClient.getStoredMedia(key,false);
    /* proceed to decode remote thumbnail file */
    let f = new File([blob], 'thumbnail.png', {type:"image/png"});
    /* set existing thumbnail */
    setThumbnailFile([{
      uid: key,
      name: 'thumbnail.png',
      percent: 0,
      size: f.size,
      originFileObj: f,
      thumbUrl: url,
      url: url
    }]);
  }
  const decodeExistingVideoFile = async()=>{
    if(data.video == VideoStatus.None){
      setVideoFile([]);
      return;
    }
    /* build key */
    let key = Helpers.buildCourseMediaURL(data, type, true);
    let url = await FileClient.getStoredMedia(key,false);
    /* proceed to decode remote thumbnail file */
    let f = new File([], 'source.mp4', {type:"video/mp4"});
    /* set existing thumbnail */
    setVideoFile([{
      uid: key,
      name: 'source.mp4',
      percent: 0,
      size: f.size,
      originFileObj: f,
      thumbUrl: videoIcon,
      url: url
    }]);
  }
  const onFormSubmit = async (values: any) => {
    /* split and trim tags */
    values.tags = `${values.tags}`.split(',').map((v)=>v.trim());

    /* set title and description properies */
    values = {
      ...values,
      title: title,
      description: description
    }

    let operation = data.id?"updated":"created";
    let update:any = {};
    switch(type){
      case CourseDataType.course:
        let cEntry = {...values, id: data.id} as CourseInput;
        if(data.id){
          await CourseClient.updateCourse(cEntry);
        }else{
          cEntry.id = await CourseClient.createCourse(cEntry);
        }
        update = {...data, ...cEntry};
        break;
      case CourseDataType.module:
        let mEntry = {...values, id: data.id, courseId: data.courseId} as CourseModuleInput;
        if(data.id){
          await CourseClient.updateCourseModule(mEntry);
        }else{
          mEntry.id = await CourseClient.CreateCourseModule(mEntry);
        }
        update = {...data, ...mEntry};
        break;
      case CourseDataType.element:
        let eEntry = {...values, id: data.id, courseId: data.courseId, 
                      moduleId: data.moduleId} as CourseModuleElementInput;
        if(data.id){
          await CourseClient.updateCourseModuleElement(eEntry);
        }else{
          eEntry.id = await CourseClient.CreateCourseModuleElement(eEntry);
        }
        update = {...data, ...eEntry};
        break;
    }
    /* show success */
    Attention.notifySuccess(`${type} ${operation}!`,
      `${type} ${values.title[lang]} was successfully ${operation}`
    );
    /* pull new data from remote in upper component*/
    onFinish(update, type, operation=="updated");
  };
  const onReset = () => {
    form.resetFields();
  };
  const handleThumbnailChange = ({ fileList }:any) => {
    setThumbnailFile(fileList);
    if(fileList.length == 0 && data.thumbnail != ThumbnailStatus.None){
      deleteThumbnail();
    }
  };
  const handleVideoChange = ({ fileList }:any) => {
    setVideoFile(fileList);
    if(fileList.length == 0 && data.video != VideoStatus.None){
      deleteVideo();
    }
  };
  const previewMedia = (file: any, isImage: boolean)=>{
    if(isImage){
      if(data.thumbnail == ThumbnailStatus.None){
        CanvasUtils.previewImage(file);        
      }else{
        window.open(thumbnailFile[0].url, '_blank');
      }
    }else{
      if(data.video == VideoStatus.None){
        CanvasUtils.previewVideo(file);        
      }else{
        window.open(videoFile[0].url, '_blank');
      }
    }
  }
  const updateMediaEntry = async (field:string, status: any ) =>{
      /* save state */
      switch(type){
        case CourseDataType.course:
          let cEntry = {id: data.id, [field]: status} as CourseInput;
          await CourseClient.updateCourse(cEntry);
          break;
        case CourseDataType.module:
          let mEntry = {id: data.id, courseId: data.courseId, 
                        [field]: status} as CourseModuleInput;
            await CourseClient.updateCourseModule(mEntry);
          break;
        case CourseDataType.element:
          let eEntry = {id: data.id, courseId: data.courseId, 
                        moduleId: data.moduleId, [field]: status} as CourseModuleElementInput;
            await CourseClient.updateCourseModuleElement(eEntry);
          break;
      }
  }
  const uploadThumbnail = async()=>{
    setThumbnailUploading(true);
    try{
      let {originFileObj}:{originFileObj:File} = thumbnailFile[0];
      /* build upload string */
      let key = Helpers.buildCourseMediaURL(data, type);
      await FileClient.uploadStoredMedia(key,originFileObj,"image/png");
      await updateMediaEntry('thumbnail', ThumbnailStatus.Ready);
    }catch(e:any){
      Attention.notifyError("Could not upload thumbnail!", e.message);
    }
    finally{
      setThumbnailUploading(false);
    }
    /* pull new data from remote in upper component*/
    onFinish({...data, thumbnail: ThumbnailStatus.Ready}, type, true);
    Attention.notifySuccess("Thumbnail uploaded!");
  }
  const uploadVideo = async()=>{
    setVideoFileUploading(true);
    try{
      let {originFileObj}:{originFileObj:File} = videoFile[0];
      /* build upload string */
      let key = Helpers.buildCourseMediaURL(data, type, true);
      await FileClient.uploadStoredMedia(key,originFileObj,"video/mp4");
      await updateMediaEntry('video',VideoStatus.Uploaded);
    }catch(e:any){
      Attention.notifyError("Could not upload video!", e.message);
    }
    finally{
      setVideoFileUploading(false);
    }
    /* pull new data from remote in upper component*/
    onFinish({...data, video: VideoStatus.Uploaded}, type, true);
    Attention.notifySuccess("Video uploaded!");
  }
  const deleteThumbnail = async()=>{
    setThumbnailUploading(true);
    try{
      /* build upload string */
      let key = Helpers.buildCourseMediaURL(data, type);
      await FileClient.deleteStoredMedia(key);
      await updateMediaEntry('thumbnail', ThumbnailStatus.None);
    }catch(e:any){
      Attention.notifyError("Could not delete thumbnail!", e.message);
    }
    finally{
      setThumbnailUploading(false);
    }
    /* pull new data from remote in upper component*/
    onFinish({...data, thumbnail: ThumbnailStatus.None}, type, true);
    Attention.notifySuccess("Thumbnail deleted!");
  }
  const deleteVideo= async()=>{
    setVideoFileUploading(true);
    try{
      /* build upload string */
      let key = Helpers.buildCourseMediaURL(data, type, true);
      /* get directory only */
      key = key.substring(0, key.lastIndexOf('/'));
      /* block ui while files are deleted */
      await block(async ()=>{
        await FileClient.deleteStoredDirectory(key);
        await updateMediaEntry('video', VideoStatus.None);
      });
    }catch(e:any){
      Attention.notifyError("Could not delete video!", e.message);
    }
    finally{
      setVideoFileUploading(false);
    }
    /* pull new data from remote in upper component*/
    onFinish({...data, video: VideoStatus.None}, type, true);
    Attention.notifySuccess("Video deleted!");
  }

  const uploadSubtitle = async (options: any, lang:string, desc: string)=>{
    const { onSuccess, onError, file, onProgress } = options;
    try{
      let key = Helpers.buildStoredSubtitleURL(data, type, lang, false);
      await FileClient.uploadStoredMedia(key,file,"text/vtt");
      onSuccess();
      fetchCourseSubtitles();
      Attention.notifySuccess(`${desc} subtitle uploaded!`);
    }catch(e:any){
      onError(e.message);
      Attention.notifyError(`${desc} subtitle could not be uploaded!`, e.message);
    }
  }
  const deleteSubtitle = async (lang:string, desc: string)=>{

    try{
      let key = Helpers.buildStoredSubtitleURL(data, type, lang, false);
      await FileClient.deleteStoredMedia(key);
      fetchCourseSubtitles();
      Attention.notifySuccess(`${desc} subtitle deleted!`);
    }catch(e:any){
      Attention.notifyError(`${desc} subtitle could not be deleted!`, e.message);
    }
  }

  const renderSubtitles  = ()=>{
    const langs = Object.keys(Language);
    return Object.values(Language).map((v, i)=>{
      const desc = langs[i];
      if(subtitles.has(v)){
        return <Col key={desc}>
          <HubPopConfirm onConfirm={()=>deleteSubtitle(v, desc)} title={`Confirm subtitle detetion`} okText={'delete it'} >
            <HubButton icon={<DeleteOutlined/>} size='middle' type='primary' >{desc}</HubButton>
          </HubPopConfirm>
          
        </Col>
      }else{
        return <Col key={desc}>
                <Upload listType="text" accept="text/vtt"
                      maxCount={1}
                      fileList={[]}
                      customRequest={(options)=>uploadSubtitle(options, v, desc)}>
                        <HubButton icon={<PlusOutlined/>}  size='middle' type='default' >
                          {desc}
                        </HubButton>
                </Upload>
            </Col>
      }
    }); 
  }

  /* render available languages */
  const renderAvailableLanguages = ()=>{
    let options:any = [];
    for(let k of LanguageMap.keys()){
      options.push(
        <Radio.Button key={k} value={k}>{LanguageMap.get(k)}</Radio.Button>
      );
    }
    return options;
  }
  
  return (
    <Badge.Ribbon placement='start' style={{display: data.id?'none':''}} color={colorMap[type]} text={`NEW ${type.toUpperCase()}`}>
      <Card  size="small" title={
              <>
                <Tag style={{visibility: !data.id?'hidden':'visible'}} color={colorMap[type]}>{type.toUpperCase()}</Tag>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <HubGradientText  forceBlack size={24} colors={Helpers.colorsFromString(title[lang], 4)}><strong>{title[lang].toUpperCase()}</strong></HubGradientText>
              </>
            }>
              <Form {...layout} form={form} initialValues={data} name="control-hooks" onFinish={onFormSubmit}>
                <Form.Item label="Language">
                  <Radio.Group defaultValue={lang} buttonStyle="solid" onChange={(e)=>setLang(e.target.value)}>
                    {renderAvailableLanguages()}
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Title" rules={[{ required: true }]}>
                  <Input  value={title[lang]} onChange={(evnt)=>setTitleFromInput(evnt.target.value)}/>
                </Form.Item>
                <Form.Item label="Description" valuePropName="value" rules={[{ required: true }]} data-color-mode="light">
                      <MDEditor
                        previewOptions={{
                          rehypeRewrite: (node) => {
                            if (node.type === "element" && node.tagName === "a") {
                              node.properties = { ...node.properties, target: "_blank" };
                        }}}}
                        value={description[lang]}
                        onChange={(desc:string|undefined)=>setMarkdownDescription(desc as string)}
                      />
                </Form.Item>
                <Form.Item label="Order" name="ordering" rules={[{ required: true }]}>
                    <InputNumber min={0} max={10000}/>
                  </Form.Item>
                <Form.Item name="tags" label="Tags" hasFeedback rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z]+(,\s*[a-zA-Z]+)*$/),
                    message: 'Tags must be comma separated words only.'
                  },{ required: true }]}>
                  <Input />
                </Form.Item>
                {/* Render only if this is a course */}
                { type === CourseDataType.course &&
                <>
                  <Form.Item label="Price" name="price" rules={[{ required: true }]}>
                    <InputNumber min={0.01} max={10000}/>
                  </Form.Item>
                  <Form.Item name="level" label="Level" rules={[{ required: true }]}>
                    <Select
                      placeholder="Select course difficulty level."
                      allowClear
                    >
                      {Object.values(CourseLevelType).map((v)=><Option key={v} value={v}>{v}</Option>)}
                    </Select>
                  </Form.Item>
                </>}
                <Form.Item label="Published" valuePropName="checked" name="published">
                  <Switch />
                </Form.Item>
                {/* *********************** UPLOAD CONTROL FOR THUMBNAIL *********************/}
                {data.id?
                  <Form.Item label="Media">
                    <Row justify='center'>
                      <Col span={8}>
                          <Upload listType="picture-card" accept="image/png"
                                fileList={thumbnailFile}
                                beforeUpload={()=>{
                                  return false;
                                }}
                                onChange={handleThumbnailChange}
                                onPreview={(file:any)=>previewMedia(file, true)}>
                            {thumbnailFile.length >= 1 ? null : 
                            <Button icon={<PictureOutlined />}>Thumbnail</Button>}
                          </Upload>
                          { thumbnailFile.length >= 1 && data.thumbnail == ThumbnailStatus.None?
                            <HubButton loading={thumbnailUploading} size="middle" type="default" onClick={uploadThumbnail}>
                              Upload
                            </HubButton>:null
                          }
                      </Col>
                      <Col span={8}>
                          {type !== CourseDataType.module? 
                            <React.Fragment>
                              <Upload listType="picture-card" accept="video/mp4"
                                      fileList={videoFile}
                                      beforeUpload={()=>{
                                        return false;
                                      }}
                                      onChange={handleVideoChange}
                                      onPreview={(file:any)=>previewMedia(file, false)} 
                                      previewFile={async(): Promise<string>=>videoIcon}>
                                  {videoFile.length >= 1 ? null : 
                                  <Button icon={<VideoCameraOutlined />}>Video</Button>}
                              </Upload>
                              { videoFile.length >= 1  && data.video == VideoStatus.None?
                                <HubButton loading={videoFileUploading} size="middle" type="default" onClick={uploadVideo}>
                                  Upload
                                </HubButton>:null
                              }
                              { data.video == VideoStatus.Ready?
                                <Tag color="success" icon={<CheckCircleOutlined />}>READY</Tag>:null
                              }
                            </React.Fragment>
                            : null}
                      </Col>
                    </Row>
                  </Form.Item>: null
                }
                {/* *********************** UPLOAD CONTROL FOR THUMBNAIL *********************/}
                {data.video == VideoStatus.Ready?
                  <Form.Item label="Subtitles">
                   <Row gutter={[6,0]} justify='center'>
                      {renderSubtitles()}
                    </Row>
                  </Form.Item>: null
                }
                <Divider/>
                <Form.Item {...tailLayout}>
                  <Space>
                    <HubButton size='large' type="primary" htmlType="submit">
                      Save
                    </HubButton>
                    {data.id?
                      <HubButton size='large' type="default" onClick={onReset}>
                        Reset
                      </HubButton>:
                      <HubButton size='large' type="default" onClick={onCancel}>
                        Cancel
                      </HubButton>
                    }
                  </Space>
                </Form.Item>
              </Form>
        </Card>
        {/* the crop modal */}
        <ImageEditModal 
          imageSrc={thumbnailSrc}
          aspect={1.51}
          onComplete={async (croppedImg:string)=>{        
            if(croppedImg){
              let blob = await CanvasUtils.base64ToBlob(croppedImg);
              handleThumbnailChange({fileList:[{originFileObj:blob}], skipDimensionCheck: true})
            }
            /* remove original */
            setThumbnailSrc(null);
          }}/>
      </Badge.Ribbon>)
};

export default CourseDetail;