import {UserConfiguration} from '../../models'
import wrapper from './wrapper';

export default class ConfigClient {

  /* get access based on area and user */
  public static async getUserConfiguration(): Promise<UserConfiguration> {

    /* Get user configuration */
    const query = `
      query {
        getUserConfiguration {
          colorMode
          emailConfigs
          smsConfigs
          soundConfigs
          language
          profileImageTime
          isPubSubConfigured
          systemVolume
          updatedAt
        }
    }`;
    /* execute with query with map */
    let {getUserConfiguration}: any = await wrapper.grapql(query);
    /* if there is data */
    if (getUserConfiguration) {
      /* configuration */
      let config = getUserConfiguration as UserConfiguration;
      return {
        ...config,
        emailConfigs: JSON.parse(`${config.emailConfigs}`),
        smsConfigs: JSON.parse(`${config.smsConfigs}`),
        soundConfigs: JSON.parse(`${config.soundConfigs}`)
      };
    } else {
      throw Error("Could not retrieve user configuration!");
    }
  }

  /* get access based on area and user */
  public static async updateUserConfiguration(entry:any): Promise<any> {
  
    /* access list query */
    const mutation = `mutation ($entry: UserConfigurationInput) {
      updateUserConfiguration(entry: $entry)
    }`;

    /* serialize maps */
    let mps = [ 
      'emailConfigs',
      'smsConfigs',
      'soundConfigs'];
    /* serializing maps if present */
    for(let m of mps){
      if(entry[m]){
        entry[m] = JSON.stringify(entry[m])
      }
    }
    /* execute with query with map */
    let result :any = await wrapper.grapql(mutation,{entry: entry});
  }

  /* get access based on area and user */
  public static async configureUserPubSub(): Promise<any> {

    /* access list query */
    const mutation = `mutation {
      configureUserPubSub
    }`;

    /* execute with query with map */
    return await wrapper.grapql(mutation);
  }
  
}