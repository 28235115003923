import  { useEffect, useState, useRef } from 'react';

import { Language, Resource } from '../../../../../models';
import { Helpers } from '../../../../../lib/utils';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table, Spin } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import {  ResourceType } from '../../../../../models/Resource';
const Highlighter = require('react-highlight-words');

type ResourceListProps = {
  onSelect: (e: Resource)=>void,
  onDeselect: ()=>void,
  selected: Resource | null,
  resources: Resource[],
  loading: boolean
}

type DataIndex = keyof Resource;

const ResourceList = (props: ResourceListProps) => {
  
  let [lang] = useState<Language>(Helpers.getUserLang());
  const [currentResources, setCurrentResources] = useState<Resource[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  let {
    onSelect,
    selected,
    onDeselect,
    resources,
    loading,
  } = props;

  /* runs when courses state changes */
  useEffect(() => {
    setCurrentResources(resources);
  },[resources])  

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };


  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Resource> => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record:any) =>
      record[dataIndex][lang]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text[lang].toString() : ''}
        />
      ) : (
        text[lang]
      ),
  });

  const columns: ColumnsType<Resource> = [
    {
      title: 'Title',
      dataIndex: 'title',
      width: '50%',
      render: (value)=>value[lang],
      ...getColumnSearchProps('title'),
      sorter: (a, b) => ('' + a.title[lang]).localeCompare(b.title[lang]),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Type',
      dataIndex: 'resourceType',
      width: '20%',
      sorter: (a, b) => ('' + a.resourceType).localeCompare(b.resourceType),
      filters: Object.values(ResourceType).map((v)=>{return {text:v, value:v}}),
      onFilter: (value: any, record: Resource) => record.resourceType.startsWith(value)
    },
    {
      title: 'Published',
      dataIndex: 'published',
      width: '10%',
      render: (value)=>value?'✔️':'✖️',
      sorter: (a, b) =>  b.published? 1: -1,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Order',
      dataIndex: 'ordering',
      width: '10%',
      sorter: (a, b) => a.ordering - b.ordering,
      sortDirections: ['descend', 'ascend'],
    }
    ,{
      title: 'Views',
      dataIndex: 'viewCount',
      width: '10%',
      sorter: (a, b) => a.viewCount - b.viewCount,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  return (
    <Spin spinning={loading}>
      <Table 
        rowKey={'id'}
        rowSelection={{
          type: 'radio',
          selectedRowKeys:[selected?selected.id:''],
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              if(selected && record.id == selected.id){
                onDeselect();
              }else{
                onSelect(record);
              }
              
            }
          };
        }}
        columns={columns} 
        dataSource={currentResources} />
    </Spin>
  );
};


export default ResourceList;