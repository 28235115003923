/**
 * This script exports the Sign In component.
 * 
 * @author Victor Santos Uceta
 * @license Attribution-NonCommercial-NoDerivatives 4.0 International
 */
/* external imports */
import { useState } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Divider
} from 'antd';
import {
  useNavigate, Link, useParams
} from "react-router-dom";
import { Auth } from 'aws-amplify';
import { HubButton, HubModal, HubPasswordPolicy } from '../../misc/front/hubComponents';
import { block } from '../../../commons/misc/front/miscSlice';
import { Attention } from '../../../../lib/utils/notification';
import Agreement from './agreement';
import AnalyticsClient, { AnalyticsEvent } from '../../../../lib/api/analytics_client';
import { Helpers } from '../../../../lib/utils';
import { PASSWORD_REGEX } from '../../../../lib/utils/constants';
import browserLang from 'browser-lang';
import { systemDefaults } from '../../../../lib/utils/enums';

const { Option } = Select;

const myLanguage = browserLang({
  languages: ['es', 'en'], 
  fallback: 'en',
});

const RegistrationForm = () => {
  
  const navigate = useNavigate();
  const { emailToValidate } = useParams();
  const [signUpForm] = Form.useForm();
  const [confirForm] = Form.useForm();
  const [currentUsername, setCurrentUsername] = useState<null|any>(emailToValidate);
  const [agreementVisible, setAgreementVisible] = useState(false);


  const onSignUpFinish = async (values: any) => {
    try {

      const { user } = await block(async () => {

        /* lowercase email */
        values.email = values.email.toLowerCase().trim();
        /* check if exists */
        const userExist = await Helpers.userExist(values.email);
        if(userExist){
          throw new Error('This email is already in use.')
        }

        return await Auth.signUp({
          "username": values.email,
          "password": values.password,
          attributes: {
              "given_name": values.firstName,
              "family_name": values.lastName,
              "email": values.email,
              "gender": systemDefaults.emptyDynamoString,
              "custom:discord_user": systemDefaults.emptyDynamoString,
              "custom:language": myLanguage
          }
        });
      });
      AnalyticsClient.record(AnalyticsEvent.signUp,{email: values.email});
      /* set current user */
      setCurrentUsername(user.getUsername());
      /* send notification */
      Attention.notifySuccess(
        'Email confirmation sent!',
        `A confirmation was send to ${user.getUsername()}, please provide the confirmation code to finisht the sign up process.`
      );
    } catch (error: any) {
      /* send notification */
      Attention.notifyError(
        'Error signing up',
        `${error.message}`
      );
    }
  };

  const onConfirmFinish = async (values: any) => {
    try {

      await block(async () => {
        await Auth.confirmSignUp(currentUsername, values.code);
      });
      /* send notification */
      Attention.notifySuccess(
        'Email confirmed!',
        `Your email is now confirmed! please proceed to login.`
      )
      navigate('/');
    } catch (error: any) {
      console.log(error);
      /* send notification */
      Attention.notifyError(
        'Error confirming email',
        `${error.message}`
      );
    }
  };

  const onResendConfirmation = async () =>{
    try {
      await block(async () => {
        await Auth.resendSignUp(currentUsername);
      });
      AnalyticsClient.record(AnalyticsEvent.resendConfirmationOk);
      /* send notification */
      Attention.notifyInfo(
        'Email confirmation re-sent!',
        `A confirmation was re-sent to ${currentUsername}, please provide the confirmation code to finisht the sign up process.`
      );
    } catch (error: any) {
      AnalyticsClient.record(AnalyticsEvent.resendConfirmationFailed);
      /* send notification */
      Attention.notifyError(
        'Error resending email confirmation.',
        `${error.message}`
      );
    }
  }

  if(!currentUsername){
    return (
      <Form
        layout="vertical"
        form={signUpForm}
        name="register"
        onFinish={onSignUpFinish}
        scrollToFirstError
      >
        <Form.Item >
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                noStyle
                rules={[{ required: true, message: 'Please provide your first name.' }]}
              >
                <Input placeholder="First Name"/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                  name="lastName"
                  noStyle
                  rules={[{ required: true, message: 'Please provide your last name.' }]}
                >
                  <Input placeholder="Last Name"/>
                </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: <div>
                E-mail not valid! Common errors:<br/>
                <ul style={{listStyle:'none', paddingLeft: 0, textDecoration:'underline'}}>
                  <li>Hidden spaces before or after</li>
                  <li>Invalid character in email</li>
                  <li>Forgetting the @</li>
                  <li>Pasting hidden characters</li>
                </ul>
                </div>,
            },
            {
              required: true,
              message: 'Please input your e-mail!',
            },
          ]}
        >
          <Input placeholder="E-mail"/>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
            {
              pattern: PASSWORD_REGEX,
              message: <HubPasswordPolicy/>,
            }
          ]}
          hasFeedback
        >
            <Input.Password  placeholder="Password"/>
        </Form.Item>

        <Form.Item
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password  placeholder="Confirm Password"/>
        </Form.Item>

        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Agreement is required')),
            },
          ]}
    
        >
          <Checkbox>
            I have read the <HubButton type="link" onClick={()=>setAgreementVisible(true)}>Agreement</HubButton>
          </Checkbox>
        </Form.Item>
        <Form.Item >
          <HubButton htmlType="submit">
            Register
          </HubButton>
        </Form.Item>

        <Divider />
        <Link to="/">back to login</Link>
        <HubModal title="Terms and conditions"
                  showScroll={true}
                  open={agreementVisible}
                  cancelText={"Close"}
                  showOk={false} 
                  onCancel={()=> setAgreementVisible(false)}>
          {Agreement}
        </HubModal>
      </Form>);
    }else{
      let confirmMsg = `A confirmation code was send to ${currentUsername} to confirm email.`
      return (
        <Form
          layout="vertical"
          form={confirForm}
          name="confirm"
          onFinish={onConfirmFinish}
          scrollToFirstError
        >
          <Form.Item extra={confirmMsg}>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="code"
                  noStyle
                  rules={[{ required: true, message: 'Please provide your email confirmation code.' }]}
                >
                  <Input placeholder="Confirmation Code"/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <br/>
                <HubButton htmlType="submit">
                  Confirm
                </HubButton>
                <Divider>or</Divider>
                <HubButton type="default" onClick={onResendConfirmation}>
                  Resend
                </HubButton>
              </Col>
            </Row>
          </Form.Item>
          <Divider />
         <Link to="/">confirm later</Link>
        </Form>)
    }
};

export default () => <RegistrationForm />;
