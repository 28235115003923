import React, { useState } from 'react';
import { Layout, Row, Col, Form, Input, Button, Card, notification, Typography, Divider } from 'antd';
import { Link, Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';


import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { block } from '../../../commons/misc/front/miscSlice';
import { HubButton } from '../../misc/front/hubComponents';

export default function ForgotPassword(props: any) {
    // const s = useSelector((state: defaultState) => state, shallowEqual)
    const dispatch = useAppDispatch();
    const { Header, Content, Footer } = Layout;
    const { Text, Paragraph } = Typography;
    
    let [resetCodeSent, setResetCodeSent] = useState(false);
    let [sendCode, setSendCode] = useState(false);
    let [redirect, setRedirect] = useState(false);
    let [username, setUsername] = useState<string|null>();

    const sendNotification = (type:string, title:string, desc:string) =>{
      //@ts-ignore 
      notification[type]({
        message:title,
        description:desc
      });
    }

    const onFinishEmailCode = async (values: any) => {
        try {
          setUsername(values.email);
          setSendCode(true);

          await block(async()=>{
            await Auth.forgotPassword(values.email);
          });

          setSendCode(false);
          setResetCodeSent(true);

          /* send notification */
          sendNotification(
            'success',
            'Password reset email sent',
            'We\'ve sent you an email with a code so you can reset your password.'
          )

        } catch (error: any) {
          setSendCode(false);
          sendNotification(
            'error',
            'Error executing password reset.',
            error.message
          )
        }
    }

    const onFinishChangePassword = async (values: any) => {
        try {
          await block(async()=> {
            if(username && username != "") {
              await Auth.forgotPasswordSubmit(username, values.code, values.password);

              /* send notification */
              sendNotification(
                'success',
                'Password changed',
                'Changed the password successfuly.'
              )

              setTimeout(() => {
                setRedirect(true);
              }, 1500);
            }
          });

        } catch (error: any) {
          sendNotification(
            'error',
            'Couldn\'t change the password. Please try again later.',
            error.message
          )
        }
    }

    if(redirect) {
        return <Navigate to="/" />
    }


    if(resetCodeSent) {
      return (
        <React.Fragment>
        <Row>
          <Col span={24} >
              <Card title="Change Password">
                  <Form
                  size="large"
                  onFinish={onFinishChangePassword}
                  >
                      <Form.Item
                          name="code"
                          hasFeedback
                          rules={[{ required: true, message: 'Please input the code.'}, { pattern: RegExp("^[0-9]+$"), message: "Enter code in correct format. Only numbers are allowed."}]}
                      >
                          <Input placeholder="Code" minLength={6}/>
                      </Form.Item>

                      <Row>
                        <Col span={24} style={{textAlign: "justify", textJustify: "inter-word"}}>
                          <Paragraph type='secondary'>
                            Password must contain: At least 8 characters, 1 upper case letter, 1 lower case letter,
                            1 symbol except " or ' and 1 digit.
                          </Paragraph>
                        </Col>
                      </Row>

                      <Form.Item
                        name="password"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password.',
                          }]}
                      >
                        <Input type="password" placeholder='New Password' />
                      </Form.Item>
                      <Form.Item
                        name="pwdConfirmed"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your new password.',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('The two passwords that you entered do not match.'));
                            },
                          }),
                        ]}
                      >
                        <Input type="password" placeholder='Confirm New Password'/>
                    </Form.Item>
                      <br />
                      <Form.Item>
                          <HubButton type="primary" htmlType="submit">Change Password</HubButton>
                      </Form.Item>
                  </Form>
              </Card>
            </Col>
          </Row>
          <br/>
          <Link to="/">back to login</Link>
          </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
            <Row>
              <Col span={24} >
                  <Card title="Provide your email to reset password">
                      <Form
                      size="large"
                      onFinish={onFinishEmailCode}
                      >
                          <Form.Item
                              name="email"
                              rules={[{ required: true, message: 'Please input your email'}, {pattern: RegExp("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,})$"), message: "Email format invalid"}]}
                          >
                              <Input disabled={sendCode} placeholder="Email"/>
                          </Form.Item>

                          <Form.Item>
                              <HubButton loading={sendCode} htmlType="submit">Send Code</HubButton>
                          </Form.Item>
                      </Form>
                  </Card>
              </Col>
          </Row>
          <br/>
          <Link to="/">back to login</Link>
        </React.Fragment>
      )
    }
}