import React from 'react';
import { Routes, Route } from 'react-router';
import { Area } from '../../../lib/utils/enums';
import { Gateway } from '../../commons/gateway/front/Gateway';
import ResourceBody from './ResourceBody';
import ResourceMenu from './ResourceList';

const Menu: React.FC = () =>  {

  let styles:React.CSSProperties = {
    height: '100vh', 
    overflowY:'scroll', 
    overflowX:'hidden'
  };
  return <div style={styles}>
    <Routes>
      <Route path="/" element={<ResourceMenu/>} />
      <Route path=":resourceId" element={<ResourceBody/>}/>
    </Routes>
    </div>;
}

export default Gateway(Menu, Area.EDUCATION, true);