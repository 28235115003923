import React from 'react';
import ReactDOM from 'react-dom/client';
import "@fontsource/roboto";
import './index.css';
import App from './App';
import { store, history } from './app/store'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import { GeneralClient } from './lib/api';
import { ErrorType } from './lib/utils/enums';

/********************  do inits here ********************/
window.addEventListener("error", ({error, preventDefault}) => {
  const { stack} = error;
  if(process.env.REACT_APP_ENVIRONMENT !== 'dev'){
    GeneralClient.reportError(stack,ErrorType.UNHANDLED);
  }
}, false);

window.addEventListener('contextmenu',  (event)=> {  
  if(process.env.REACT_APP_ENVIRONMENT !== 'dev' && event && event.preventDefault){
    event.preventDefault(); 
  }
}, false);

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    if(this.length){
      return this.charAt(0).toUpperCase() + this.slice(1);
    }else{
      return this;
    }
  },
  enumerable: false
});

declare global {
  interface String {
    capitalize() : string;
  }
}

/********************  end ********************/

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
    <Provider store={store}>
      <ReduxRouter history={history}>
        <App />
      </ReduxRouter>
    </Provider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
