import React, {useEffect, useState} from 'react';
import { Typography, Table, Row, Col, Layout, Divider, Skeleton, Badge } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import Lottie from "lottie-react";
import './index.css';
import { StripeClient } from '../../../../lib/api';
import { StripePaymentMethod, StripeSubscription } from '../../../../models';
import UpgradeSubscriptionModal from './upgradeSubscriptionModal';
import CancelSubscriptionModal from './cancelSubscriptionModal';
import { block } from '../../../commons/misc/front/miscSlice';
import TopUpSubscriptionModal from './topUpSubscriptionModal';
import { Gateway } from '../../../commons/gateway/front/Gateway';
import { HubButton } from '../../../commons/misc/front/hubComponents';
import HubBadge, { colors } from './Badge';
import { Area, Role } from '../../../../lib/utils/enums';
import customerGift from '../../../../media/animations/customer-gift.json';
import premiumRocket from '../../../../media/animations/rocket.json';
import { Helpers } from '../../../../lib/utils';

const { Text} = Typography;
const { Content } = Layout;

const Subscriptions = ()=>{

  const [subscription, setSubscription] = useState<StripeSubscription|null>(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [canPay, setCanPay] = useState(false);
  const [subLoaded, setSubLoaded] = useState(false);
  const [showCancelSubModal, setShowCancelSubModal] = useState(false);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<StripePaymentMethod[]>([]);

  useEffect(() => {
    (async () => {
      await block(async () => {
        /* resolve both requests */
        const [sub, pm] = await Promise.all([
          StripeClient.read_customer_subscription(),
          StripeClient.read_payment_methods()
        ]);
        /* set subscription and payment methods */
        setSubscription(sub);
        setPaymentMethods(pm.paymentMethods);
        setSubLoaded(true);
      });
    })();
  }, []);

  const upgradeSubscription = async (priceId: string) => {
      setCanPay(paymentMethods.length > 0);
      setShowUpgradeModal(true);
  }

  const topOffSubscription = async () => {
      setCanPay(paymentMethods.length > 0);
      setShowTopUpModal(true);
  }

  const cancelSubscription = () => {
    setShowCancelSubModal(true);
  }

  const closeCancelSubModal = async (fetch:boolean = false) => {
    if(fetch){
      await block(async () => {
          const sub = await StripeClient.read_customer_subscription();
          setSubscription(sub);
      });
    }
    setShowCancelSubModal(false);
  }

  const closeUpgradeModal = async (fetch:boolean = false) => {
    if(fetch){
      await block(async () => {
          const sub = await StripeClient.read_customer_subscription();
          setSubscription(sub);
          await Helpers.refreshUser();
      });
    }
    setShowUpgradeModal(false);
  }

  const closeTopUpModal = async (fetch:boolean = false) => {
    if(fetch){
      await block(async () => {
          const sub = await StripeClient.read_customer_subscription();
          setSubscription(sub);
          await Helpers.refreshUser();
      });
    }
    setShowTopUpModal(false);
  }

  const renderSubDetails = (priceId: string, isFreeTier: boolean) => {
    if(isFreeTier) {
      return (
        <Badge.Ribbon text={"FREE"}>
          <Lottie loop={true} animationData={customerGift} style={{height: 100, marginTop:-20}}/>
        </Badge.Ribbon>
      );
    }
    else if(subscription) {
      switch(subscription.status) {
        case "active":
          return (
            <React.Fragment>
              <Text>Status:&nbsp;<Text style={{color: "green"}}><strong>Active</strong></Text></Text>
              <br />
              {subscription.cancelled? <Text>Last Day of Premium:<br/><Text style={{color: "red"}}>{new Date(subscription.currentPeriodEnd).toDateString()}</Text></Text> :
              <Text>Next Payment Due:<br/><Text style={{color: "green"}}>{new Date(subscription.currentPeriodEnd).toDateString()}</Text></Text>}
              <br />
              {subscription.cancelled? <></> : 
              <HubButton size='middle' style={{marginTop:6, marginBottom:-6}} onClick={cancelSubscription} >Cancel Subscription</HubButton>}              
            </React.Fragment>
          );
        case "past_due":
          return (
            <React.Fragment>
              <Text>Status:&nbsp;<Text style={{color: "red"}}><strong>Past Due</strong></Text></Text>
              <br />
              <Text>Next Payment Attempt:<br/><Text style={{color: "red"}}>{new Date(subscription.currentPeriodEnd).toDateString()}</Text></Text>
              <br />
              <HubButton size='middle' style={{marginTop:6, marginBottom:-6}} onClick={() => topOffSubscription()} >Pay Now</HubButton>
            </React.Fragment>
          );
        case "canceled":
          return (
            <React.Fragment>
              <Text>Status: Cancelled</Text>
              <br />
              <HubButton size='middle' onClick={() => upgradeSubscription(priceId)} >Restore Premium</HubButton>
            </React.Fragment>
          );
        default:
          break;
      }
    }
    else if(subscription == null && subLoaded) {
      return ( <React.Fragment>
                  <Lottie loop={true} animationData={premiumRocket} style={{height: 150, marginTop:-35,marginBottom: -20}}/>
                  <HubButton onClick={() => upgradeSubscription(priceId)} >Upgrade</HubButton>
                </React.Fragment>);
    }
    else {
      return (
        <React.Fragment>
          <Skeleton.Input active size="small" />
          <br />
          <br />
          <Skeleton.Input active size="small" />
          <br />
        </React.Fragment>
      );
    }
  }

  const renderHeader = (color: string, title: string, price: string, isFreeTier: boolean = false, priceId: string = "") => {
    return (
      <React.Fragment>
        <div style={{backgroundColor: color, minWidth:250, borderRadius: 12}}>
          <Row>
            <Col span={24} style={{textAlign: "center"}}>
              <HubBadge role={title} height={120} showRole={true}/>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: "center"}}>
              <Text style={{fontSize: 24, color: "black"}}><strong>{price}</strong> </Text><strong>/month</strong>
              <Divider />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{textAlign: "center", minHeight: 76}}>
              {renderSubDetails(priceId, isFreeTier)}
            </Col>
          </Row>
          <br />
        </div>
      </React.Fragment>
    );
  }
  
  const customerColor = colors.get(Role.CUSTOMER);
  const customerFeatures = [
    {
      key: '1',
      name: <React.Fragment><CheckCircleOutlined style={{color: customerColor}} />&nbsp;<Text style={{color: "black"}}> Webinars mensuales</Text></React.Fragment>,
    },
    {
      key: '2',
      name: <React.Fragment><CheckCircleOutlined style={{color: customerColor}} />&nbsp;<Text style={{color: "black"}}>  Watchlist <strong>1</strong> vez a la semana</Text></React.Fragment>,
    },
    {
      key: '3',
      name: <React.Fragment><CheckCircleOutlined style={{color: customerColor}} />&nbsp;<Text style={{color: "black"}}> Canal de ganancias/perdidas</Text></React.Fragment>,
    }
  ];
  const premiumColor = colors.get(Role.PREMIUM);
  const premiumFeatures = [
    {
      key: '1',
      name: <React.Fragment><CheckCircleOutlined style={{color: premiumColor}} />&nbsp;<Text style={{color: "black"}}> Chat <strong>Premium</strong></Text></React.Fragment>
    },
    {
      key: '2',
      name: <React.Fragment><CheckCircleOutlined style={{color: premiumColor}} />&nbsp;<Text style={{color: "black"}}> Alertas <strong>VIP</strong></Text></React.Fragment>,
    },
    {
      key: '3',
      name: <React.Fragment><CheckCircleOutlined style={{color: premiumColor}} />&nbsp;<Text style={{color: "black"}}> Watchlist <strong>diarios</strong></Text></React.Fragment>,
    },
    {
      key: '4',
      name: <React.Fragment><CheckCircleOutlined style={{color: premiumColor}} />&nbsp;<Text style={{color: "black"}}> RainHub University</Text></React.Fragment>,
    },
    {
      key: '5',
      name: <React.Fragment><CheckCircleOutlined style={{color: premiumColor}} />&nbsp;<Text style={{color: "black"}}> Webinars semanales</Text></React.Fragment>,
    },
    {
      key: '6',
      name: <React.Fragment><CheckCircleOutlined style={{color: premiumColor}} />&nbsp;<Text style={{color: "black"}}> Indicadores de RainHub</Text></React.Fragment>,
    },
    {
      key: '7',
      name: <React.Fragment><CheckCircleOutlined style={{color: premiumColor}} />&nbsp;<Text style={{color: "black"}}> Pdf/Ebooks y Tutoriales</Text></React.Fragment>,
    },
    {
      key: '8',
      name: <React.Fragment><CheckCircleOutlined style={{color: premiumColor}} />&nbsp;<Text style={{color: "black"}}> Trading Floor (<strong>trading en vivo</strong>)</Text></React.Fragment>,
    },
    {
      key: '10',
      name: <React.Fragment><CheckCircleOutlined style={{color: premiumColor}} />&nbsp;<Text style={{color: "black"}}> Analisis de <strong>futuros</strong>/<strong>proyecciones</strong></Text></React.Fragment>
    },
    {
      key: '11',
      name: <React.Fragment><CheckCircleOutlined style={{color: premiumColor}} />&nbsp;<Text style={{color: "black"}}> Todos los beneficios de la<br/>membresia <strong>Community</strong></Text></React.Fragment>
    }
  ];
  
  const columnsFree = [
    {
      title: renderHeader("white", Role.CUSTOMER, "$0", true), //"#1a274f"
      dataIndex: 'name',
      key: 'name',
      className: "price-col-free"
    }
  ];
  
  const columnsPremium = [
    {
      title: renderHeader("white", Role.PREMIUM, "$54", false), //#16182b
      dataIndex: 'name',
      key: 'name',
      className: "price-col-premium"
    }
  ];

  /* styles for offering division */
  const offeringStyles:React.CSSProperties  = {
    boxShadow: "0 1px 5px 1px rgba(0,0,0,0.3)",
    background: "white",
    border:`2px solid`,
    borderRadius: 12, 
    padding: 6
  };

  return (
    <React.Fragment>
      <Layout>
        <Content>
          <Row gutter={[24, 0]} justify="center">
            <Col>
              <div style={{...offeringStyles, borderColor: customerColor}}>
                <Table  pagination={false} 
                        bordered={true} 
                        dataSource={customerFeatures} 
                        columns={columnsFree} 
                        size="small"/>
              </div>
              <br/>
            </Col>
            <Col>
              <div style={{...offeringStyles, borderColor: premiumColor}}>
                <Table pagination={false} 
                       bordered={true} 
                       dataSource={premiumFeatures} 
                       columns={columnsPremium} 
                       size="small"/>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
      <UpgradeSubscriptionModal showUpgradeModal={showUpgradeModal} canPay={canPay} closeUpgradeModal={closeUpgradeModal} />
      <CancelSubscriptionModal showCancelSubModal={showCancelSubModal} closeCancelSubModal={closeCancelSubModal} />
      <TopUpSubscriptionModal showTopUpModal={showTopUpModal} closeTopUpModal={closeTopUpModal} canPay={canPay} />
    </React.Fragment>
  );
}

export default Gateway(Subscriptions, Area.ACCOUNT_SUBSCRIPTIONS);