import React from 'react';
import {Modal, Layout, Form, Input, Row, Col, notification} from 'antd';
import './verificationModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons'
import {loadStripe, VerifyMicrodepositsForSetupData} from '@stripe/stripe-js';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { block } from '../../../commons/misc/front/miscSlice';
import { PendingBankAccountIntent, User } from '../../../../models';
import { StripeClient } from '../../../../lib/api';
import { HubButton, HubModal } from '../../../commons/misc/front/hubComponents';
import { Attention } from '../../../../lib/utils/notification';

interface RHMicrodepositForSetupData extends VerifyMicrodepositsForSetupData {
  descriptor_code: string
}

export default function VerificationModal(props: any) {
    const {Content} = Layout;
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user) as User;

    const onFinish = async (values: any) => {
        try {
          await block(async()=>{
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
            const pm: PendingBankAccountIntent|null = props.selectedPendingBankAccount;
            const code: string = `SM${values.code}`;
            if(pm && code) {
              const data: RHMicrodepositForSetupData = {
                descriptor_code: code
              }
              const verificationResult = await stripe?.verifyMicrodepositsForSetup(pm.clientSecret, data);
              
              if(verificationResult?.error) {
                throw new Error(verificationResult.error.message);
              }

              await StripeClient.set_default_payment_method(pm.paymentMethodId, true);
            }

            return;
          });

          Attention.notifySuccess(
            "Successfully verified Bank Account"
          );
          handleCancel();
          await props.reloadPaymentMethods();

        } catch (error) {
          Attention.notifyError(
            'Bank Account verification failed',
            'Couldn\'t verifiy the bank account. Please try again later.'
          );
          console.error(error);
        }
    }
    
    const handleCancel = () => {
        props.setIsModalVisible(false);
        form.resetFields();
    };

    return (
        <React.Fragment>
            <HubModal title="Verify Bank" open={props.isModalVisible} onCancel={handleCancel} showOk={false} >
                <Layout>
                    <Content style={{background: "white", textAlign: "center"}}>
                        <Row>
                            <Col span={24}>
                                <p>Please enter the 6-digit code from your bank statement to verify your account.</p>
                                <div className="BankStatementTable">
                                  <div className="BankStatementTable-Header">
                                    <FontAwesomeIcon icon={faUniversity} size="1x" color="lightGray" />
                                    <span className="Text Text-color--gray200 Text-fontSize--13 Text-transform--uppercase"> Bank statement</span>
                                  </div>
                                  <table id="tranTable">
                                      <thead>
                                        <tr>
                                            <th><span className="Text Text-color--gray500">Transaction</span></th>
                                            <th style={{textAlign: "right"}}><span className="Text Text-color--gray500">Amount</span></th>
                                            <th><span className="Text Text-color--gray500">Type</span></th>
                                            <th><span className="Text Text-color--gray500">Date</span></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                            <td colSpan={4} style={{height: "inherit"}}>
                                            <hr />
                                            </td>
                                        </tr>
                                        <tr className="Text-color--gray500">
                                            <td className="Text-fontWeight--600 Text-color--blue" style={{color: "blue", fontWeight: 600}}>SMXXXX</td>
                                            <td className="Text-fontWeight--600 Text-color--gray800" style={{textAlign: "right"}}>$0.01</td>
                                            <td>ACH CREDIT</td>
                                            <td>JUL 15</td>
                                        </tr>
                                        <tr className="Text-color--gray200">
                                            <td>Groceries</td>
                                            <td style={{textAlign: "right"}}>$1000.00</td>
                                            <td>VISA</td>
                                            <td>JUL 14</td>
                                        </tr>
                                      </tbody>
                                  </table>
                                </div>
                                <br />
                                <br />
                                <Form form={form} onFinish={onFinish}>
 
                                  <Form.Item
                                      name="code"
                                      rules={[{ required: true, message: 'Please input the code' }]}>
                                          <Input prefix="SM" maxLength={4} placeholder="XXXX" min={4} style={{width: 300}} />
                                  </Form.Item>

                                  <Form.Item>
                                      <HubButton htmlType="submit" >Verify</HubButton>
                                  </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </HubModal>
        </React.Fragment>
    );
}