import React, { createElement, useEffect, useState } from 'react';
import { Col, Layout, Row, Space, Tabs } from 'antd';
import { Gateway } from '../../../../commons/gateway/front/Gateway';
import { Area } from '../../../../../lib/utils/enums';
import { lang } from 'moment';
import styles from 'react-loading-overlay-ts/dist/styles';
import { HubButton, HubPopConfirm } from '../../../../commons/misc/front/hubComponents';
import courses from '../courses';
import {PlusOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import ResourceList from './ResourceList';
import { Course, Language, Resource, ResourceInput, ResourceLevelType, ResourceSourceStatus, ResourceThumbnailStatus, ResourceType } from '../../../../../models';
import { Helpers } from '../../../../../lib/utils';
import ResourceDetail from '../education/ResourceDetail';
import { FileClient, ResourceClient } from '../../../../../lib/api';
import { ResourceAccessType, ResourceSourceType } from '../../../../../models/Resource';
import { Attention } from '../../../../../lib/utils/notification';
import { block } from '../../../../commons/misc/front/miscSlice';

const AdminEducation = () => {

  let [lang] = useState<Language>(Helpers.getUserLang());
  let [fetching, setFetching] = useState<boolean>(false);
  let [resources, setResources] = useState<Resource[]>([]);
  let [newResource, setNewResource] = useState<ResourceInput|null>(null);
  let [selectedResource, setSelectedResource] = useState<Resource|null>(null);
  
  /* runs when component is mount */
  useEffect(() => {
    (async () => {
      setResources(await fetchResources());
    })();
  }, []);

      /* fetch courses from backend */
  const fetchResources = async (): Promise<Resource[]>=>{
    let resources:Resource[] = [];
    try{
      setFetching(true);
      resources = await ResourceClient.getResources();
    }catch(e:any){
      Attention.notifyError('Could not load resources', e.message);
    }finally{
      setFetching(false);
    }
    return resources;
  }

  const deleteResource =  async()=>{
  
    /* get directory only */
    let dirKey = `resources/${selectedResource?.id}`;
    /* delete both table entry and files */
    await block(async ()=>{
      await ResourceClient.deleteResource(selectedResource?.id || "");
      await FileClient.deleteStoredDirectory(dirKey);
      setResources(await fetchResources());
    });
  }

  const reloadResource = async()=>{
    await onDetailOperationFinish(selectedResource as Resource);
  }

  /* create resource */
  const createResource = ()=>{
    /* start resource listing backwards */
    let orders = resources.map(r=>r.ordering);
    let ordering = resources.length>0?Math.min.apply(Math, orders)-1:10000;
    /* create resource */
    let resource:ResourceInput = {
      title: {es: 'Titutlo', en: 'Title'},
      description: {es:'Entre descripcion', en:'Enter description'},
      resourceType: ResourceType.webinar,
      resourceSourceType: ResourceSourceType.rainhub_video,
      thumbnail: ResourceThumbnailStatus.None,
      sourceStatus: ResourceSourceStatus.None,
      level: ResourceLevelType.Everyone,
      ordering: ordering,
      published: false,
      access: ResourceAccessType.exclusive,
      tags: ['enter', 'tags', 'here']
    }
    /* set new resource */
    setNewResource(resource);
    setSelectedResource(null);
  }

  const onSelected = (data: Resource) =>{
    setNewResource(null);
    setSelectedResource(data);
  }

  const renderDetail = ()=>{
    let data = newResource? {...newResource} as ResourceInput: {...selectedResource} as Resource;
    if (Object.keys(data).length){
      return <ResourceDetail  data={data} 
                              onFinish={onDetailOperationFinish} 
                              onCancel={resetState} />
    }
  }

  const resetState = ()=>{
    setNewResource(null);
    setSelectedResource(null);
  }

  /* when the deatil operation is done (create or update) */
  const onDetailOperationFinish = async (data: Resource, existing = true) =>{
    /* reset selected items */
    resetState();

    /* update in upper level */
    let {id} = data;
    let nResource = await block(async ()=>{
      return await ResourceClient.getResourceById(id);
    }); 

    /* filter old list and remove item if exist */
    let filtered = resources.filter(r=> r.id !== id);
    /* merge new item and old list */
    let nresources = [nResource, ...filtered];
    /* if existing, set as selected */
    if(existing){
      setSelectedResource(nResource);
    }
    /* set resources */
    setResources(nresources);
  }

  let styles:React.CSSProperties = {
    height: '78vh', 
    overflowY:'scroll', 
    overflowX:'hidden'
  };
  return (
    <Layout style={styles}>
        <Row >
        <Col span={10}>
            <Space>
              <HubButton icon={<PlusOutlined/>} onClick={createResource} disabled={selectedResource?true:false}  size='middle' >Add Resource</HubButton>
              <HubPopConfirm
                  key={"delete-btn"}
                  position={"bottom"}
                  title={<p>Are you sure you want to delete {selectedResource?.resourceType}: <br/><strong>{selectedResource?.title[lang]}</strong></p>}
                  onConfirm={deleteResource}
                  okText="Yes"
                  cancelText="No">
                  <HubButton type="default"  size={'middle'} disabled={!selectedResource}><DeleteOutlined /></HubButton>
                </HubPopConfirm>
                <HubButton icon={<ReloadOutlined />} onClick={reloadResource} disabled={!selectedResource} type='default' size='middle'></HubButton>
            </Space>
          </Col>
        </Row>
        <Row style={{paddingTop: 10}} gutter={[12, 0]}>
            <Col span={12}>
              <ResourceList selected={selectedResource} loading={fetching} onSelect={onSelected} onDeselect={resetState} resources={resources}/>
            </Col>
            <Col span={12}>
              {renderDetail()}
            </Col>
        </Row>
    </Layout>)
};


export default Gateway(AdminEducation, Area.ADMIN_CONTENT_EDUCATION);