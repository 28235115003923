import React, { useState } from "react";

import { HubButton, HubGradientText, HubModal, HubTitle } from "../../commons/misc/front/hubComponents";
import { Col, Layout, Rate, Row, Typography } from "antd";
import { Course } from "../../../models";
import { Gradients, Helpers } from "../../../lib/utils";
import stringHash from "@sindresorhus/string-hash";
import TextArea from "antd/lib/input/TextArea";
import { Language } from "aws-sdk/clients/support";


const { Content } = Layout;
const { Text } = Typography;

type CourseReviewModalProps = {
  isOpen:boolean,
  course: Course,
  submitReview: (review:string, score: number)=>void,
  onCancel: ()=>void,
}

const CourseReviewModal = ({
  isOpen,
  course,
  submitReview,
  onCancel,
}: CourseReviewModalProps) => {

  const [lang] = useState<Language>(Helpers.getUserLang());
  const [review, setReview] = useState("");
  const [score, setScore] = useState(1);
  const [scoreHover, setScoreHover] = useState(false);
  
  const onReviewChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReview(e.target.value.trim());
  };

  const onSubmit = ()=>{
    submitReview(review, score);
  }

  const validateRegex = /^[a-zA-Z0-9\s\.\,\!\¿\?]*$/gm;
  const isReviewValid = validateRegex.test(review);

  return (
    <HubModal
      title={
        <HubGradientText forceBlack size={24} colors={Gradients[stringHash(course?.id as string)%Gradients.length]}>
          <strong>{course?.title[lang]} review</strong>
        </HubGradientText>
      }
      open={isOpen}
      onCancel={onCancel}
      showCancel={true}
      showOk={false} 
    > 
      <Layout>
        <Content style={{ backgroundColor: "white", textAlign: "center" }}>
            <Row align="middle" justify="center">
              <Col>
                <Rate style={{zoom:2}} className={!scoreHover?'horizontal-shake':''} 
                      onChange={setScore} value={score} onFocus={()=>setScoreHover(true)}/>
                <br/><br/>
              </Col>
              <Col span={24}>
                <Text>Please let us know what you think about this course, 50 letters or more (be honest 😃)</Text>
                <br/><br/>
                <TextArea showCount minLength={25} maxLength={800} style={{ height: 100 }} onChange={onReviewChange} />
                {!isReviewValid && 
                  <HubTitle>Invalid text detected, only letters, numbers, white space and <strong>,.¿?!</strong> are allowed!</HubTitle>
                }
              </Col> 
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }} span={24}>
                <br/>
                <HubButton disabled={review.length < 50 || !isReviewValid} onClick={onSubmit} size='large'>Submit Review</HubButton> 
              </Col>
            </Row>

        </Content>
      </Layout>
    </HubModal>
  );
};

export default CourseReviewModal;
