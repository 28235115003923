import { Row, Col,  Slider, Tag} from 'antd';
import { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { CanvasUtils } from '../../../../lib/utils';
import { block } from './miscSlice';
import {HubModal} from './hubComponents';

const ImageEditModal:  React.FC<any> = ({imageSrc, onComplete, aspect}) => {

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  /* clean up component */
  const cleanUp = ()=>{
      /* clean up */
      setCrop({ x: 0, y: 0 });
      setRotation(0);
      setZoom(1);
  }
  /* on crop complete handler */
  const onCropComplete = useCallback((croppedArea:any, croppedAreaPixels:any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  /* show cropped image */
  const showCroppedImage = useCallback(async () => {
    try {
      await block(async()=>{
        const croppedImage = await CanvasUtils.getCroppedImg(
          imageSrc as string,
          croppedAreaPixels,
          rotation
        );
        /* send back cropped image */
        await onComplete(croppedImage);
        /* clean up component */
        cleanUp();
      })
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])

  const onCancel = ()=>{
    /* send back cropped image */
    onComplete(null);
    /* clean up component */
    cleanUp();
  }

  return <HubModal
        open={imageSrc!==null}
        okText={"Crop/Save"}
        onOk={showCroppedImage}
        onCancel={onCancel}
      >
      <Row>
        <Col span={24} className={'crop-container'}>
            <div className={'crop-container'}>
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={aspect || 1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            </div>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col span={4}><Tag>Zoom</Tag></Col>
        <Col span={20}>
        <Slider value={zoom}
                min={1} 
                max={3}
                step={0.1}
                tooltip={{
                  placement: 'right',
                  open: false
                }}
                onChange={(zoom) => setZoom(zoom)}
                />
        </Col>
      </Row>
      <Row>
        <Col span={4}><Tag>Rotation</Tag></Col>
        <Col span={20}>
        <Slider value={rotation} 
                min={0} 
                max={360} 
                tooltip={{
                  placement: 'right',
                  open: false
                }}
                onChange={(rotation) => setRotation(rotation)}
                />
        </Col>
      </Row>
    </HubModal>;

}

export default ImageEditModal;