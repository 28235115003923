import React, { useEffect, useState } from 'react';
import { Layout, Tabs, Row, Col, Space } from 'antd';
import { Gateway } from '../../../../commons/gateway/front/Gateway';
import { Area } from '../../../../../lib/utils/enums';
import CourseList from './CourseList';
import { HubButton, HubPopConfirm } from '../../../../commons/misc/front/hubComponents';
import {PlusOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import CourseDetail from './CourseDetail';
import { Course, CourseDataType, CourseInput, CourseLevelType, CourseModule, CourseModuleElement, CourseModuleElementInput, CourseModuleInput, Language, ThumbnailStatus, VideoStatus } from '../../../../../models';
import {CourseClient} from '../../../../../lib/api';
import { block } from '../../../../commons/misc/front/miscSlice';
import { Attention } from '../../../../../lib/utils/notification';
import { Helpers } from '../../../../../lib/utils';

const AdminCourses = () => {

  let [lang] = useState<Language>(Helpers.getUserLang());
  let [fetching, setFetching] = useState<boolean>(false);
  let [courses, setCourses] = useState<Course[]>([]);
  let [newItem, setNewItem] = useState<any>(null);
  let [selectedNewItemType, setSelectedNewItemType] = useState<CourseDataType>(CourseDataType.course);
  let [selectedItem, setSelectedItem] = useState<any>(null);
  let [selectedItemType, setSelectedItemType] = useState<CourseDataType>(CourseDataType.course);
  
  /* runs when component is mount */
  useEffect(() => {
    (async () => {
      setCourses(await fetchCourses());
    })();
  }, []);

  /* fetch courses from backend */
  const fetchCourses = async (): Promise<Course[]>=>{
    let courses:Course[] = [];
    try{
      setFetching(true);
      courses = await CourseClient.getCourses();
    }catch(e:any){
      Attention.notifyError('Could not load courses', e.message);
    }finally{
      setFetching(false);
    }
    return courses;
  }

  const reloadCourse = async()=>{
    let course = await block(async ()=>{
      return await CourseClient.getCourseById(selectedItem.id);
    }) as Course;
    let ncourses = courses.map((c)=>{
      return (c.id === course.id)?course: c;
    });
    /* set courses */
    setCourses(ncourses);
    /* set selected course */
    setSelectedItem(course);
  }

  const resetState = ()=>{
    setNewItem(null);
    setSelectedItem(null);
  }
  /* when the deatil operation is done (create or update) */
  const onDetailOperationFinish = async (data: any, type: CourseDataType, existing = true) =>{
    /* reset selected items */
    resetState();
    if(existing){
      setSelectedItem(data);
      setSelectedItemType(type);
    }
    /* update in upper level */
    let id = type == CourseDataType.course?data.id:data.courseId;
    let nCourse = await CourseClient.getCourseById(id);
    let ncourses = [];

    /* if existing item, merge with courses */
    if(existing || type != CourseDataType.course){
      ncourses = courses.map((c)=>{
        return (c.id === nCourse.id)?nCourse: c;
      });
    }else{
      ncourses = [...courses, nCourse];
    }
    /* set courses */
    setCourses(ncourses);
  }

  const onSelected = (data: any, type: CourseDataType) =>{
    setNewItem(null);
    setSelectedItem(data);
    setSelectedItemType(type);
  }

  const renderDetail = ()=>{
    let data = newItem? {...newItem}: {...selectedItem};
    let selectedType =  newItem? selectedNewItemType: selectedItemType;

    if (Object.keys(data).length){
      return <CourseDetail data={data} type={selectedType} 
      onFinish={onDetailOperationFinish}
      onCancel={resetState} />
    }
  }

  const commonContentFactory = (ordering: number)=>{
    return {
      title: {es: 'Titutlo', en: 'Title'},
      description: {es:'Entre descripcion', en:'Enter description'},
      thumbnail: ThumbnailStatus.None,
      video: VideoStatus.None,
      ordering: ordering,
      published: false,
      tags: ['enter', 'tags', 'here']
    }
  }
  /* create course */
  const createCourse = ()=>{
    /* create course */
    let course:CourseInput = {
      ...commonContentFactory(courses.length),
      level: CourseLevelType.Beginner,
      price: 99.99
    }
    /* set new course */
    setNewItem(course);
    setSelectedNewItemType(CourseDataType.course);
  }
  /* create module */
  const createModule = async ()=>{
    let course = await block(async ()=>{
      return await CourseClient.getCourseById(selectedItem.id);
    }) as Course;
    /* create course */
    let module:CourseModuleInput = {
      ...commonContentFactory(course.modules?course.modules.length:0),
      courseId: course.id,
    }
    /* set new course */
    setNewItem(module);
    setSelectedNewItemType(CourseDataType.module);
  }
  /* create module */
  const createElement = async ()=>{
    /* fetch course and get element count */
    let course = await block(async ()=>{
      return await CourseClient.getCourseById(selectedItem.courseId);
    }) as Course;
    let module = course.modules?.find((m)=>m.id == selectedItem.id) as CourseModule;
    /* create course */
    let element:CourseModuleElementInput = {
      ...commonContentFactory(module.elements?module.elements.length:0),
      moduleId: module.id,
      courseId: course.id,
    }
    /* set new course */
    setNewItem(element);
    setSelectedNewItemType(CourseDataType.element);
  }

  /* delete element */
  const deleteSelected = async()=>{
    /* can delete switch flag */
    let hasDepedencies = false;
    try{
      /* deletion swithc */
      switch(selectedItemType){
        case CourseDataType.course:
          let cData = await CourseClient.getCourseById(selectedItem.id);
          /* check if it has dependencies */
          hasDepedencies = (cData.modules || []).length > 0 || cData.video !== VideoStatus.None || cData.thumbnail !== ThumbnailStatus.None;
          if(hasDepedencies){break;}
          await CourseClient.deleteCourse(cData.id);
          break
        case CourseDataType.module:
          let mData = selectedItem as CourseModule;
          /* check if it has dependencies */
          hasDepedencies = (mData.elements || []).length > 0 || mData.thumbnail !== ThumbnailStatus.None;
          if(hasDepedencies){break;}
          await CourseClient.deleteCourseModule(mData.id, mData.courseId);
          break
        case CourseDataType.element:
          let eData = selectedItem as CourseModuleElement;
          /* check if it has dependencies */
          hasDepedencies = eData.video !== VideoStatus.None || eData.thumbnail !== ThumbnailStatus.None;
          if(hasDepedencies){break;}
          await CourseClient.deleteCourseModuleElement(eData.id, eData.courseId);
          break
      }
    }catch(e:any){
      Attention.notifyError(`Error deleting ${selectedItemType}`, e.message);
      return;
    }
    /* show unable to delete message */
    if(hasDepedencies){
      Attention.notifyError(`Unable to delete ${selectedItemType}`,
      `${selectedItem.title[lang]} has dependencies, remove all media and sub objects first.`
      );
    }else{
      Attention.notifySuccess(`${selectedItemType} removed!`,
      `${selectedItem.title[lang]} has been removed.`
      );
      setSelectedItem(null);
      /* remove item from current data */
      if(selectedItemType == CourseDataType.course){
        setCourses(courses.filter(c=> c.id !== selectedItem.id));
      }else{
        let nCourse = await CourseClient.getCourseById(selectedItem.courseId);
        let ncourses = courses.map((c)=>{
          return (c.id === nCourse.id)?nCourse: c;
        });
        setCourses(ncourses);
      }
    }
  }

  /* selected booleans */
  const addCourseDisabled = selectedItem != null;
  const addModuleDisabled = selectedItem == null || selectedItemType !== CourseDataType.course;
  const addElementDisabled = selectedItem == null || selectedItemType !== CourseDataType.module;

  let styles:React.CSSProperties = {
    height: '78vh', 
    overflowY:'scroll', 
    overflowX:'hidden'
  };

  return (
      <Layout style={styles}>
          <Row >
            <Col span={10}>
              <Space>
                <HubButton icon={<PlusOutlined/>} onClick={createCourse} size='middle'  disabled={addCourseDisabled}>Add Course</HubButton>
                <HubButton icon={<PlusOutlined/>} onClick={createModule} type='default' size='middle' disabled={addModuleDisabled}>Add Module</HubButton>
                <HubButton icon={<PlusOutlined/>} onClick={createElement} type='dashed' size='middle' disabled={addElementDisabled}>Add Element</HubButton>
                <HubPopConfirm
                  key={"delete-btn"}
                  position={"bottom"}
                  title={<p>Are you sure you want to delete {selectedItemType}: <br/><strong>{selectedItem?.title[lang]}</strong></p>}
                  onConfirm={deleteSelected}
                  okText="Yes"
                  cancelText="No">
                  <HubButton type="default"  size={'middle'} disabled={!selectedItem}><DeleteOutlined /></HubButton>
                </HubPopConfirm>
                <HubButton icon={<ReloadOutlined />} onClick={reloadCourse} type='default' size='middle' disabled={addModuleDisabled}></HubButton>
              </Space>
            </Col>
          </Row>
          <Row style={{paddingTop: 10}} gutter={[12, 0]}>
              <Col span={10}>
                <CourseList loading={fetching} onSelect={onSelected} onDeselect={resetState} courses={courses}/>
              </Col>
              <Col span={14}>
                {renderDetail()}
              </Col>
          </Row>
      </Layout>)
};


export default Gateway(AdminCourses, Area.ADMIN_CONTENT_COURSES);