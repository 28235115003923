import React, { useState } from "react";

import { HubButton, HubGradientText, HubModal } from "../../commons/misc/front/hubComponents";
import { Col, Layout, Row } from "antd";
import { Course, Language } from "../../../models";
import { Gradients, Helpers } from "../../../lib/utils";
import stringHash from "@sindresorhus/string-hash";
import confeti from '../../../media/animations/confetti-1.json';
import Lottie from "lottie-react";

const { Content } = Layout;

type FinishCourseModalProps = {
  isOpen:boolean,
  course: Course,
  onGoToReview: ()=>void,
  onCancel: ()=>void,
}

const FinishCourseModal = ({
  isOpen,
  course,
  onGoToReview,
  onCancel,
}: FinishCourseModalProps) => {

  const [lang] = useState<Language>(Helpers.getUserLang());

  return (
    <HubModal
      title={
        <HubGradientText forceBlack size={24} colors={Gradients[stringHash(course?.id as string)%Gradients.length]}>
          <strong>{course?.title[lang]} finished!</strong>
        </HubGradientText>
      }
      open={isOpen}
      onCancel={onCancel}
      showCancel={true}
      showOk={false} 
    > 
      <Layout>
        <Content style={{ backgroundColor: "white", textAlign: "center" }}>
            <Row align="middle" justify="center">
              <Col>
                <Lottie loop={true} animationData={confeti} style={{margin: "-60px"}} />
              </Col>
              <Col span={24}>
                  <h2>
                    Congratulations! You just completed this course! We are proud of you.
                    Please tell us what you think by leaving a review.
                  </h2>
              </Col> 
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }} span={24}>
                <HubButton onClick={onGoToReview} size='large'>Write a Review</HubButton> 
              </Col>
            </Row>

        </Content>
      </Layout>
    </HubModal>
  );
};

export default FinishCourseModal;
