
import Lottie from "lottie-react";
import '@dotlottie/player-component';
import {
  useNavigate
} from "react-router-dom";
import closed from '../../../../media/animations/closed.json';
import { Col, Row, Result, Button} from 'antd';
import { HubButton } from "../../misc/front/hubComponents";

const Closed = ({access}:any) => {

  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="vertical-center">
        <Row>
          <Col span={24} style={{textAlign:'center'}}>
            <Result
              style={{margin:'-35px'}}
              icon={<Lottie loop={true} animationData={closed} style={{height:'350px', margin:'-80px'}}/>}
              title="Area Closed"
              subTitle="This area is not accessible to anyone at the momment..."
              extra={[
                <HubButton 
                  key={"closed"}
                  onClick={()=>{
                    navigate(-1);
                  }}>
                    <strong>Go Back</strong>
                </HubButton>
              ]}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}


export default Closed;