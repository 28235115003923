import React from 'react';
import { Layout, Typography, Row, Col } from 'antd';
import { StripeClient } from '../../../../lib/api';
import { block } from '../../../commons/misc/front/miscSlice';
import { HubButton, HubModal } from '../../../commons/misc/front/hubComponents';
import { Attention } from '../../../../lib/utils/notification';
import {
  useNavigate
} from "react-router-dom";
import {PlusOutlined } from '@ant-design/icons';
import { Area } from '../../../../lib/utils/enums';

const { Text } = Typography;
const { Content } = Layout;

export default function TopUpSubscriptionModal(props: any) {

  const navigate = useNavigate();

  const confirmTopUp = async () => {
    try {

      await block(async () => {
        await StripeClient.top_up_subscription();
      });

      Attention.notifySuccess(
        "Welcome back to RainHub Premium",
      );
      props?.closeTopUpModal(true);

    } catch (error) {
      console.log(error);
      Attention.notifyError(
        'Payment failed',
        'Couldn\'t top up your subscription. Please try again later.'
      );
    }
  }
  const renderUpgradeButton = ()=>{

    if(!props.canPay){
      return <React.Fragment>
              <Text type='danger'>Please setup a payment method before upgrading your subscription</Text>
              <br />
              <HubButton 
                  style={{margin:10}}
                  icon={<PlusOutlined/>}
                  size={'middle'}
                  onClick={()=>{
                    navigate(`/${Area.ACCOUNT_WALLET}`);
                  }}>
                    Add Payment Method
                </HubButton>
              <br />
            </React.Fragment>
    }else{
      return <React.Fragment>

              <HubButton style={{margin:10}} onClick={confirmTopUp} disabled={!props.canPay} >Confirm Payment</HubButton>
              <br />
            </React.Fragment>
    }

  }
  return (
    <React.Fragment>
      <HubModal title={"Get back on Premium"}
                open={props.showTopUpModal || false} 
                onCancel={()=>props.closeTopUpModal()} 
                showOk={false}>
        <Layout>
          <Content style={{backgroundColor: "white"}}>
          <Row>
              <Col span={24} style={{textAlign: "center"}}>
                <Text style={{fontSize: 34, color: "black"}}><strong>$54</strong> </Text><strong>/month</strong>
              </Col>
            </Row>
            <Row>
              <Col style={{textAlign: "center"}} span={24}>
                  {renderUpgradeButton()}
              </Col>
            </Row>
            <Row>
              <Col>
                <Text type='secondary'>
                    By pressing "Confirm Payment" you authorize RainHub, LLC to electronically debit your configured 
                    default payment method periodically for charges arising from your use of RainHub, LLC's services
                    and/or purchase of products from RainHub, LLC, pursuant to RainHub, LLC's website and terms,
                    until your authorization is revoked and, if necessary, electronically credit your account to
                    correct erroneous debits. You may amend or cancel this authorization at any time
                    in RainHub, LLC's website.
                  </Text>
              </Col>
            </Row>
          </Content>
        </Layout>
      </HubModal>
    </React.Fragment>
  );
}