import { FILE_ACCESS_LEVEL } from '../utils/enums';
import {store} from '../../app/store';
import wrapper from './wrapper';
import { UserConfiguration } from '../../models';
import ConfigClient from './config_client';
import { CanvasUtils } from '../utils';

export default class FileClient {

  /* get access based on area and user */
  public static async uploadUserProfileImage(url:string): Promise<void> {
    /* fetch blob */
    const blob = await (await fetch(url)).blob(); 
    /* upload image */
    let result = await wrapper.putFile(
      "profile.jpeg",
      blob,
      FILE_ACCESS_LEVEL.PROTECTED,
      "image/jpeg"
    );
    /* update configuration */
    const config:any = {profileImageTime: new Date().toISOString()};
    /* push configuration */
    await ConfigClient.updateUserConfiguration(config);
    /* update configuration and update cached file */
    const nConfig = await ConfigClient.getUserConfiguration();
    /* set new configuration */
    store.dispatch({
      type: "auth/setUserConfig",
      payload: nConfig
    });
  }
  /* get access based on area and user */
  public static async getUserProfileImage(download=false): Promise<any> {
    /* upload image */
    return await wrapper.getFile(
      "profile.jpeg",
      FILE_ACCESS_LEVEL.PROTECTED,
      download
    );
  }
  /* get access based on area and user */
  public static async getFile(key:string, download=false, level=FILE_ACCESS_LEVEL.PUBLIC): Promise<any> {
    /* upload image */
    return await wrapper.getFile(
      key,
      level,
      download
    );
  }
  /* get access based on area and user */
  public static async listDirectoryFiles(root:string): Promise<any> {
    /* upload image */
    return await wrapper.listDirectory(
      root,
      FILE_ACCESS_LEVEL.PUBLIC
    );
  }
  /* get access based on area and user */
  public static async uploadStoredMedia(key:string, file:File|Blob, type: string): Promise<any> {
    /* upload image */
    return await wrapper.putFile(
      key,
      file,
      FILE_ACCESS_LEVEL.PUBLIC,
      type
    );
  }
  /* get access based on area and user */
  public static async deleteStoredDirectory(key:string): Promise<any> {
    /* upload image */
    return await wrapper.deleteDirectory(
      key,
      FILE_ACCESS_LEVEL.PUBLIC
    );
  }
  /* get access based on area and user */
  public static async deleteStoredMedia(key:string): Promise<any> {
    /* upload image */
    return await wrapper.deleteFile(
      key,
      FILE_ACCESS_LEVEL.PUBLIC
    );
  }
  /* get access based on area and user */
  public static async getStoredMedia(key:string, download=false, cloudFront=false): Promise<any> {
    if(cloudFront){
      return `${process.env.REACT_APP_CLOUDFRONT_URL}/${key}`
    }else{
      return await wrapper.getFile(
        key,
        FILE_ACCESS_LEVEL.PUBLIC,
        download
      );      
    }
  }

  /* get access based on area and user */
  public static async getMediaToken(url:string): Promise<any> {
    /* access list query */
    const query = `query ($url: String) {
      getMediaAccessToken(url: $url)
    }`;
    /* execute with query with map */
    let {getMediaAccessToken}:any = await wrapper.grapql(query, {url});

    /* if there is data */
    if (getMediaAccessToken) {
      return getMediaAccessToken;
    } else {
      throw Error("Could not retrieve token");
    }
  }

}