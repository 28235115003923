import  { useEffect, useState } from 'react';
import { Tree , Spin} from 'antd';
import type { DataNode, EventDataNode,  } from 'antd/es/tree';
import { Course, CourseDataType, Language } from '../../../../../models';
import {CourseClient} from '../../../../../lib/api';
import { Helpers } from '../../../../../lib/utils';

interface CourseNode extends DataNode {
  type: CourseDataType;
  data: any
}

type CourseListProps = {
  onSelect: (e: any, t: CourseDataType)=>void,
  onDeselect: ()=>void,
  courses: Course[],
  loading: boolean
}

/* build gdata object */
const GDataBuilder = (data:any, children:any, type:any, lang:string):CourseNode =>{
return {
    key: data.id,
    title: data.title[lang],
    type: type,
    data:data,
    children: children? []: undefined
  }
}
/* the courses to tree data transformer */
const courseToGData = (courses: Course[], lang:string): CourseNode[] =>{

  let data: CourseNode[] = [];

  /* for each course */
  for(let c of courses){
    /* course to node */
    let cNode: CourseNode = GDataBuilder(c,  c.modules, CourseDataType.course, lang)
    data.push(cNode);
    /* push modules */
    for(let m of c.modules || []){
      let mNode: CourseNode = GDataBuilder(m,  [], CourseDataType.module,lang);
      cNode.children?.push(mNode);
      /* push elements */
      for(let e of m.elements || []){
        let eNode: CourseNode = GDataBuilder(e, undefined, CourseDataType.element,lang);
        mNode.children?.push(eNode);
      }
    }
  }
  return data;
}

const CourseList = (props: CourseListProps) => {
  
  let [lang] = useState<Language>(Helpers.getUserLang());
  const [currentCourses, setCurrentCourses] = useState<Course[]>([]);
  const [gData, setGData] = useState<CourseNode[]>([]);

  let {
    onSelect, 
    onDeselect,
    courses,
    loading,
  } = props;

  /* runs when courses state changes */
  useEffect(() => {
    setGData(courseToGData(courses,lang));
    setCurrentCourses(courses);
  },[courses])  

  /* on selected item */
  const onNodeSelect = async (selectedKeys: any[], {node}: {node: EventDataNode<CourseNode>}) => {
    if(selectedKeys.length == 0){
      /* if deselected, buble up event */
      onDeselect();
    }else{
      /* if selected, bubble up event */
      onSelect(node.data, node.type);
    }
  }

  const onLoadData = async (node: EventDataNode<CourseNode>) =>{
    /* check if course has it's modules */
    if(node.type === CourseDataType.course && !node.children){
      let oCourse = node.data as Course;
      /* fetch course */
      let nCourse = await CourseClient.getCourseById(oCourse.id);
      let ncourses = currentCourses.map((c)=>{
        if(c.id === nCourse.id){
          return nCourse
        }else{
          return c;
        }
      });
      /* set course list and g data*/
      setCurrentCourses(ncourses);
      setGData(courseToGData(ncourses,lang));
    }
  }

  return (
    <Spin spinning={loading}>
      <Tree
        style={{height: 500, overflowY: "scroll", border: "1px #F7815F solid" }}
        className="draggable-tree"
        blockNode
        onSelect={onNodeSelect}
        loadData={onLoadData}
        treeData={gData}
      />
    </Spin>
  );
};


export default CourseList;