import { CanvasUtils } from '../utils';
import FileClient from './file_client';
import {store} from '../../app/store';
import wrapper from './wrapper';
import { ProfileImage, UserConfiguration, UserProgressInput } from '../../models';
import { DEFAULT_ISO_DATE } from '../utils/constants';

export default class UserClient {

  public static async getAndCacheUserProfileImage(): Promise<any> {
    let config = store.getState().auth.config as UserConfiguration;
    let profilePic = JSON.parse(localStorage.getItem("profile_image") as any) as ProfileImage;
    
    if(profilePic && config.profileImageTime == profilePic.profileImageTime){
      /* nothing to do here, continue */
    }else if (config.profileImageTime !== DEFAULT_ISO_DATE){
      const content = await FileClient.getUserProfileImage(true);
      const base64 = await CanvasUtils.blobToBase64(content.Body);
      /* set the new image in cache */
      profilePic = {
        base64,
        profileImageTime: config.profileImageTime
      };
      /* save to local storage */
      localStorage.setItem("profile_image", JSON.stringify(profilePic));
    }else{
      profilePic = {
        base64: null,
        profileImageTime: config.profileImageTime
      };
    }
    /* dispatch store */
    store.dispatch({
      type: "auth/setProfileImage",
      payload: profilePic
    });
  }
  /* get parent item progress, filtering by text value */
  public static async getParentProgressByTextValue(parentId: string, textValue: string, 
                                                  equals:boolean=true, count:number = 20, 
                                                  nextToken: string | undefined=undefined): Promise<{items:any[],nextToken:string}> {
    /* access list query */
    const query = `query ($parentId: ID, $textValue: String, 
                          $equals:Boolean, $count:Int, $nextToken: String) {

      getParentProgressByTextValue(parentId: $parentId, textValue: $textValue, 
                                  equals: $equals, count: $count, nextToken: $nextToken){
        items {
          textValue
          userId
          numValue
          updatedAt
        }
        nextToken
      }
    }`;
    /* execute with query with map */
    let {getParentProgressByTextValue} :any = await wrapper.grapql(query,{parentId, textValue, equals, count, nextToken});
    return getParentProgressByTextValue;
  }

  /* update course entry*/
  public static async updateUserProgress(entry:UserProgressInput): Promise<any> {

    if(entry.metadata){
      entry = {...entry, metadata: JSON.stringify(entry.metadata)}
    }
    /* access list query */
    const mutation = `mutation ($entry: UserProgressInput) {
      updateUserProgress(entry: $entry){
        parentId
      }
    }`;
    /* execute with query with map */
    let {updateUserProgress} :any = await wrapper.grapql(mutation,{entry: entry});
    return updateUserProgress;
  }

}