
import React, { MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import { Button, Col, Modal, Popconfirm, Row, Spin } from 'antd';
import { TooltipPlacement} from 'antd/lib/tooltip';
import { ButtonType} from 'antd/lib/button';
import { ButtonHTMLType} from 'antd/lib/button/button';
import {CloseOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import logoSrc from '../../../../media/images/logo.png';
import videoBanner from '../../../../media/images/banner-video.png';
import videoErrorBanner from '../../../../media/images/error-video.png';
import ReactPlayer, { Config } from 'react-player';
import { OnProgressProps } from 'react-player/base';
import { Language, LanguageMap } from '../../../../models';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import { TrackProps } from 'react-player/file';
import { CaretLeftOutlined, CaretRightOutlined, FullscreenOutlined} from '@ant-design/icons';
import { isIOS } from 'react-device-detect';

/* workaround issues with worker using require: https://github.com/wojtekmaj/react-pdf/issues/782 */
const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

type HubButtonProps = {
  children?: any,
  icon?: ReactNode,
  style?: React.CSSProperties,
  shake?: boolean,
  size?: SizeType,
  type?: ButtonType | undefined,
  onClick?: MouseEventHandler<HTMLElement> | undefined,
  htmlType?: ButtonHTMLType,
  disabled?: boolean,
  loading?: boolean,
  className?: string
}
export const HubButton = (props:HubButtonProps) => {
  
  let { children, 
        icon,
        shake,
        style={}, 
        size ="large", 
        type ="primary", 
        htmlType, 
        disabled, 
        className,
        loading, 
        onClick
      } = props;
  
  if(shake){
    className = className?`${className} horizontal-shake`: 
                          'horizontal-shake';
  }
  return (
    <Button shape="round"
            style={{...style}}
            className={className}
            loading={loading}
            disabled={disabled}
            icon={icon} 
            size={size} 
            type={type}
            htmlType={htmlType}
            onClick={onClick}>
      {children}
    </Button>
  )
}

type HubModalProps = {
  children: any,
  open: boolean,
  closable?: boolean,
  title?: string | ReactNode,
  titleIcon?: ReactNode,
  showCancel?: boolean,
  showOk?: boolean,
  showScroll?: boolean,
  onCancel?: MouseEventHandler<HTMLElement> | undefined,
  onOk?: MouseEventHandler<HTMLElement> | undefined,
  okText?:string,
  cancelText?:string,
  footer?:ReactNode[],
  fullScreen?: boolean
}

export const HubModal = (props:HubModalProps) => {
  
  let {children, 
      titleIcon,
      fullScreen=false,
      showScroll=false,
      open, 
      footer = [],
      title,
      okText = "Ok",
      cancelText = "Cancel",
      closable = true,
      showOk = true,
      showCancel = true,
      onOk = ()=>{},
      onCancel = ()=>{},
    } = props;
 
  if(showCancel){
    footer.push(<HubButton key="ok" type="default"  onClick={onCancel}>{cancelText}</HubButton>);
  }

  if(showOk){
    footer.push(<HubButton key="cancel" onClick={onOk}>{okText}</HubButton>);
  }

  let styles:React.CSSProperties = {maxHeight: 600};
  
  if(fullScreen){
    styles = {height:window.innerHeight, width: window.innerWidth}
  }
  if(showScroll){
    styles['overflowY'] = 'scroll';
  }

  let tNode = title;
  if(typeof title === 'string'){
    tNode = <HubButton icon={titleIcon} type="link">{title?.toUpperCase()}</HubButton>
  }

  return (
    <Modal  bodyStyle={styles}
            destroyOnClose={true}
            closable={closable}
            closeIcon={<HubTitle ><CloseOutlined/></HubTitle>}
            open={open}
            maskClosable={false}
            onCancel={onCancel}
            onOk={onOk}
            centered={true}
            title={tNode}
            footer={footer}
            
      >
      {children}
    </Modal>
  )
}

type HubTitleProps = {
  children: any,
  size?: SizeType
}
export const HubTitle = (props:HubTitleProps) => {
  
  let { children,
        size = "large"
      } = props;
  
  let sizeClass = "";
  switch(size){
    case "small": sizeClass = "ant-btn-sm";
      break
    case "large": sizeClass = "ant-btn-lg";
      break
  }

  return (
  <span className={`ant-btn-link ${sizeClass}`}>
    {children}
  </span>
  )
}

type HubPopConfirmProps = {
  children: any,
  title?: ReactNode | string,
  titleIcon?: ReactNode,
  position?: TooltipPlacement,
  showCancel?: boolean,
  onCancel?: any,
  onConfirm?: any,
  okText?:string,
  cancelText?:string,
}
export const HubPopConfirm = (props:HubPopConfirmProps) => {
  
  let {children, 
      titleIcon, 
      title,
      okText = "Ok",
      cancelText = "Cancel",
      position = "right",
      onConfirm = ()=>{},
      onCancel = ()=>{}
    } = props;
    
  return (
    <Popconfirm  
            title={title}
            icon={titleIcon}
            okText={okText}
            cancelText={cancelText}
            onConfirm={onConfirm}
            onCancel={onCancel}
            placement={position}
            okButtonProps={{style:{borderRadius:12}}}
            cancelButtonProps={{style:{borderRadius:12}}}      
      >
      {children}
    </Popconfirm>
  )
}


export const HubIcon = () => {
  return <React.Fragment>
          <img style={{marginTop:-4}}height={16} width={16} src={logoSrc}/>&nbsp;
      </React.Fragment>
}

type HubGradientTextProps = {
  children: any,
  forceBlack?: boolean,
  size: number,
  colors: string[]
}
export const HubGradientText = ({colors, children, size, forceBlack}:HubGradientTextProps) => {
  
  let s = colors.map((c, i)=>{
    return `${c} ${i==0?'0':(((i+1)/colors.length)*100).toFixed(0)}%`;
  });

  let styles: React.CSSProperties = {};
  if(forceBlack){
    styles = {
      fontSize: size,
      color: "black"
    }
  }else{
    styles =  {
      fontSize: size,
      backgroundImage: `linear-gradient(to right, ${s.join(' ,')})`,
      color: 'transparent',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
    }
  }

 
  return (
  <span style={styles}>
    {children}
  </span>
  )
}

type HubPriceLabelProps = {
  size: number,
  price: number,
  showCents?: boolean
}
export const HubPriceLabel = ({size, price, showCents=false}:HubPriceLabelProps) => {
  
  let dollars = Math.trunc(price);
  let cents = price.toFixed(2);
  cents = cents.substring(cents.indexOf('.'));

  let dollarsStyle: React.CSSProperties = {
    color:'ForestGreen', 
    fontSize: size, 
    marginTop: -4
  }

  let signStyles: React.CSSProperties = {
    verticalAlign:'top',
    paddingTop: Math.trunc(size*.2), 
    display:'inline-flex', 
    fontSize: Math.trunc(size*.5)
  }

  let centsStyles: React.CSSProperties = {
    ...signStyles,
    color:'ForestGreen'
  }
  return (<Row align='middle' justify='center'>
        <Col>
            <span style={signStyles}>$</span>
            <strong style={dollarsStyle}>
              {dollars}
            </strong>
            {showCents && 
              <span style={centsStyles}>{cents}</span>
            }
        </Col>
    </Row>)
}

type HubVideoPlayerProps = {
  url?: string,
  token?:string,
  banner?:string,
  isYoutube?: boolean,
  subtitles?: Map<string, string>,
  showLoading?: boolean,
  onProgress?: (prog:OnProgressProps)=>void,
}
export const HubVideoPlayer = (props:HubVideoPlayerProps) => {

  let [videoReady, setVideoReady] = useState<boolean>(false);
  let [videoError, setVideoError] = useState<boolean>(false);
  let [internalPlayer, setInternalPlayer] = useState<HTMLVideoElement|null>(null);
  let [trackChangeEventSet, setTrackChangeEventSet] = useState<boolean>(false);
  let [thumbnailLoaded, setThumbnailLoaded] = useState<boolean>(false);
  let [bannerDims, setBannerDims] = useState<{height:number, width:number}>({height:0, width:0});
  let [trackLang, setTrackLang] = useState<string>(localStorage.getItem('track-lang') || 'off');


  let {
    url,
    token,
    banner,
    subtitles,
    showLoading,
    isYoutube,
    onProgress
  } = props;

  /* fetch course */
  useEffect(() => {
    setThumbnailLoaded(false);
  }, [banner]);

  const onVideoReady = (player:ReactPlayer)=>{
    /* set ready timeout */
    setTimeout(()=>setVideoReady(true), 500);
    const internalPlayer = player.getInternalPlayer() as any;
    /* if there is an internal player (it can be youtube) */
    if(internalPlayer && internalPlayer.textTracks){
      setInternalPlayer(internalPlayer);
      // set internal player
      internalPlayer.textTracks.onchange = onSubsTrackChange;

      // print current language
      if(trackLang!=='off'){
        for(let t of internalPlayer.textTracks){
          t.mode = t.language == trackLang? "showing":"hidden";
        }
      }
    }
  }

  /* event listener track change */
  const onSubsTrackChange:any = ({target}:Event): any=>{
    const lst = Array.from(target as TextTrackList);
    const track = lst.find((t)=>t.mode == 'showing');
    if(track){
      localStorage.setItem('track-lang', track.language);
      setTrackLang(track.language)
    }else{
      localStorage.setItem('track-lang', 'off');
      setTrackLang('off')
    }
    
  }

  /* config token if necessary */
  let tokenCfg = {};
  if(token){
    tokenCfg = {
      xhrSetup: (xhr:any, url:any)=> {
        const fullUrl = url + token;
        // console.log(fullUrl)
        xhr.open('GET', fullUrl);
      }
    }
  }
  const tracks: TrackProps[] = [];
  if(subtitles?.size){
    for (let [lang, url] of subtitles || new Map()) {
      tracks.push({
        kind: "subtitles",
        src: url,
        srcLang: lang,
        label: LanguageMap.get(lang) || "",
        default: lang == trackLang
      });
    }
  }

  /* the player configuration */
  const config: Config = {
    file: {
      hlsOptions: tokenCfg,
      forceHLS: !isIOS,
      attributes: { controlsList: 'nodownload', crossOrigin: 'true' },
      forceVideo: true,
      tracks: tracks
    }
  }
  console.log(tracks)
  /* the overlay config */
  const overlayStyles: React.CSSProperties = {
    position: 'relative',
    display: 'block',
    borderRadius: '6px',
    overflow: 'hidden',
    border: '1px solid #FF8642',
    background: 'black'
  }
  const logoStyles: React.CSSProperties = {
    position: 'absolute',
    width: '4%',
    right: '4%',
    bottom: '12%',
    opacity: 0.8
  }

  /* fallback if video fails */
  let fallback = <img alt={'error'} src={videoErrorBanner} style={{width:'100%', maxHeight: '75vh'}}  />

  const bannerOnLoad = ({ target }:any) => {
    const { offsetHeight, offsetWidth } = target;
    setBannerDims({
      height: offsetHeight,
      width: offsetWidth
    })
  };

  const onError = (e:any)=>{
    /* set timeout to report error */
    setTimeout(()=>setVideoError(true), 1000);
    console.log(JSON.stringify(e));
  }

  /* display mode in case of youtube */
  const display =  isYoutube?'inline-block':'contents';
  /* element function */
  const renderElement = ()=>{
    if(banner || !url){
      return  <Spin spinning={!thumbnailLoaded && showLoading} style={{maxHeight: '75vh'}}>
                <img  alt={'bannner'} src={videoBanner} className='noselect'
                      style={{display: thumbnailLoaded?'none':'',height:'100%', maxHeight: '75vh'}} />
                <img  alt={'thumbnail'} src={banner} className='noselect'
                      style={{display: !thumbnailLoaded?'none':'',height:'100%', maxHeight: '75vh'}}
                      onLoad={()=>setThumbnailLoaded(true)} />
              </Spin>
    }else{ 

      /* if iOS fallback to mp4 file */
      if(isIOS){
        url = `${url?.replace('m3u8','mp4')}${token}`
      }
      /* return player */
      return <Spin spinning={!videoReady && !videoError && showLoading} style={{maxHeight: '75vh'}}>
                <img  alt={'bannner'} className='noselect' src={videoError?videoErrorBanner:videoBanner} 
                      style={{width:'100%', display: videoReady && url?'none':''}}
                      onLoad={bannerOnLoad}
                    />
                <ReactPlayer  key={`${url}`}
                              url={url}
                              className={'HubVideoPlayer'}
                              onProgress={onProgress}
                              onReady={onVideoReady}
                              onError={onError}
                              height={bannerDims.height}
                              width={'100%'}
                              fallback={fallback}
                              controls={true}
                              config={config}
                              onContextMenu={(e:any) => e.preventDefault()}
                              style={{display: videoReady?display:'none'}}>
                              </ReactPlayer>
                <img src={logoSrc} style={logoStyles} className='noselect'/>
            </Spin>
    }
  }

  return (
    <div style={overlayStyles}>
          {renderElement()}
    </div>);
}


type HubPDFProps = {
  url: string,
  height?: number,
  width?: number
}
export const HubPDF = ({url, height, width}:HubPDFProps) => {
  

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }:any)=> {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const changePage = (offset:any)=> {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = ()=> {
    changePage(-1);
  }

  const nextPage = ()=> {
    changePage(1);
  }

  const openPdfNewTab = ()=>{
    window?.open(url, '_blank')?.focus()
  }

  return (
    <div>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page height={height}
              pageNumber={pageNumber} 
              renderTextLayer={false} 
              renderAnnotationLayer={false}/>
      </Document>
      <div>
        <p>
          <strong>{pageNumber || (numPages ? 1 : '--')}</strong> / {numPages || '--'}
        </p>
        <HubButton
          type="default"
          disabled={pageNumber <= 1}
          onClick={previousPage}
          icon={<CaretLeftOutlined />}
        >
        </HubButton>
        <HubButton onClick={openPdfNewTab}
                icon={<FullscreenOutlined />}
              ></HubButton>
        <HubButton
          type="default"
          disabled={pageNumber >= (numPages||0)}
          onClick={nextPage}
          icon={<CaretRightOutlined />}
        >
        </HubButton>
      </div>
      </div>
  );
}

export const HubPasswordPolicy = () => {
  return (
    <div>Password must have at least 8 characters long, 1 upper case letter, 1 lower case letter, 1 number, and 1 symbol, example: 
                <br/> <strong>^ $ * . [ ] { } ( ) ? \" ! @ # % & / \ , &gt; &lt; ' : ; | _ ~ ` = + -</strong></div>
  );
}