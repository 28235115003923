export enum Language {
  english = "en",
  espanol = "es",
}

export const LanguageMap = new Map<string, string>([
  ['en', 'english'],
  ['es', 'español']
]);

export enum ColorMode {
  light = "light",
  dark = "dark",
}

export enum ConfigKeys {
  emailSubscriptionCreated = 'emailSubscriptionCreated',
  emailSubscriptionCancelled = 'emailSubscriptionCancelled',
  emailSubscriptionUpdated = 'emailSubscriptionUpdated',
  emailPaymentFailed= 'emailPaymentFailed',
  emailPaymentSucceed= 'emailPaymentSucceed',
  emailCourseReceipt= 'emailCourseReceipt',
  soundSuccess= 'soundSuccess',
  soundInfo='soundInfo',
  soundWarning='soundWarning',
  soundError='soundError'
}

export type AccessEntry = {
  area: string,
  queryName: string,
  roles: string,
  enabled: boolean,
  hasPreview: boolean,
  underMainteinance: boolean
}
/* access types for front-end access table */
export enum AccessType {
  PREVIEW = "PREVIEW",
  FULL = "FULL",
  NONE = "NONE",
  MAINTENANCE = "MAINTENANCE",
  DISABLED = "DISABLED"
}
export type AccessMap ={
  area: string,
  type: AccessType,
  requiredRoles: string[]
}

/* reducer type for map */
export type AccessMapping = { 
  [name: string]: AccessMap 
}
