import { Row, Col, Form, Input, Typography, FormInstance, Divider } from 'antd';
import {  HubButton, HubModal } from '../../../commons/misc/front/hubComponents';
import {MailOutlined, PhoneOutlined} from '@ant-design/icons';

type VerifyEmailModalProps  = {
  open: boolean,
  form: FormInstance,
  onCancel: (a:boolean)=>void,
  onOk: (a:boolean)=>void,
  onResend: (type:"phone_number"|"email")=>void,
  verificationType: "phone_number" |"email"
  value: string
}

const VerifyModal = (props:VerifyEmailModalProps)=>{

  const {
    open,
    form,
    verificationType,
    value,
    onResend,
    onCancel,
    onOk
  } = props;

  const confirmMsg = <div>A confirmation code was send to <strong>{value}</strong> to confirm {verificationType.replace("_"," ")}.</div>
  const icon = verificationType == "phone_number"?<PhoneOutlined />:<MailOutlined />;
  return (
      <HubModal title={`Verify ${verificationType.replace("_"," ")}`}
            titleIcon={icon}
            open={open}
            onCancel={()=> {
              onCancel(false);
              form.resetFields();
            }}
            cancelText="Cancel" 
            showOk={false}>

              <Row style={{textAlign:"center"}}>
                <Col offset={6} span={12}>
                  <Form
                    layout="vertical"
                    form={form}
                    name="confirm"
                    onFinish={onOk}
                    scrollToFirstError
                  >
                    <Form.Item extra={confirmMsg}>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="code"
                            noStyle
                            rules={[{ required: true, message: 'Please provide your email confirmation code.' }]}
                          >
                            <Input placeholder="Confirmation Code"/>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row  style={{textAlign:"center"}}>
                        <Col span={24}>
                          <br/>
                          <HubButton htmlType="submit">
                            Confirm
                          </HubButton>
                          <Divider>or</Divider>
                          <HubButton type="default" onClick={()=>onResend(verificationType)}>Resend</HubButton>
                          <br/>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>

      </HubModal>);

}

export default VerifyModal;