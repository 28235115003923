import Lottie from "lottie-react";
import {Role} from '../../../../lib/utils/enums';
import badgeGold from '../../../../media/animations/badge-gold.json';
import badgeBronce from '../../../../media/animations/badge-bronze.json';
import badgeRed from '../../../../media/animations/badge-red.json';
import badgeSilver from '../../../../media/animations/badge-silver.json';
import badgeBlue from '../../../../media/animations/badge-blue.json';
import { Tag, Tooltip } from "antd";

type HubBadgeProps ={
  role: string,
  height: number,
  showRole?: boolean,
  style?: React.CSSProperties
}

export const colors:Map<string, string> = new Map([
  [Role.ADMIN, '#6EB7FD'],
  [Role.CUSTOMER, '#B37548'],
  [Role.EXEMPT, '#868F9A'],
  [Role.PREMIUM, '#FAA541'],
  [Role.STAFF, '#FA5216']
]);


const HubBadge = (props:HubBadgeProps) => {

let {role, height, showRole=false, style={}} = props;

/* pick the card color */
const bgColor = colors.get(role) as string;
const tagVisible = height > 100;

const badgeStyles: React.CSSProperties = {
  ...style,
  textAlign:'center',
  width: 'fit-content',
  display: 'inline-block'
}
const tagStyles:React.CSSProperties = {
  visibility: showRole && tagVisible?'visible': 'hidden',
  fontSize: Math.ceil(height *(1/10)),
  marginRight: 0,
  top: Math.ceil(height *(1/4)),
  position: 'relative',
  zIndex: 1,
  borderRadius: 8
}

let badgeAnimation:any = "";
switch(role) {
  case Role.ADMIN:
    badgeAnimation = badgeBlue;
    break
  case Role.STAFF:
    badgeAnimation = badgeRed;
    break
  case Role.EXEMPT:
    badgeAnimation = badgeSilver;
    break
  case Role.PREMIUM:
    badgeAnimation = badgeGold;
    break
  case Role.CUSTOMER:
      badgeAnimation = badgeBronce;
      break
  default:
    badgeAnimation = badgeBronce;
}

/* The role title */
const title = <strong>{`${role==Role.CUSTOMER?'Community':role}`.toUpperCase()}</strong>;
return (
    <Tooltip open={showRole && !tagVisible?undefined:false} color={bgColor} placement={'right'} title={title}>
      <div style={badgeStyles} className={"noselect"}>
          <Tag style={tagStyles} color={bgColor}><strong>{title}</strong></Tag>
          <Lottie initialSegment={[1,60]} loop={false} animationData={badgeAnimation} style={{height: height}}/>
      </div>
    </Tooltip>
  )
}


export default HubBadge;