import React, { useEffect, useState } from 'react';
import {Divider, Row, Col} from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import VerificationModal from './verificationModal';
import AddPaymentMethodModal from './addPaymentMethodModal'
import { PendingBankAccountIntent, StripePaymentMethod } from '../../../../models';
import { StripeClient } from '../../../../lib/api';
import {PlusOutlined, StarTwoTone } from '@ant-design/icons';
import { Gateway } from '../../../commons/gateway/front/Gateway';
import { HubButton, HubPopConfirm } from '../../../commons/misc/front/hubComponents';
import CreditCard from './CreditCard';
import BankAccount from './BankAccount';
import { block } from '../../../commons/misc/front/miscSlice';
import { Attention } from '../../../../lib/utils/notification';
import { Area } from '../../../../lib/utils/enums';

const PaymentMethod = () => {
  
  let [isVerificatioModalVisible, setIsVerificationModalVisible] = useState(false);
  let [isAddPaymentMethodModalVisible, setIsAddPaymentMethodModalVisible] = useState(false);
  let [paymentMethodList, setPaymentMethodList] = useState(new Array<StripePaymentMethod>());
  let [pendingBankAccountList, setPendingBankAccountList] = useState(new Array<PendingBankAccountIntent>());
  let [selectedPendingBankAccount, setSelectedPendingBankAccount] = useState<PendingBankAccountIntent|null>(null);

  useEffect(() => {
      (async () => {
        await reloadPaymentMethods();
      })();
  }, []);

  const reloadPaymentMethods = async () => {
        await block(async()=>{
          let paymentMethodList = await StripeClient.read_payment_methods();
          let pendingBankAccountList = await StripeClient.read_pending_bank_accounts();
          setPaymentMethodList(paymentMethodList.paymentMethods);
          setPendingBankAccountList(pendingBankAccountList);
        });
  }
  const setPaymentMethodAsDefault = async (index: number) => {
        if(paymentMethodList.length > 0) {
          try{
            await block(async()=>{
              const pm = paymentMethodList[index];
              await StripeClient.set_default_payment_method(pm.id, false);
              let list = await StripeClient.read_payment_methods();
              setPaymentMethodList(list.paymentMethods);
              Attention.notifySuccess(
                'Default payment method changed!',
                `From now on, this payment method will be charged.`
              )
            });
          }catch(error:any){
            Attention.notifyError(
              'Set default payment error.',
              error.message
            )
          }

        }
  }
  const deletePaymentMethod = async (index: number) => {
      try {
        if(paymentMethodList.length > 0) {
            await block(async()=>{
              const pm = paymentMethodList[index];
              await StripeClient.delete_payment_method(pm.id);
              await reloadPaymentMethods();
            });
            Attention.notifySuccess(
              "Successfully deleted payment method",
              "This payment method won't be charged at any time."
            );
        }
      } catch (error) {
        Attention.notifyError(
          "Deleting Payment Method failed",
          "Could not delete this payment method. Please try again later."
        );
      }
  }


  function renderPaymentMethodActionsActions(pm: StripePaymentMethod, index: number) {

    let actions: React.ReactNode[] = [];

    if(!pm.isDefault) {
      actions.push(
        <HubPopConfirm
            key={"default-btn"}
            position={"bottom"}
            title="Are you sure you want to set as default?"
            onConfirm={() => setPaymentMethodAsDefault(index)}
            okText="Yes"
            cancelText="No">
            <HubButton type="default" size={'middle'}>Set As Default</HubButton>
        </HubPopConfirm>,
        <span key={"space"}>&nbsp;</span>,
        <HubPopConfirm
            key={"delete-btn"}
            position={"bottom"}
            title="Are you sure you want to delete this payment method?"
            onConfirm={() => deletePaymentMethod(index)}
            okText="Yes"
            cancelText="No">
            <HubButton type="default"  size={'middle'}><DeleteOutlined /></HubButton>
        </HubPopConfirm>
      );
    }else{
      actions.push(
          <HubButton key={"default-btn"} type="default" size={'middle'} icon={<StarTwoTone twoToneColor={["orange", "orange"]} />}>
            <strong>Default</strong>
          </HubButton>
      );
    }

    return actions;
  }

  const renderCreditCards = ()=>{

    let cards = [];
    for(let { index, item } of paymentMethodList.map((item, index) => ({ index, item }))){
      if(item.type == "card"){
        cards.push(
          <Col key={item.number}>
            <div style={{margin:6, textAlign: "center"}}>
              <CreditCard country={item.country} 
                          number={item.number.replaceAll('*','')} 
                          type={item.bank.toUpperCase()}
                          key={item.number}/>
              <div style={{marginTop: 6}}>
                {renderPaymentMethodActionsActions(item, index)}
              </div>
            </div>
          </Col>
        )
      } 
    }
    return cards;
  }
  const renderBankAccounts = ()=>{

    let accounts = [];
    for(let { index, item } of paymentMethodList.map((item, index) => ({ index, item }))){
      if(item.type == "bank_account"){
        accounts.push(
          <Col key={item.number}>
            <div style={{margin:6, textAlign: "center"}}>
              <BankAccount country={item.country} 
                          number={item.number.replaceAll('*','')} 
                          type={item.bank.toUpperCase()}
                          key={item.number}/>
              <div style={{marginTop: 6}}>
                {renderPaymentMethodActionsActions(item, index)}
              </div>
            </div>
          </Col>
        )
      } 
    }
    return accounts;
  }
  const renderPendingBankAccounts = ()=>{

    let accounts = [];
    for(let item of pendingBankAccountList){
      accounts.push(
          <Col key={item.number}>
            <div style={{margin:6, textAlign: "center"}}>
              <BankAccount country={"!"} 
                          number={item.number.replaceAll('*','')} 
                          type={item.bank.toUpperCase()}
                          key={item.number}/>
              <div style={{marginTop: 6}}>
                <HubButton 
                    icon={<WarningOutlined style={{color: "#eed202"}} />}
                    onClick={() => { 
                      setIsVerificationModalVisible(true); 
                      setSelectedPendingBankAccount(item); 
                    }}>Verify</HubButton>
              </div>
            </div>
          </Col>
        ) 
    }
    return accounts;
  }

  return (
      <React.Fragment>
          <Row>
              <Col span={24} style={{textAlign:'center'}}>
                  <HubButton icon={<PlusOutlined/>} onClick={() => setIsAddPaymentMethodModalVisible(true)}>
                    Add Payment Method
                  </HubButton>
              </Col>
          </Row>
          <br/>
          {/* If there are credit cards */}
          {paymentMethodList.some((m)=>m.type=="card") &&
            <React.Fragment> 
              <Row>
                  <Col span={24}>
                      <Divider><HubButton type={"link"} >Credit Cards </HubButton></Divider>
                  </Col>
              </Row>
              <Row justify='center'>
                    {renderCreditCards()}
              </Row> 
            </React.Fragment>
          }
          {/* If there are bank accounts */}
          {paymentMethodList.some((m)=>m.type=="bank_account") &&
            <React.Fragment> 
              <Row>
                  <Col span={24} >
                      <Divider><HubButton type={"link"} >Bank Accounts </HubButton></Divider>
                  </Col>
              </Row>
              <Row justify='center'>
                    {renderBankAccounts()}
              </Row> 
            </React.Fragment>
          }
          {pendingBankAccountList.length > 0 &&
            <React.Fragment> 
              <Row>
                  <Col span={24} >
                      <Divider><HubButton type={"link"} >Unverified Bank Accounts </HubButton></Divider>
                  </Col>
              </Row>
              <Row justify='center'>
                    {renderPendingBankAccounts()}
              </Row> 
            </React.Fragment>
          }
          <VerificationModal isModalVisible={isVerificatioModalVisible} setIsModalVisible={setIsVerificationModalVisible} selectedPendingBankAccount={selectedPendingBankAccount} reloadPaymentMethods={reloadPaymentMethods} />
          <AddPaymentMethodModal isModalVisible={isAddPaymentMethodModalVisible} setIsModalVisible={setIsAddPaymentMethodModalVisible} reloadPaymentMethods={reloadPaymentMethods} />
      </React.Fragment>
  );
}


export default Gateway(PaymentMethod, Area.ACCOUNT_WALLET);