import {Role} from "../../../../lib/utils/enums";

/* the communication prefix */
export const pfx = `${process.env.REACT_APP_ENVIRONMENT || process.env.ENVIRONMENT}-`;
/* AREA ENUMERATOR */
export enum TopicBase {
  /* system core messages */
  SYSTEM = "rainhub-pubsub/system",
  DIRECT = "rainhub-pubsub/user",
  /* role specific messages */
  ADMIN = "rainhub-pubsub/role/admin",
  STAFF = "rainhub-pubsub/role/staff",
  EXEMPT = "rainhub-pubsub/role/exempt",
  PREMIUM = "rainhub-pubsub/role/premium",
  CUSTOMER = "rainhub-pubsub/role/customer",
  PUBLIC = "rainhub-pubsub/role/public" 
}

/* the area endpoint map */
export const TopicRoleMap = new Map<string, Set<string>>([
  /* role-topic map */
  [Role.ADMIN, new Set<string>([
    TopicBase.ADMIN
  ])],
  [Role.STAFF, new Set<string>([
    TopicBase.STAFF
  ])],
  [Role.EXEMPT, new Set<string>([
    TopicBase.EXEMPT
  ])],
  [Role.PREMIUM, new Set<string>([
    TopicBase.PREMIUM
  ])],
  [Role.CUSTOMER, new Set<string>([
    TopicBase.CUSTOMER
  ])],
  [Role.PUBLIC, new Set<string>([
    TopicBase.PUBLIC
  ])],
]);

/* system message types */
export enum SystemMsgType {
  /* system core messages */
  REFRESH_ACCESS = "REFRESH_ACCESS",
  REFRESH_APPLICATION = "REFRESH_APPLICATION",
  LOGOUT_APPLICATION = "LOGOUT_APPLICATION"
}

/* the models for messages are here */
export interface SystemMsgBase {
  type: SystemMsgType
}
/* system message interfaces */
export interface SystemRefreshAccessMsg extends SystemMsgBase {
}
export interface SystemRefreshApplicationMsg extends SystemMsgBase {
}
export interface SystemLogoutApplicationMsg extends SystemMsgBase {
}

/* direct message types */
export enum DirectMsgType {
  /* system core messages */
  TOKEN_REFRESHED = "TOKEN_REFRESHED",
  MEMBERSHIP_UPGRADED = "MEMBERSHIP_UPGRADED",
  MEMBERSHIP_DOWNGRADED = "MEMBERSHIP_DOWNGRADED",
}

/* the models for messages are here */
export interface DirectMsgBase {
  type: DirectMsgType
}
/* system message interfaces */
export interface DirectTokenRefresedMsg extends DirectMsgBase {
  hash: number
}
export interface DirectMembershipUpgradeddMsg extends DirectMsgBase {
  membership: string
}
export interface DirectMembershipDowngradeddMsg extends DirectMsgBase {
  membership: string
}