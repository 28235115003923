
import React from 'react';
import Lottie from "lottie-react";
import '@dotlottie/player-component';
import {
  Link
} from "react-router-dom";
import notFound from '../../../../media/animations/404.json';

const PageNotFound = () => {

  return (
    <React.Fragment>
      <Lottie loop={true} animationData={notFound} style={{width:500}} />
    </React.Fragment>
  )
}


export default PageNotFound;