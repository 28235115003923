import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { store } from '../../../../app/store';

/* define state */
interface MiscState {
  isLoading: boolean
}

/* create initial state object */
const initialState: MiscState = {
  isLoading: false
}
/* create slice with reducers */
export const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    }
  },
});
/* export actions */
export const { setIsLoading } = miscSlice.actions
/* export function to be used as Ui blocking */
export const block = async (fn:() => Promise<any>): Promise<any> => {
/* start loading */
  store.dispatch({
    type: "misc/setIsLoading",
    payload: true
  });

  let result;
  try{
    result = await fn();
  }catch(e: any){
    throw e;
  }finally{
    store.dispatch({
      type: "misc/setIsLoading",
      payload: false
    });
  }
  return result;
}
/* export reducer */
export default miscSlice.reducer