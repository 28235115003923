import { Card, Col, Divider, Input, Row, Spin } from 'antd';
import Layout from 'antd/lib/layout/layout';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { FileClient } from '../../../lib/api';
import {ResourceClient} from '../../../lib/api';
import {  Helpers } from '../../../lib/utils';
import { Resource, Language, UserProgress } from '../../../models';
import { HubButton, HubTitle } from '../../commons/misc/front/hubComponents';
import { block } from '../../commons/misc/front/miscSlice';
import AnalyticsClient, { AnalyticsEvent } from '../../../lib/api/analytics_client';
import { ResourceAccessType, ResourceColorMap } from '../../../models/Resource';
import newBadge from '../../../media/animations/new-yellow.json';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lottie from 'lottie-react';

const gridStyle: React.CSSProperties = {
  height: '70px',
  display: 'flex',
  padding: '12px',
  textAlign: 'center',
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  alignContent: 'center'
}

const { Search } = Input;
const typeColors  = {...ResourceColorMap} as any;
/* extend to search free and premium */
typeColors['free'] = '#6DE5F2'
typeColors['premium'] = '#F2BD1D'

/* image for list dimensions */
const imgWidth = 264;
const imgHeight = 174;


type SearchableResource = Resource & {searchTxt: string, dayPosted: number}

const ResourceList: React.FC = () =>  {
  let navigate = useNavigate();
  /* the dynamic sizing for grid */
  const griRowdRef = useRef<any>(null);
  const [gWidth, setGwidth] = useState<number>(0);
  const [gHeight, setGheight] = useState<number>(0);

  let [lang] = useState<Language>(Helpers.getUserLang());
  let [isStaff] = useState<boolean>(Helpers.isUserStaff());
  let [availableTypes, setAvailableTypes] = useState<Set<string>>(new Set());
  let [resources, setResources] = useState<SearchableResource[]>([]);
  let [showList, setShowList] = useState<SearchableResource[]>([]);
  let [textFilter, setTextFilter] = useState<string>("");
  let [typeFilter, setTypeFilter] = useState<Set<string>>(new Set());
  let [thumbnails, setThumbnails] =useState<Map<string, string>>(new Map());

  /* runs when component is mount */
  useEffect(() => {
    (async () => {
      let [resources, progress] = await block(async ()=>{
        let requests = [
          fetchResources(),
          ResourceClient.getResourceProgress()
        ]
        return await Promise.all(requests);
      }); 
      /* transfer progress for this user */
      resources = assignUserProgress(resources, progress);
      /* resolve thumbnail */
      let thumbnails, thumbnailStatus;
      [thumbnails, thumbnailStatus] = await generateThumbnailObjects(resources);

      /* add searchable textfield to resources */
      let avalTypes = new Set<string>();
      let today = new Date();
      for(const r of resources as SearchableResource[]){
        /* build searchable text */
        r.searchTxt = (r.title[lang] + r.tags.join(',')).toLocaleLowerCase();
        avalTypes.add(r.resourceType);
        /* calculate day posted */
        let difference = today.getTime() - new Date(r.createdAt).getTime();
        r.dayPosted = Math.ceil(difference / (1000 * 3600 * 24));
      }
      /* sort types */
      avalTypes = new Set(['free','premium',...Array.from(avalTypes).sort()]);


      /* filter the ones that should not be shown */
      resources = resources.filter((c:Resource)=>c.published || isStaff)

      /* REMOVE */
      // resources = [...resources,...resources,...resources,...resources,
      //             ...resources,...resources,...resources,...resources,
      //             ...resources,...resources,...resources,...resources];

      /* set elements */
      setResources(resources);
      setShowList(resources);
      setThumbnails(thumbnails);
      setAvailableTypes(avalTypes);
      
      /* set the height and width */
      setGheight(griRowdRef.current?.clientHeight||1);
      setGwidth(griRowdRef.current?.clientWidth||1);
    })();
  }, []);

  /* runs when component is mount */
  useEffect(() => {
    if(textFilter || typeFilter.size > 0){
      let filtered:SearchableResource[] = [];

      let premiumOnly = typeFilter.has('premium');
      let freeOnly = typeFilter.has('free');
      let both = premiumOnly && freeOnly;

      /* remove access filters */
      let currentFilters = new Set(typeFilter);
      currentFilters.delete('premium');
      currentFilters.delete('free');

      /* filter by text */
      for(const r of resources as SearchableResource[]){
        /* by default it will be included */
        let included = true;
        /* if there is search text and does not match switch flag */
        if(textFilter && !r.searchTxt.includes(textFilter)){
          included = false;
        }
        /* if there is type filters and this resource is not of filtered types switch flag */
        if(currentFilters.size > 0 && !currentFilters.has(r.resourceType)){
          included = false;
        }

        /* if free only and it is not open, remove */
        if(!both && freeOnly && r.access !== ResourceAccessType.open){
          included = false;
        }
        /* if premium only and it is not open, remove */
        if(!both && premiumOnly && r.access !== ResourceAccessType.exclusive){
          included = false;
        }

        /* finally, if it matches everything, add it */
        if(included){
          filtered.push(r);
        }
      }
      /* set the filtered list */
      setShowList(filtered);
    }else{
      setShowList(resources);
    }
  }, [textFilter, typeFilter]);

  /* assing user progress to list */
  const assignUserProgress = (resources:Resource[], progress: UserProgress[])=>{
    /* first create a map */
    let m = progress.reduce((map:any,p)=>{
      return {...map, [p.parentId]: p};
    },{});
    /* set progress to all resources */
    for(let c of resources){
      c.progress = m[c.id];
    }
    return resources;
  }

  const generateThumbnailObjects = async (resources:Resource[]): Promise<[Map<string, string>, Map<string, boolean>]>=>{
    let requests = [];
    for(let r of resources){
      let key = Helpers.buildResourceSourceURL(r, r.resourceSourceType, 'thumbnail_small.jpeg');
      requests.push(FileClient.getStoredMedia(key,false));
    }
    let keys =  await Promise.all(requests);
    let kMap = new Map<string, string>();
    let rMap = new Map<string, boolean>();
    for(let i = 0; i < resources.length; i++){
      kMap.set(resources[i].id, keys[i]);
      rMap.set(resources[i].id, false);
    }
    return [kMap, rMap];
  }
  /* fetch resources from backend */
  const fetchResources = async (): Promise<Resource[]>=>{
    return await ResourceClient.getResources();
  }

  const selectResource = (id: string, title: string)=>{
    navigate(id);
    AnalyticsClient.record(AnalyticsEvent.openCourse,{
      title,
      id
    });
  }

  const viewsStyles: React.CSSProperties = {
    border: "1px #FF8642 solid",
    position: 'absolute',
    bottom: 0,
    display: 'block',
    right: '1%'
  }

  const newBadgeStyles: React.CSSProperties = {
    width:'60px',
    position: 'absolute',
    top: '1px',
    display: 'block',
    right: '1px'
  }



  const renderResource = (lst:SearchableResource[])=>{

    return lst.map((c:SearchableResource)=>{

      let color = typeColors[c.resourceType];
      let typeBadgeStyles: React.CSSProperties = {
        position: 'absolute',
        bottom: 0,
        display: 'block',
        left: 0,
        background: color, 
        color:'white', 
        border:`${color} 1px solid`,
        borderRadius: 0,
        borderTopRightRadius: 12
      }
      /* set opacity in case it is unlisted */
      const opacity = !c.published && isStaff? 0.5:1
      const visitedColor = c.progress?'#9eaded':'white';
      const visitedBordeColor = c.progress?'#6a78ba':'#FF8642';
      /* return card component */
      return <Col key={c.id}>
        <div style={{borderRadius: 16, overflow: 'auto', border: `1px ${visitedBordeColor} solid`}}>
        <Card
          onClick={()=>selectResource(c.id, c.title[lang])}
          hoverable
          style={{ width: imgWidth, opacity: opacity }}
            cover={
              <div style={{width: imgWidth, height: imgHeight, background:'#6a78ba', position: 'relative'}}>
                <LazyLoadImage
                  alt={c.title[lang]}
                  width={imgWidth} 
                  effect="blur"
                  src={thumbnails.get(c.id)} 
                  style={{borderBottom: `1px ${visitedBordeColor} solid`}}
                  />
                <HubButton  style={{...viewsStyles,background: visitedColor}} size='small' type='default'>{c.viewCount} 👁️</HubButton>
                <HubButton  style={typeBadgeStyles} size='small'>{c.resourceType}</HubButton>
                {c.dayPosted <= 7 && 
                  <Lottie loop={true} animationData={newBadge} style={newBadgeStyles}/>
                }
              </div>
            }
          >
          <Card.Grid hoverable={false} style={{...gridStyle,width:'100%', fontWeight:'600'}}>
              <h4>
                {c.title[lang]}
              </h4>
          </Card.Grid>
        </Card>
        </div>
      </Col>
    })
  }

  const renderToggleGroup = ()=>{

    const buttons:any = [];
    
    const s = new Set(typeFilter);
    for(const k of availableTypes){
      let color = s.has(k)?typeColors[k]:'';
      let styles = {background: color, 
                    margin:1, 
                    color: color?'white':'black', 
                    border:`${typeColors[k]} 1px solid`}
      buttons.push(<Col key={k}>
        <HubButton style={styles}
                  onClick={()=>{
                    if(s.has(k)){
                      s.delete(k)
                    }else{
                      s.add(k)
                    }
                    setTypeFilter(s);
                  }}
                  type='default'
                  size={'small'}
                  >{k}</HubButton>
        </Col>)
      if(k =='premium'){
        buttons.push( <Divider type="vertical" />)
      }
    }

    return buttons;
  }

  const srchMsg = lang === Language.english? "Search Resources": "Buscar Recursos";

  return  (<Layout style={{marginTop: 24, height:'96%'}}>
      <Row justify='center'>
        <Col span={24}>
          <Row justify='center'>
              <Col sm={12} md={10} lg={8} xl={6}>
                <div className='resourceSearch'>
                  <Search 
                          size='large' 
                          placeholder={srchMsg} 
                          onSearch={(v:string)=>setTextFilter(v.toLocaleLowerCase())}
                          allowClear
                          enterButton/>
                </div>
              </Col>
          </Row>
          <Divider> 
            <HubButton size='small' onClick={()=>setTypeFilter(new Set())}>Clear Filters</HubButton>
          </Divider>
          <Row justify='center'>
            {renderToggleGroup()}
          </Row>
        </Col>
      </Row>
      <Divider > <HubTitle size='middle'><strong>{showList.length}</strong></HubTitle>&nbsp;results</Divider>
      <div ref={griRowdRef}
            style={{height:'100%', width:'100%', overflow:'auto', overflowX:'hidden'}}>
          <Row  gutter={[6, 6]} justify='center' align='top' style={{maxHeight:gHeight}} >
                  {renderResource(showList)}
          </Row>
      </div>
  </Layout>);
 
};

export default ResourceList;