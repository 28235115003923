import { Layout, Tabs } from 'antd';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";
import menuCreditCard from '../../../media/animations/credit-card.json';
import menuSettings from '../../../media/animations/settings.json'
import menuUserInfo from '../../../media/animations/info.json';
import menuCart from '../../../media/animations/cart.json';
import Lottie from "lottie-react";
import PaymentMethod from './wallet';
import Subscriptions from './subscriptions';
import Profile from './profile';
import Settings from './settings';
import { isMobile } from 'react-device-detect';
import { Gateway } from '../../commons/gateway/front/Gateway';
import { Area } from '../../../lib/utils/enums';


const { Content} = Layout;

const Account = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selectedTab = pathname.split('/')[2] || 'profile';

  /* navigate key */
  const onTabClick:any = (key: string)=>{
    navigate(key)
  }
  
  return (
      <Layout>
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px'}}>
            <Routes>
                <Route path="profile" element={<span></span>}/>
                <Route path="wallet" element={<span></span>}/>
                <Route path="subscriptions" element={<span></span>}/>
                <Route path="settings" element={<span></span>}/>
                <Route path="*" element={<Navigate to="profile" replace />} />
              </Routes>
            <br/><br/>
            <Tabs activeKey={selectedTab} 
                  tabPosition={isMobile?"top":"left"} 
                  onTabClick={onTabClick}
                  destroyInactiveTabPane={true}
              items={[
                  {
                    label:(<span>
                          <Lottie loop={true} animationData={menuUserInfo} style={{width:'90px', margin:'-14px'}}/>
                          Profile
                        </span>),
                    key:"profile",
                    children:<Profile />
                  },
                  {
                    label:(  <span>
                      <Lottie loop={true} animationData={menuCart} style={{width:'80px', margin:'-10px'}}/>
                      Subscriptions
                    </span>),
                    key:"subscriptions",
                    children:<Subscriptions />
                  },
                  {
                    label:(  <span>
                      <Lottie loop={true} animationData={menuCreditCard} style={{width:'80px', margin:'-12px'}}/>
                      Wallet
                    </span>),
                    key:"wallet",
                    children:<PaymentMethod />
                  },
                  { 
                    label:(  <span>
                      <Lottie loop={true} animationData={menuSettings} style={{width:'80px', margin:'-8px'}}/>
                      Settings
                    </span>),
                    key:"settings",
                    children:<Settings />
                  }
              ]}/>
            </Content>
        </Layout>
      </Layout>)
};

/* component styles */
const styles = {
  logo:{
      width: '100%',
      marginBottom: 15,
      userSelect: 'none',
      pointerEvents: 'none'
  },
  row:{
      height: '100%',
      width: '100%'
  },
  col: {
      textAlign: 'center'
  }
};
export default Gateway(Account, Area.ACCOUNT);