import { notification } from 'antd';
import {store} from '../../app/store';
import succcessAudioFile  from '../../media/audio/success.mp3';
import infoAudioFile  from '../../media/audio/info.mp3';
import warningAudioFile  from '../../media/audio/warning.mp3';
import errorAudioFile  from '../../media/audio/error.mp3';
import {Howl} from 'howler';
import { ConfigKeys, UserConfiguration } from '../../models';


export enum MsgType {
  SUCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error"
}

export enum AttentionPos {
  TOP = "top",
  BOTTOM = "bottom",
  TOP_LEFT = "topLeft",
  TOP_RIGHT = "topRight",
  BOTTOM_LEFT = "bottomLeft",
  BOTTOM_RIGHT = "bottomRight"
}

/* the content types for the desired text */
type contentType = string | JSX.Element;

export class Attention {
    /* more sounds effects at: https://pixabay.com/sound-effects/search/game/ */
    /* instantiante audios */
  public static successSound = new Howl({src: succcessAudioFile, preload: true});
  public static infoSound = new Howl({src: infoAudioFile, preload: true});
  public static warningSound = new Howl({src: warningAudioFile, preload: true});
  public static errorSound = new Howl({src: errorAudioFile, preload: true});

  public static playSound(sound:Howl, configProp: string): any {
    const config =  store.getState().auth.config as UserConfiguration;
    const {soundConfigs, systemVolume} = config || {soundConfigs:null, systemVolume:null};
    /* only play if enabled */
    if(soundConfigs && soundConfigs[configProp]){
      sound.volume(systemVolume);
      sound.play();
    }
  }

  public static notification(msg:contentType, desc: contentType, type:MsgType, duration:number=4.5, position:AttentionPos = AttentionPos.TOP_RIGHT): any {
    /* show notification */
    notification[type]({
      message: msg,
      description: desc,
      placement: position,
      duration
    })
  }
  public static notifyError(msg:contentType, desc: contentType = "", duration:number=4.5, position:AttentionPos = AttentionPos.TOP_RIGHT): any {
    Attention.playSound(Attention.errorSound, ConfigKeys.soundError);
    Attention.notification(msg, desc, MsgType.ERROR,duration, position);
  }
  public static notifySuccess(msg:contentType, desc: contentType = "", duration:number=4.5, position:AttentionPos = AttentionPos.TOP_RIGHT): any {
    Attention.playSound(Attention.successSound, ConfigKeys.soundSuccess);
    Attention.notification(msg, desc, MsgType.SUCESS, duration, position);
  }
  public static notifyInfo(msg:contentType, desc: contentType = "", duration:number=4.5, position:AttentionPos = AttentionPos.TOP_RIGHT): any {
    Attention.playSound(Attention.infoSound, ConfigKeys.soundInfo);
    Attention.notification(msg, desc, MsgType.INFO, duration, position);
  }
  public static notifyWarning(msg:contentType, desc: contentType = "", duration:number=4.5, position:AttentionPos = AttentionPos.TOP_RIGHT): any {
    Attention.playSound(Attention.warningSound, ConfigKeys.soundWarning);
    Attention.notification(msg, desc, MsgType.WARNING, duration, position);
  }
}
