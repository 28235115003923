import React, { useState } from 'react';
import {Layout, Button, Row, Col, notification} from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import {loadStripe} from '@stripe/stripe-js';
import { StripeClient } from '../../../../../lib/api';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { User } from '../../../../../models';
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js';
import { block } from '../../../../commons/misc/front/miscSlice';
import { CheckCircleOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { HubButton } from '../../../../commons/misc/front/hubComponents';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

export default function AddCard(props: any) {
  return (
    <Elements stripe={stripePromise}>
      <CardForm props={props} />
    </Elements>
  );
}

function CardForm({props}: any) {
    const dispatch = useAppDispatch();
    const {Header, Content} = Layout;

    const user = useAppSelector((state) => state.auth.user) as User;
    const stripe = useStripe();
    const elements = useElements();
    let [canSave, setCanSave] = useState<boolean>(false)

    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };

    const cardElementChange = (event: StripeCardElementChangeEvent) => {
      setCanSave(event.complete && !event.error);
    }

    const saveCard = async () => {
        try {

          await block(async()=>{
            const cardElement = elements?.getElement("card");
            if(cardElement) {
              const pm = await stripe?.createPaymentMethod({
                type: "card",
                card: cardElement,
                billing_details: {
                  name: `${user.given_name} ${user.family_name}`
                }
              });
              if(pm && pm.paymentMethod) {
                
                return await StripeClient.add_card_to_customer(pm.paymentMethod.id);
              }
            }
          });

          notification.open({
            message: "Successfully added Card",
            description: "",
            icon: <CheckCircleOutlined style={{color: "green"}}/>,
          });
          props.close();
          await props.reloadPaymentMethods();

        } catch (error) {
          // console.log("Big error", error);
          notification.open({
              message: 'Adding Card Failed',
              description: 'Couldn\'t add the card. Please try again later.',
              icon: <CloseCircleOutlined style={{color: "red"}}/>,
          });
        }
    }

    const goTo = (page: number) => {
        if(props.goTo)
            props.goTo(page);
    }

    return (
        <React.Fragment>
            <Layout>
                <Header style={{background: "white"}}>
                    <Row>
                        <Col span={2}>
                            <HubButton type="default" onClick={() => goTo(0)}>Back</HubButton>
                        </Col>
                    </Row>
                </Header>
                <Content style={{background: "white", paddingLeft: 25, paddingRight: 25, paddingTop: 45}}>
                    <Row>
                        <Col span={24}>
                          <CardElement options={CARD_ELEMENT_OPTIONS} onChange={cardElementChange} />
                          <br />
                          <br />
                          <HubButton disabled={!canSave} icon={<SaveOutlined />} onClick={saveCard} >Save</HubButton>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </React.Fragment>
    );
}