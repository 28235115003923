import { Col, Row, Divider, Image, Card } from 'antd';
import React, {  useEffect, useState } from 'react';
import { useNavigate, useParams} from 'react-router';
import { ResourceClient} from '../../../lib/api';
import { Resource, Language,  ResourceSourceStatus, User, ResourceSourceType } from '../../../models';
import { block } from '../../commons/misc/front/miscSlice';
import MDEditor from '@uiw/react-md-editor';
import { HubButton, HubGradientText, HubPDF, HubTitle, HubVideoPlayer } from '../../commons/misc/front/hubComponents';
import stringHash from "@sindresorhus/string-hash";
import { Gradients, Helpers } from '../../../lib/utils';
import { isMobile } from 'react-device-detect';
import PageNotFound from '../../commons/misc/front/404';
import { FileClient } from '../../../lib/api';
import { Role } from '../../../lib/utils/enums';
import AnalyticsClient, { AnalyticsEvent } from '../../../lib/api/analytics_client';
import lock from '../../../media/animations/lock.json';
import { useAppSelector } from '../../../app/hooks';
import { LinkOutlined} from '@ant-design/icons';
import Lottie from "lottie-react";
import { ResourceAccessType, ResourceColorMap } from '../../../models/Resource';


const typeColors  = ResourceColorMap as any;

const ResourceBody: React.FC = () =>  {

  const { resourceId } = useParams();
  const user = useAppSelector((state:any) => state.auth.user) as User;
  let navigate = useNavigate();
  const [lang] = useState<Language>(Helpers.getUserLang());
  let [subtitles, setSubtitles] = useState(new Map<string, string>());
  let [resource, setResource] = useState<Resource|null>(null);
  let [resourceFetched, setResourceFetched] = useState<boolean>(false);
  let [sourceToken, setSourceToken] = useState<string>("");
  let [thumbnailUrl, setThumbnailUrl] = useState<string>("");
  let [fullSourceUrl, setFullSourceUrl] = useState<string>("");

  /* check if premium */
  const isPreview =  user.role == Role.CUSTOMER && resource?.access !== ResourceAccessType.open;

  let cardDivStyles: React.CSSProperties = {
    borderRadius: 16, 
    overflow: 'auto', 
    border: "1px #FF8642 solid", 
    display: 'inline-block',
    maxWidth: 500}
  let iconStyles:React.CSSProperties = {
    opacity:0.8,
    top: '40%',
    fontSize: 64, 
    color: '#FF8642', 
    background:'white',
    borderRadius: 12, 
    border: "1px #FF8642 solid", 
  }

  /* fetch course */
  useEffect(() => {
    (async () => {
      if(resourceId){
        try{
          await block(async ()=>{
            let resource = await ResourceClient.getResourceById(resourceId);
            /* if no progress, create user progress as visited */
            if(!resource.progress && !isPreview){
              resource = await ResourceClient.setResourceAsVisited(resourceId);
            }
            /* TODO Fetch media url */
            await fetchResourceSourceURL(resource);
            /* set resource */
            setResource(resource);
            /*send analytics event */
            reportAnalyticsEvent();
          }); 
        }finally{
          setResourceFetched(true);
        }
      }
    })();
  }, [resourceId]);

  const reportAnalyticsEvent = ()=>{
    AnalyticsClient.record(AnalyticsEvent.viewEducationResource,{
      title: resource?.title[lang] || "",
      id: resource?.id || ""
    });
  }

  const fetchResourceSourceURL = async (resource: Resource)=>{

    /* if rainhub video, fetch subtitles */
    switch(resource.resourceSourceType){
      case ResourceSourceType.image:
      case ResourceSourceType.pdf:
      case ResourceSourceType.rainhub_video:
        let sourceKey = Helpers.buildResourceSourceURL(resource,resource.resourceSourceType, '', true, true);
        let sourceUrl = await FileClient.getStoredMedia(sourceKey,false, true);
        let root = sourceKey.substring(0, sourceKey.lastIndexOf('/'));
        let token = await FileClient.getMediaToken(root);
        let fullUrl = `${sourceUrl}${token}`;
        /* set the state */
        setSourceToken(token);
        if(resource.resourceSourceType === ResourceSourceType.rainhub_video){
          setFullSourceUrl(sourceUrl)
        }else{
          setFullSourceUrl(fullUrl);
        }
        break;
      case ResourceSourceType.youtube:
      case ResourceSourceType.website:
        setFullSourceUrl(resource.source);
    }

    /* fetch thumbnail url */
    let thumbnailKey = Helpers.buildResourceSourceURL(resource, resource.resourceSourceType, 'thumbnail_small.jpeg');
    let thumbnailUrl = await FileClient.getStoredMedia(thumbnailKey,false);
    setThumbnailUrl(thumbnailUrl);
    if(resource.sourceStatus === ResourceSourceStatus.Ready){
      await fetchResourceSubtitles(resource)
    }

  }

  const fetchResourceSubtitles = async (data: Resource) =>{
    const root = Helpers.buildStoredSubtitleRoot(data, 'resource');
    let subs = await FileClient.listDirectoryFiles(root);
    let present = new Map<string, string>();
    for(let s of subs){
      let lang = s.replace(/^.*[\\\/]/, '').split('.')[0];
      let key = await FileClient.getFile(s);
      present.set(lang,key);
    }
    /* set the new map */
    setSubtitles(present);
  } 


  /* if resource is not fetched, return none */
  if(!resourceFetched){
    return <></>
  }else if(resourceFetched && !resource){
    return ( 
      <Row justify='center' align='middle'>
        <Col style={{textAlign:'center'}}>
        <PageNotFound/>
          <HubTitle size='large'>Sorry, this resource could not be found...</HubTitle>
          <br/><br/>
          <HubButton onClick={()=>navigate('/education')}>Back to Education</HubButton>
        </Col>
      </Row>)
  }

  const renderRainHubVideo = ()=>{
    const isYoutube = resource?.resourceSourceType == ResourceSourceType.youtube;
    return <HubVideoPlayer 
        isYoutube={isYoutube}
        url={fullSourceUrl}
        subtitles={subtitles} 
        token={sourceToken} 
        showLoading={true}/>
  }

  const renderImage = ()=>{
    const imageStyle: React.CSSProperties = {
      height:'60vh',
      border: "1px #FF8642 solid"
    }
    return   <Image
        style={imageStyle}
        src={fullSourceUrl}
      />
  }

  const renderPdf = ()=>{
    return (<HubPDF url={fullSourceUrl} height={window?.innerHeight*.6}/>);
  }

  const renderWebsite = () =>{
    let e = document.createElement('a');
    e.href = fullSourceUrl;

    return (
    <div style={cardDivStyles}>
        <Card
        onClick={()=>{
          window?.open(fullSourceUrl, '_blank')?.focus()
        }}
        hoverable
        cover={<div>
            <img src={thumbnailUrl} style={{ borderBottom: "1px #FF8642 solid",maxWidth: 450}} />
            <LinkOutlined className='centered' style={iconStyles} />
          </div>
        }>
        <Card.Grid hoverable={false} style={{width:'100%'}}>
            <HubGradientText forceBlack size={22} colors={[]}>
              Go to <strong>{e.hostname}</strong>
            </HubGradientText>
        </Card.Grid>
      </Card>
    </div>)
  }

  const renderVisualElement = ()=>{
    /* the element reference */
    let element = null;
    /* if video is present */
    if(fullSourceUrl){
      switch(resource?.resourceSourceType){
        case ResourceSourceType.rainhub_video:
        case ResourceSourceType.youtube:
          element =renderRainHubVideo();
          break;
        case ResourceSourceType.image:
          element = renderImage();
          break;
        case ResourceSourceType.pdf:
          element = renderPdf();
          break;
        case ResourceSourceType.website:
            element = renderWebsite();
            break;
      }
    }
    /* if there is an element, return */
    if(element){
      return (
        <Row justify='center' align='middle'>
          <Col span={20} style={{textAlign:'center', marginBottom:10}}>
              {element}
          </Col>
        </Row>
      )
    }
  }


  const renderUpgradeMembership = ()=>{
    return (
      <Row justify='center' align='middle'>
      <Col span={20} style={{textAlign:'center', marginBottom:10}}>
        <div style={cardDivStyles}>
        <Card
          onClick={()=> navigate('/account/subscriptions')}
          hoverable
          cover={<div style={{ borderBottom: "1px #FF8642 solid",maxWidth: 350}}>
            <Lottie  loop={true} animationData={lock} />
          </div>
        }>
        <Card.Grid hoverable={false} style={{width:'100%'}}>
            <HubGradientText forceBlack size={22} colors={[]}>
              <HubButton > UPGRADE TO ACCESS</HubButton> 
            </HubGradientText>
        </Card.Grid>
       </Card>
      </div>
      </Col>
    </Row>)
  }

  const renderTags = () =>{
    return resource?.tags.map(t=>{
      return <HubButton key={t} type='default' size='middle'>{t}</HubButton>;
    });
  }

  const color = typeColors[resource?.resourceType as any];
  const typeBadgeStyles: React.CSSProperties = {
    background: color, 
    color:'white', 
    border:`${color} 1px solid`
  }

  return <>
      <Row align='middle'>
        <Col span={isMobile?24:18}>
          <Row align='middle' justify='start'>
            <Col >
              <HubButton size='large' onClick={()=>navigate('/education')}>Go Back</HubButton>
            </Col>
            <Col style={{paddingLeft: 6}}>
              <HubGradientText forceBlack size={isMobile?18:30} colors={Gradients[stringHash(resource?.id as string)%Gradients.length]}>
                <strong>{resource?.title[lang]}</strong>
              </HubGradientText>
              {isPreview &&
                <HubGradientText forceBlack size={isMobile?16:28} colors={Gradients[stringHash(resource?.id as string)%Gradients.length]}>
                  <strong>{" 🔒 (PREVIEW)"}</strong>
                </HubGradientText>
              }
            </Col>
            <Col style={{paddingLeft: 6}}>
                <HubButton  size='middle' type='default' style={{borderColor: '#FF8642',background: resource?.progress?'#9eaded':'white'}}>
                  {resource?.viewCount} 👁️
                </HubButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[6,2]} justify='center'>
        <Col span={24}>
            {isPreview?renderUpgradeMembership():renderVisualElement()}
            <Row>
              <Col span={1}></Col>
              <Col span={22} style={{padding:4 ,
                                      fontFamily:'roboto', 
                                      borderTop:'solid 1px #FF8642' }} >
                <HubButton  style={typeBadgeStyles}>{resource?.resourceType}</HubButton>
                {renderTags()}
              </Col>
            </Row>
            <Row justify='center' align='top' data-color-mode="light">
              <Col span={22}>             
                <MDEditor.Markdown source={resource?.description[lang]} 
                  style={{ padding:24 ,borderRadius: 8, fontFamily:'roboto', border:'solid 1px #FF8642' }} 
                  linkTarget="_blank" 
                /> 
              </Col>
            </Row>
        </Col>
      </Row>
    </>;
}

export default ResourceBody;