import { Role } from "../lib/utils/enums";
import { ColorMode, Language } from "./General";


export type UserProgress = {
  userId: string
  parentId: string
  type: ProgressType
  numValue: number
  textValue: string
  completed: string
  metadata: any
  createdAt: string
  updatedAt: string
}

export enum ProgressType {
  CourseProgress = "courseProgress",
  ResourceProgress = "resourceProgress"
}

export type UserProgressInput = {
  parentId: string
  numValue?: number
  textValue?: string
  completed?: string
  metadata?: any
}

export type ProfileImage = {    
  profileImageTime: string,
  base64: string | null
}

export type User ={
  sub:                   string;
  username:              string;
  gender:                string;
  email_verified:        boolean;
  "custom:discord_user": string;
  "custom:stripe_id":    string;
  "custom:language":     Language;
  role:                  Role;
  given_name:            string;
  family_name:           string;
  email:                 string;
  accessToken:           string;
  accessTokenExp:        number;
  accessTokenIssued:     number;
  idToken:               string;
  idTokenExp:            number;
  idTokenIssued:         number;
  refreshToken:          string;
  phone_number:          string;
  phone_number_verified: boolean;
  profileImage?:         ProfileImage;  
}

export type ContextUser ={
  username:              string;
  sub:                   string;
  givenName:             string;
  lastName:              string;
  email:                 string;
  language:              Language;
  discordUser:           string;
  stripeId:              string;
  groups:                string;  
}


export interface UserConfiguration {
  profileImageTime: string,
  isPubSubConfigured?: boolean,
  systemVolume: number,
  language: Language,
  colorMode: ColorMode,
  emailConfigs: {[key: string]:boolean},
  smsConfigs: {[key: string]:boolean},
  soundConfigs: {[key: string]:boolean},
  updatedAt?: string
}
