import { UserOutlined } from '@ant-design/icons';
import { Avatar, Divider , Upload, 
        UploadProps} from 'antd';
import React, { useState} from 'react';
import { Attention } from '../../../../lib/utils/notification';
import { RcFile } from 'antd/lib/upload';
import { getOrientation } from 'get-orientation/browser'
import { CanvasUtils, Helpers } from '../../../../lib/utils';
import ImageEditModal from '../../../commons/misc/front/imageEditModal';
import { FileClient } from '../../../../lib/api';
import UserClient from '../../../../lib/api/user_client';
import { useAppSelector } from '../../../../app/hooks';
import { User } from '../../../../models';
import HubBadge from '../subscriptions/Badge';
import AnalyticsClient, { AnalyticsEvent } from '../../../../lib/api/analytics_client';


const ORIENTATION_TO_ANGLE:any = {
  '3': 180,
  '6': 90,
  '8': -90,
}


const ProfilePic: React.FC<any> = ({disabled, size}) => {

  const user = useAppSelector((state) => state.auth.user) as User;
  const [imageSrc, setImageSrc] = useState<string|null>(null);
  const badgeStylesOverwrite: React.CSSProperties = {
    position: 'relative',
    float: 'right',
    display:'flex',
    top: `-${size* (size > 60?0.5:0.8)}px`
  };

  const props: UploadProps = {
    disabled: disabled,
    showUploadList: false,
    maxCount: 1,
    accept:"image/jpeg,image/png",
    beforeUpload: async (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        Attention.notifyError("Invalid Image", "Profile image needs to be in PNG or JPEG format.")
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        Attention.notifyError("Invalid Image", 'Image must be smaller than 2 MB.');
        return false
      }
      /* crop load image data */
      let imageDataUrl = await Helpers.readFile(file) as string;
      const orientation: any = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await CanvasUtils.getRotatedImage(imageDataUrl, rotation);
      }

      /* set image source */
      setImageSrc(imageDataUrl);

      /* cancel event */
      return false;
    }
  };


  return <div style={{textAlign:"center"}}>
    {/* the crop modal */}
    <ImageEditModal 
      imageSrc={imageSrc}
      onComplete={async (croppedImg:string)=>{        
        if(croppedImg){
          /* set cropped source */
          try{
            /* upload image to profile picture */
            await FileClient.uploadUserProfileImage(croppedImg);
            /* download and cache image */
            await UserClient.getAndCacheUserProfileImage();
            /* notify success */
            Attention.notifySuccess(
              "Profile Picture Set!",
              "Your profile picture is up to date."
            );
            AnalyticsClient.record(AnalyticsEvent.setProfilePicOk);
          }catch(e:any){
            Attention.notifyError(
              "Upload Error",
              "Cannot upload profile image at this time, try again later."
            );
            AnalyticsClient.record(AnalyticsEvent.setProfilePicFailed);
          }
        }
        /* remove original */
        setImageSrc(null);
      }}/>
    <div style={{width:"min-content", display:"inline-block"}}>
      <Upload {...props}>
        <Avatar
              src={user?.profileImage?.base64}
              size={size}
              style={{cursor: disabled?"default":"grab", border:"3px solid #FF8642"}}
              icon={<UserOutlined />}
              ></Avatar>
      </Upload>
      <HubBadge height={size/2.5} role={user.role} style={badgeStylesOverwrite} showRole={true}/>
    </div>
  </div>
};

export default ProfilePic;