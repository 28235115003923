
import { ProgressType, Resource, ResourceInput, ResourceUserProgress} from '../../models'
import wrapper from './wrapper';


/* full resource properties string */
const resourceProps = `
  access
  createdAt
  createdBy
  description
  id
  level
  ordering
  published
  resourceSourceType
  resourceType
  source
  sourceStatus
  tags
  thumbnail
  title
  updatedAt
  viewCount
`;
const fullResourceProps = `
  access
  createdAt
  createdBy
  description
  id
  level
  ordering
  published
  resourceSourceType
  resourceType
  source
  sourceStatus
  tags
  thumbnail
  title
  updatedAt
  viewCount
  progress {
    createdAt
    metadata
  }
`;

/* post process resource, sort elements and modules and parse progress */
const postProcessResource= (resource: Resource)=>{
    /* parse title an description */
    resource.title = JSON.parse(`${resource.title}`);
    resource.description = JSON.parse(`${resource.description}`);

    /* parse resource progress metadata if present */
    if(resource.progress){
      let metadata = resource.progress.metadata as any;
      resource.progress.metadata = JSON.parse(metadata);
    }
}

/* post process resource, sort elements and modules and parse progress */
const preprocessResource = (el: any)=>{
  return {
    ...el,
    title: el.title?JSON.stringify(el.title):undefined,
    description: el.description?JSON.stringify(el.description):undefined
  }
}

export default class ResourceClient {

  /* Create resource graphql function*/
  public static async createResource(resource:ResourceInput): Promise<any> {

    /* access list query */
    const mutation = `mutation ($resource: ResourceInput) {
      createResource(newResource: $resource) {
        id
      }
    }`;
    /* stringify props */
    resource = preprocessResource(resource);
    /* execute with query with map */
    let {createResource}:any = await wrapper.grapql(mutation,{resource});
    /* return resource id */
    return createResource.id;
  }
  /* update resource entry*/
  public static async updateResource(resource:ResourceInput): Promise<any> {
    /* access list query */
    const mutation = `mutation ($entry: ResourceInput) {
      updateResource(entry: $entry){
        id
      }
    }`;
    /* stringify props */
    resource = preprocessResource(resource);
    /* execute with query with map */
    let {updateResource} :any = await wrapper.grapql(mutation,{entry: resource});
    return updateResource;
  }
 /* Create resource graphql function*/
 public static async deleteResource(id:string): Promise<any> {

    /* access list query */
    const mutation = `mutation ($id: ID) {
      deleteResource(resourceId: $id) {
        status
      }
    }`;
    /* execute with query with map */
    let result:any = await wrapper.grapql(mutation,{id});
    /* return resource id */
    return result;
  }
  /* update resource entry*/
  public static async setResourceAsVisited(resourceId:string): Promise<Resource> {
    /* access list query */
    const mutation = `mutation ($resourceId: ID) {
      setResourceAsVisited(resourceId: $resourceId){
        ${fullResourceProps}
      }
    }`;
    /* execute with query with map */
    let {setResourceAsVisited} :any = await wrapper.grapql(mutation,{resourceId});
    /* post process resource */
    postProcessResource(setResourceAsVisited);
    return setResourceAsVisited;
  }
  /* Create resource graphql function*/
  public static async getResources(): Promise<Resource[]> {

    /* access list query */
    const query = `query {
      getResources {
        resources {
          ${resourceProps}
        }
      }
    }`;

    /* execute with query with map */
    let {getResources}:any = await wrapper.grapql(query, {});

    /* if there is data */
    if (getResources) {
      let resources = getResources.resources.sort((a:Resource,b:Resource)=>a.ordering - b.ordering);
      for(let c of resources){
        /* parse title an description */
        c.title = JSON.parse(`${c.title}`);
        c.description = JSON.parse(`${c.description}`);
      }
      /* sort by ordering and return */
      return resources;
    } else {
      throw Error("Could not retrieve resources!");
    }

  }
  /* Create resource graphql function*/
  public static async getResourceProgress(): Promise<ResourceUserProgress[]> {

    /* access list query */
    const query = `query ($type: progressType) {
      getUserProgressByType(type: $type){
        items {
          createdAt
          metadata
          parentId
          type
          updatedAt
          numValue
          textValue
          completed
          userId
        }
      }
    }`;
    /* execute with query with map */
    let {getUserProgressByType: {items}} = await wrapper.grapql(query,{type: ProgressType.ResourceProgress});
    /* the user progress completely parsed */
    return items.map((i:any)=>{return {...i, metadata: JSON.parse(i.metadata)}});
  }
  /* Create resource graphql function*/
  public static async getResourceById(id:string): Promise<Resource> {
    console.log(id)
    /* access list query */
    const query = `query ($id: ID) {
      getResourceById(resourceId: $id){
        ${fullResourceProps}
      }
    }`;
    /* execute with query with map */
    let {getResourceById}:{getResourceById:Resource} = await wrapper.grapql(query,{id});
    /* post process resource */
    postProcessResource(getResourceById);
    /* if there is data */
    return getResourceById;
  }

}