import { Layout, Tabs } from 'antd';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";
import Lottie from "lottie-react";
/* local components */
import { Gateway } from '../../../commons/gateway/front/Gateway';
import AdminCourses  from './courses'
import AdminResources from './education'
import books from '../../../../media/animations/books.json';
import resources from '../../../../media/animations/resources.json';
import { Area } from '../../../../lib/utils/enums';


const { Content} = Layout;
const AdminContent = () => {
  
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selectedTab = pathname.split('/')[3] || "education";
  /* navigate key */
  const onTabClick:any = (key: string)=>{
    navigate(`content/${key}`);
  }
  
  return (
          <Content >
            <Routes>
              <Route path="content/courses" element={<span></span>}/>
              <Route path="content/education" element={<span></span>}/>
              <Route path="content/*" element={<Navigate to="education" replace />} />
            </Routes>
          <Tabs activeKey={selectedTab} tabPosition={"top"} onTabClick={onTabClick}  destroyInactiveTabPane={true}
            style={{height:"100%"}}
            items={[
              {
                label:(<div style={{textAlign:"center"}}>
                      <Lottie loop={true} animationData={books} style={{height:'80px', margin:'0px'}}/>
                      Education
                    </div>),
                key:"education",
                children:<AdminResources/>
              },
              {
                label:(<div style={{textAlign:"center"}}>
                      <Lottie loop={true} animationData={resources} style={{height:'60px', marginTop:'20px'}}/>
                      Courses
                    </div>),
                key:"courses",
                children:<AdminCourses/>
              }
            ]}/>
          </Content>)
};

export default Gateway(AdminContent, Area.ADMIN_CONTENT);