import React from "react";

import { HubButton, HubModal, HubTitle } from "../../commons/misc/front/hubComponents";
import { Col, Layout, Row } from "antd";
import sleepingPup from '../../../media/animations/puppy-sleeping.json';
import sleepingPupBlack from '../../../media/animations/puppy-black-sleeping.json';
import Lottie from "lottie-react";
import { useNavigate } from "react-router";

const { Content } = Layout;

type ReloadModalProps = {
  isOpen:boolean,
  idleHours: number
}

const ReloadModal = ({
  isOpen,
  idleHours
}: ReloadModalProps) => {

  const navigate = useNavigate();

  return (
    <HubModal
      title={<HubTitle>YOU WENT TO SLEEP</HubTitle>}
      open={isOpen}
      closable={false}
      showCancel={false}
      showOk={false} 
    > 
      <Layout>
        <Content style={{ backgroundColor: "white", textAlign: "center" }}>
            <Row align="middle" justify="center">
              <Col>
                {(Math.random()*101|0)%2 === 0?
                  <Lottie loop={true} animationData={sleepingPup} style={{margin: "-60px"}} />:
                  <Lottie loop={true} animationData={sleepingPupBlack} style={{width: "300px"}} />
                }
              </Col>
              <Col span={24}>
                  <h2>
                    The platform has been <strong>idle for more than {idleHours} hours</strong>, we need to do a quick reload.
                  </h2>
              </Col> 
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }} span={24}>
                <HubButton onClick={()=> navigate(0)} size='large'>Reload Now</HubButton> 
              </Col>
            </Row>

        </Content>
      </Layout>
    </HubModal>
  );
};

export default ReloadModal;
