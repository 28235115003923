import {  Menu, MenuProps } from 'antd';
import { useEffect, useState } from 'react';
import { Course, CourseDataType, Language } from '../../../models';
import { StarTwoTone, UnorderedListOutlined, AppstoreAddOutlined, ReadFilled } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { HubButton } from '../../commons/misc/front/hubComponents';
import AnalyticsClient, { AnalyticsEvent } from '../../../lib/api/analytics_client';
import { Helpers } from '../../../lib/utils';


type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
  label: string,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  itemType?: CourseDataType,
  data?: any,
  level?: number,
  style: any = {whiteSpace: 'normal', height: 'auto'},
): MenuItem => {

  if(label.length > 25){
    style = {...style, lineHeight: 2}
  }

  if(label.includes('📦')){
    style = {...style, opacity: 0.7} 
  }

  let labelNode;
  if(level == 0){
    labelNode = <h4 style={{border: '1px dashed lightgray', borderRadius:6, padding: 12}}>{label}</h4>;
  }else{
    labelNode = label;
  }
  
  return {
    key,
    icon,
    children,
    label: labelNode,
    itemType,
    data,
    style
  } as MenuItem;
}

type ContentMenuProps = {
  progressMap: Map<string, boolean>,
  selectedKey: string,
  onSelect: (e: any, t: CourseDataType)=>void,
  course: Course
}

const introLang = {
  [Language.english]: "Introduction",
  [Language.espanol]: "Introducción"
}

const ContentMenu = ({course, onSelect, selectedKey, progressMap}: ContentMenuProps) =>  {

  const [isStaff] = useState<boolean>(Helpers.isUserStaff());
  const [menuItems, setMenuItems] = useState<ItemType[]>([]);
  const [lang] = useState<Language>(Helpers.getUserLang());
  /* runs when courses state changes */
  useEffect(() => {
    setMenuItems(buildMenuItems(course))
  },[course, progressMap])  

  const completeSymbol = (e: any)=>{
    if(course.progress){
      return progressMap.get(e.id)?' 🔵':' ⚪'
    }else{
      return '';
    }
  }

  const buildMenuItems = (course: Course) =>{
    let lock = !course.progress?'🔒 ':'';
    let items: ItemType[] = [];

    /* insert course item first */
    items.push(getItem(`${course.title[lang]}: Intro${completeSymbol(course)}`, course.id, <StarTwoTone  />, undefined, CourseDataType.course, course, 0))
    /* for each module */
    for(let m of course.modules || []){
      let elements:ItemType[]  = [];
      /* check if module is not published */
      if(!m.published && !isStaff) continue;
      /* set opacity in case it is unlisted */
      let moduleHidden = !m.published && isStaff? '📦 ':'';
      /* add first element as intro of module */
      elements.push(getItem(`${lock||moduleHidden}${introLang[lang]}${completeSymbol(m)}`, m.id, <ReadFilled />, undefined, CourseDataType.module, m, 1));
      /* add actual elements */
      let cnt = 0;
      for(let e of m.elements || []){
        /* check if element is not published */
        if(!e.published && !isStaff) continue;
        /* set opacity in case it is unlisted */
        let elementHidden = !e.published && isStaff? '📦 ':'';
        /* increase counter and build item */
        cnt+= progressMap.get(e.id)?1:0;
        elements.push(getItem(`${lock||elementHidden}${e.title[lang]}${completeSymbol(e)}`, e.id, <AppstoreAddOutlined />, undefined, CourseDataType.element, e, 1))
      }
      /* verify if module is fully completed */
      let modCplt = cnt == m.elements.length && progressMap.get(m.id)?' 🔵':' ⚪';
      /* add module to items */
      items.push(getItem(`${lock||moduleHidden}${m.title[lang]}${modCplt}`, `${m.id}-module`, <UnorderedListOutlined  />, elements,  CourseDataType.module, m, 0));
    }

    return items;
  }

  const menuContainerStyles: React.CSSProperties = {
    width: '100%',
    height: '75vh',
    // overflowY: 'scroll'
  }
  const menuStyles: React.CSSProperties = {
    border: '1px solid #FF8642',
    padding: 2,
    borderRadius: 6
  }

  const itemOnSelect = ({item}:any)=>{
    let {itemType, data} = item.props;
    onSelect(data, itemType);
    AnalyticsClient.record(AnalyticsEvent.clickCourseElement,{
      courseTitle: course.title[lang],
      courseId: course.id,
      type: itemType,
      title: data.title[lang],
      id: data.id
    });
  }

  return (
  <div style={menuContainerStyles} >
    <Menu onSelect={itemOnSelect}
          theme="light" 
          multiple={false}
          defaultOpenKeys={course.modules?.map((m)=>`${m.id}-module`)}
          selectedKeys={[selectedKey]}
          forceSubMenuRender={true}
          style={menuStyles}
          mode="inline" 
          items={menuItems} />
  </div>);
}

export default ContentMenu;