/* configure aws amplify */
import './config-amplify';
import {useEffect, useState} from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import Lottie from "lottie-react";
import appLoading from './media/animations/loading-clock.json';
import appDisconnected from './media/animations/no-internet-dinnosaur.json';
import Landing from './components/main/front/Landing';
import Main from './components/main/front/Main';
import { useAppSelector } from './app/hooks';
import { Helpers } from './lib/utils';
import './App.less';
import { block } from './components/commons/misc/front/miscSlice';
import { HubGradientText } from './components/commons/misc/front/hubComponents';
import { isMobile } from 'react-device-detect';
import IncompatibleModal from './components/commons/misc/front/IncompatibleModal';

const App = () => {

  const user = useAppSelector((state) => state.auth.user);
  const access = useAppSelector((state) => state.auth.access);
  const config = useAppSelector((state) => state.auth.config);
  const isLoading = useAppSelector((state) => state.misc.isLoading);
  const hasInternet = useAppSelector((state) => state.pubsub.hasInternet);
  const [showIncompatibleModal, setShowIncompatibleModal] = useState(false);
  const [sessionChecked, setSessionChecked] = useState(false);

  useEffect(() => {
    (async ()=>{
      /* check if mobile, if so show incompatible modal */
      // setShowIncompatibleModal(isMobile)
      /* try to load user */
      try{
        await block(async()=>{
          await Helpers.refreshUser(true);
        });
      }catch(e){
      }
      setSessionChecked(true);
    })()
  }, []);

  
  let component;
  if(user && access && config){
    component = <Main/>
  }else{
    component = <Landing/>
  }
  
  let animation:any = appLoading;
  let style:any = {width:'100px'};
  let text:any = "";
  if(!hasInternet){
    animation = appDisconnected;
    text = <HubGradientText forceBlack size={24} colors={['#c36424', '#de9bd9']}><strong>{"WAITING FOR INTERNET"}</strong></HubGradientText>;
    style = {width:'160px', marginLeft:"54px", marginBottom:"-12px"};
  }

  const isActive = isLoading || !hasInternet;
  
  return (<LoadingOverlay
      active={isActive}
      className={"loading-overlay"}
      text={text}
      spinner={
        <Lottie loop={true} 
        animationData={animation}
        style={style}
        />} 
        > 
        <div style={{height:'100%', width:'100%'}}>
          {sessionChecked?component:null}
        </div>
        <IncompatibleModal isOpen={showIncompatibleModal} onOk={()=> setShowIncompatibleModal(false)}/>
  </LoadingOverlay>)

}

export default App;