import { UserProgress} from './User';

export enum CourseDataType {
  course = "course",
  module = "module",
  element = "element"
}
export enum ThumbnailStatus {
  None = "none",
  Ready = "ready"
}
export enum VideoStatus {
  None = "none",
  Ready = "ready",
  Processing = "processing",
  Uploaded = "uploaded"
}
export enum CourseLevelType {
  Beginner = "beginner",
  Intermediate = "intermediate",
  Advanced = "advanced",
  Specialized = "specialized"
}

export type CourseInput = {
  id?: string
  title?: {[key: string]: string}
  published?: boolean
  level?: CourseLevelType
  tags?: Array<string>
  description?: {[key: string]: string}
  ordering?: number
  price?: number
  video?: VideoStatus
  thumbnail?: ThumbnailStatus
}

export type Course = {
  id: string
  title: {[key: string]: string}
  published: boolean
  level: CourseLevelType
  totalTimeHours: number
  tags: Array<string>
  description: {[key: string]: string}
  ratingCount: number
  ratingTotal: number
  studentCount: number
  ordering: number
  price: number
  video: VideoStatus
  thumbnail: ThumbnailStatus
  createdBy: string
  modules?: Array<CourseModule>
  userReviews?: Array<CourseUserProgress>
  progress?: CourseUserProgress
  createdAt: string
  updatedAt: string
}

export interface CourseUserProgress extends Omit<UserProgress, 'metadata'> { 
  metadata:{
    transactionId: string,
    completeElements:{
      [key:string]: boolean
    }
  }
}

export type CoursePage = {
  courses: Array<Course>
  nextToken?: string
}

export type CourseModuleInput = {
  courseId: string
  id?: string
  title?: {[key: string]: string}
  published?: boolean
  description?: {[key: string]: string}
  tags?: Array<string>
  thumbnail?: ThumbnailStatus
  ordering?: number
}

export type CourseModule = {
  courseId: string
  id: string
  title: {[key: string]: string}
  published: boolean
  description: {[key: string]: string}
  totalTimeHours: number
  tags: Array<string>
  ordering: number
  thumbnail: ThumbnailStatus
  elements: Array<CourseModuleElement>
  createdBy: string
  createdAt: string
  updatedAt: string
}

export type CourseModuleElementInput = {
  courseId: string
  moduleId: string
  id?: string
  title?: {[key: string]: string}
  published?: boolean
  description?: {[key: string]: string}
  tags?: Array<string>
  ordering?: number
  video?: VideoStatus
  thumbnail?: ThumbnailStatus
}

export type CourseModuleElement = {
  courseId: string
  moduleId: string
  id: string
  title: {[key: string]: string}
  published: boolean
  description: {[key: string]: string}
  totalTimeHours: number
  tags: Array<string>
  ordering: number
  thumbnail: ThumbnailStatus
  video: VideoStatus
  createdBy: string
  createdAt: string
  updatedAt: string
}

export enum UserCourseReportType {
  purchased = 'purchased',
  reviewed = 'reviewed',
  finish = 'reviewed'
}