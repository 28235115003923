import {User} from '../../models'
import { Analytics } from 'aws-amplify';

export enum AnalyticsEvent {
  loginOk = 'login_ok',
  loginFailed = 'login_failed',
  logout = 'logout',
  signUp = 'signup',
  resendConfirmationOk = 'resend_confirmation_ok',
  resendConfirmationFailed = 'resend_confirmation_failed',
  discordNotFound = 'discord_not_found',
  discordFound = 'discord_found',
  dualSession = 'dual_session',
  pubsubNotSend ='pubsub_not_send',
  pubsubDisconnect='pubsub_disconnect',
  saveProfile='save_profile',
  confirmEmailOk='confirm_email_ok',
  confirmEmailFailed='confirm_email_failed',
  confirmPhoneOk='confirm_phone_ok',
  confirmPhoneFailed='confirm_phone_failed',
  openCourse='open_course',
  openCourseReview='open_course_review',
  clickCourseElement='click_course_element',
  collapseSidePanel='collapse_side_panel',
  expandedSidePanel='expanded_side_panel',
  setProfilePicOk='setProfilePicOk',
  setProfilePicFailed='setProfilePicFailed',
  viewEducationResource ='viewEducationResource'
}

type AnalyticsAttributes = {
  [key: string]:string
}
type Analyticsmetrics = {
  [key: string]:number
}

export default class AnalyticsClient {

  /* get access based on area and user */
  public static async update(user: User): Promise<void> {

    /* update analytics endpoint */
    await Analytics.updateEndpoint({
      userId: user.sub,
      userAttributes: {
        email: [user.email],
        given_name: [user.given_name],
        family_name: [user.family_name],
        discord_user: [user['custom:discord_user']]
      }
    });
  }

  public static record(name:AnalyticsEvent, attributes: AnalyticsAttributes={}, metrics:Analyticsmetrics={} ){
    Analytics.record({
      name,
      attributes,
      metrics
    });
  }

  
}