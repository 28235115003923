
import { Col, Layout, Row , Divider} from "antd";
import { useState } from 'react';
import { HubButton, HubModal, HubTitle } from "../../misc/front/hubComponents";
import DiscordIdentity, { DiscordIcon } from "./discord-identity";
import AnalyticsClient, { AnalyticsEvent } from '../../../../lib/api/analytics_client';
import { GeneralClient } from '../../../../lib/api';
import { block } from '../../../commons/misc/front/miscSlice';
import { Attention } from "../../../../lib/utils/notification";
import { Helpers } from "../../../../lib/utils";

const { Content } = Layout;

type ReloadModalProps = {
  isOpen:boolean
}


const JoinDiscordModal = ({
  isOpen
}: ReloadModalProps) => {


  const [showNotFound, setShowNotFound] = useState(false);

  /* this is the callback for discord username fetch */
  const validateDiscordUsername = async (username: string)=>{
    
    let {isValid, userId} = await block(async ()=>{
      return await GeneralClient.validateDiscordUser(username);
    });

    if(isValid){
      await Helpers.refreshUser();
      AnalyticsClient.record(AnalyticsEvent.discordFound, {username});
    }else{
      AnalyticsClient.record(AnalyticsEvent.discordNotFound, {username});
      setShowNotFound(true);
      Attention.notifyError("Discord user not found in Community");
    }
  }

  return (
    <HubModal
      open={isOpen}
      closable={false}
      showCancel={false}
      showOk={false} 
    > 
      <Layout>
        <Content style={{ backgroundColor: "white", textAlign: "center" }}>
          <br/>

          
          <Row align="middle" justify="center">
              <Col span={24}>
                  <h3>
                    One more step to start using the platform! Connect your <strong>Discord User</strong>.
                  </h3>
                  <h2>
                    We recommend using a <strong>Computer</strong> 💻 for this step.
                  </h2>
              </Col> 
          </Row>
          <br/>
          <Row gutter={10} justify="center">
            <Col span={12}>
              <DiscordIdentity onUsername={validateDiscordUsername}/>
            </Col>
          </Row>

          {showNotFound &&
                <>
                <Divider></Divider>
                <Row align="middle" justify="center">
                    <Col span={24}>
                        <h4>
                          <HubTitle>Not part of our <strong>Discord Community</strong>? </HubTitle>
                        </h4>
                    </Col>
                  <Col>
                    <HubButton icon={<DiscordIcon />} onClick={()=>window.open(process.env.REACT_APP_COMMUNITY_LINK, "_blank")} className='discord-button-compact' >Join Commnunity</HubButton>
                  </Col>
                </Row>
                </>
          }
        </Content>
      </Layout>
    </HubModal>
  );
};

export default JoinDiscordModal;
