import { Row, Col, Card } from 'antd';

import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import logoSrc from '../../../media/images/logo_orange.svg';
import { animated, useSpring } from '@react-spring/web'
/* local components */
import {SignIn, SignUp, DiscordPopupRedirect} from '../../commons/auth/front';
import ForgotPassword from '../../commons/auth/front/forgotPassword';

const svgBackground = ()=>{

  return(
    <svg className='svg-background' xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMin slice" viewBox="24 105 156 95" >
        <rect x="0" y="0" width="300" height="300" fill="#4E598C"/>
        <path className="top-outer"     fill="#FF8642" stroke="#FFFFFF" strokeWidth="1" d="M68 1c23,0 67,17 69,39 4,38 5,52 -5,69 -20,36 -68,46 -120,-9 -27,-28 -7,-101 56,-99z"/>
        <path className="top-inner"     fill="#4E598C" stroke="#FFFFFF" strokeWidth="1" d="M69 22c33,-7 52,21 54,37 3,26 -1,28 -8,40 -15,25 -48,25 -85,-14 -19,-20 -4,-53 39,-63z"/>
        <path className="bottom-outer"  fill="#FF8642" stroke="#FFFFFF" strokeWidth="1" d="M158 158c30,13 56,50 44,75 -9,18 -22,46 -42,55 -20,10 -48,2 -73,-19 -29,-25 -27,-55 -9,-79 17,-22 43,-48 80,-32z"/>
        <path className="bottom-inner"  fill="#4E598C" stroke="#FFFFFF" strokeWidth="1" d="M136 180c32,-9 45,13 46,29 3,27 8,29 0,42 -14,25 -52,24 -79,0 -21,-18 -2,-61 33,-71z"/>
    </svg>
  )
}


const Landing = () => {

  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 }, config: {duration: 500} })
  return (
    /* render a row with elements align in the middle of container */
    // @ts-ignore
    <Row justify='center'  align='middle' style={styles.row} className='gradient-background'>
        {svgBackground()}
        {/* render columns with different width space allocations depending on 
        availablespace(or screen resolution).
        For example(first Col below), the total screen width has 24 units(100%).
        when the screen is xs(x small), will take 2 units of the width of this row.
        When the screen is sm(small) will take 4 units of the width of this row.
        When the screen is md(medium) will take 6 units of the width of this row.
        When the screen is lg(large) will take 9 units of the width of this row.
        When the screen is xl(x large) will take 9 units of the width of this row.
        
        The total allocation between each size of all columns must sum up 24. Note
        that the first Col xs: 2, second Col xs: 20, third Col xs: 2. In a small screen
        this will render one 2 units column, one 20 units column and another 2
        units column(left to right), where 24 units is 100% the width of the 
        available space.
        */}
         <Col flex="auto"></Col>
        {/* @ts-ignore */}
        <Col xs={22} sm={16} md={12} lg={10} xl={6} style={styles.col}>
          <animated.div style={props}>
            {/* wrap the content of the form into the Spin component which
            will block the UI when local state loading is set to True */}
            {/* render application logo */}
            <Card style={{borderRadius: 12, border:'1px solid #FF8642'}}>
              {/* @ts-ignore */}
              <img className='noselect' style={styles.logo} src={logoSrc} alt='logo'/>
              {/* the routes */}
                  <Routes>
                    <Route path="/" element={<SignIn />} />
                    <Route path="/register" element={<SignUp/>} />
                    <Route path="/confirm/:emailToValidate" element={<SignUp/>} />
                    <Route path="/discord/redirect" element={<DiscordPopupRedirect/>} />
                    <Route path="/forgot" element={<ForgotPassword />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
            </Card>
          </animated.div>
        </Col>
        <Col flex="auto"></Col>
    </Row>)
};

/* component styles */
const styles = {
  logo:{
      height: 100,
      width: '100%',
      overflow: 'visible',
      marginBottom: 35,
      marginTop: 25
  },
  row:{
      height: '100%'
  },
  col: {
      textAlign: 'center'
  }
};
export default Landing;