import React from 'react';
import {Button, Layout, Row, Col, Form, Input, notification} from 'antd';
import {loadStripe} from '@stripe/stripe-js';
import {StripeClient} from '../../../../../lib/api';
import {  User } from '../../../../../models';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { block } from '../../../../commons/misc/front/miscSlice';
import { CheckCircleOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';

import './index.css';
import { HubButton } from '../../../../commons/misc/front/hubComponents';

export default function AddBankAccount(props: any) {

    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user) as User;
    const {Header, Content} = Layout;
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {
        try {

          await block(async()=>{
            /* TODO: remove this stripe test key */
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
            const clientSecret = await StripeClient.get_bank_account_setup_intent();

            const confirmationResult = await stripe?.confirmUsBankAccountSetup(clientSecret, {
              payment_method: {
                billing_details: {
                  name: `${user.given_name} ${user.family_name}`,
                  email: user.email,
                },
                us_bank_account: {
                  account_holder_type: "individual",
                  account_number: values.accountNumber,
                  routing_number: values.routingNumber,
                  account_type: "checking"
                }
              }
            });

            if(confirmationResult?.error) {
              throw new Error();
            }

            console.log("confirmationResult", confirmationResult);
            return;
          });


          notification.open({
            message: "Successfully added a Bank Account",
            description: "Please verify your Bank Account to begin using it for payments.",
            icon: <CheckCircleOutlined style={{color: "green"}}/>,
          });

          props.close();
          await props.reloadPaymentMethods()

        } catch (error) {
          console.log("big error", error);
            // Utils.setBlockUIVisible(dispatch, false);
          notification.open({
              message: "Adding Bank Account Failed",
              description: "Couldn\'t add the bank account. Please try again later.",
              icon: <CloseCircleOutlined style={{color: "red"}}/>,
          });
        }
    }

    const onFinishFailed = () => {

    }

    const goTo = (page: number) => {
        clear();
        if(props.goTo)
            props.goTo(page);
    }

    const clear = () => {
        form?.resetFields();
    }

    const processNumber = (prop: string, num: string) => {
        num = num.replaceAll(" ", "");

        let leadingZeroCount: number = 0;

        for(let i=0; i < num.length; i++) {
            if(num[i] == "0")
                leadingZeroCount++;
            else
                break;
        }

        let str = parseInt(num, 10).toString();
        if(str != "" && str != "NaN") {

            let n: string = "";

            if(leadingZeroCount > 0) {
                if(str == "0")
                    leadingZeroCount--;
                for(let ii=0; ii < leadingZeroCount; ii++) {
                    n = "0" + n;
                }
            }

            str = n + str;

            let x: any = {};
            x[prop] = str
            form.setFieldsValue(x);
        }
        else {
            let x: any = {};
            x[prop] = "";
            form.setFieldsValue(x);
        }
    }


    return (
        <React.Fragment>
            <Layout>
                <Header style={{background: "white"}}>
                    <Row>
                        <Col span={2}>
                            <HubButton type="default" onClick={() => goTo(0)}>Back</HubButton>
                        </Col>
                    </Row>
                    
                </Header>
                <Content style={{background: "white"}}>
                    <br/>
                    <Row>
                        <Col span={24}>
                        <Form
                            form={form}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            >
                                <Form.Item
                                    name="accountNumber"
                                    rules={[{ required: true, message: 'Please input your account number' }]}
                                >
                                    {/* <InputNumber placeholder="Account Number" stringMode={true} maxLength={12} min={0} style={{width: 300}} /> */}
                                    <Input onChange={(e: any) => processNumber("accountNumber", e.target.value)} placeholder="Account Number" maxLength={12} min={0} style={{width: 300}} />
                                </Form.Item>

                                <Form.Item
                                    name="routingNumber"
                                    rules={[{ required: true, message: 'Please input your routing number' }]}
                                >
                                    <Input onChange={(e: any) => processNumber("routingNumber", e.target.value)} placeholder="Routing Number" maxLength={9} min={0} style={{width: 300}} />
                                </Form.Item>

                                <Form.Item>
                                    <HubButton icon={<SaveOutlined />} htmlType="submit" >Save</HubButton>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </React.Fragment>
    )
}