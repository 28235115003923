import { HubModal, HubTitle } from "./hubComponents";
import { Col, Layout, Row } from "antd";
import laptopAnimation from "../../../../media/animations/warning-phone.json"
import Lottie from "lottie-react";

const { Content } = Layout;

type ReloadModalProps = {
  isOpen:boolean
  onOk: () => void
}

const IncompatibleModal = ({
  isOpen,
  onOk
}: ReloadModalProps) => {


  return (
    <HubModal
      title={<HubTitle>PHONE OR TABLET DETECTED</HubTitle>}
      onOk={onOk}
      open={isOpen}
      closable={false}
      showCancel={false}
      showOk={true} 
    > 
      <Layout>
        <Content style={{ backgroundColor: "white", textAlign: "center" }}>
            <Row align="middle" justify="center">
              <Col>
                  <Lottie loop={false} animationData={laptopAnimation} style={{margin: "-20px"}} />
              </Col>
              <Col span={24}>
                  <h2>
                    The platform works better on large screens. Please use a <strong>laptop 💻</strong> or <strong>desktop 🖥️</strong>. 
                    We are working on a mobile version 📱
                  </h2>
              </Col> 
            </Row>
        </Content>
      </Layout>
    </HubModal>
  );
};

export default IncompatibleModal;
