import Lottie from "lottie-react";
import '@dotlottie/player-component';
import {
  useNavigate
} from "react-router-dom";
import restricted from '../../../../media/animations/restricted.json';
import { Col, Row, Result, Button} from 'antd';
import { HubButton } from "../../misc/front/hubComponents";

const Restricted = ({access}:any) => {

  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="vertical-center">
        <Row>
          <Col span={24} style={{textAlign:'center'}}>
            <Result
              style={{margin:'-35px'}}
              icon={<Lottie loop={true} animationData={restricted} style={{height:'300px',margin:'-25px'}}/>}
              title="Hey! What are you doing here?"
              subTitle="This area is restricted, look around for places you can go."
              extra={[
                <HubButton 
                  key={"restricted"}
                  onClick={()=>{
                    navigate(-1);
                  }}>
                    <strong>Go Back</strong>
                </HubButton>
              ]}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}


export default Restricted;