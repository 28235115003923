const DiscordPopupRedirect =()=> { 
  const fragment = new URLSearchParams(window.location.hash.slice(1));
  const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];

  if(accessToken && tokenType && accessToken != "" && tokenType != "") {
    
    fetch('https://discord.com/api/users/@me', {
      headers: {
        authorization: `${tokenType} ${accessToken}`,
      },
    })
      .then(result => result.json())
      .then(response => {
        const { username, discriminator } = response;
        localStorage.setItem("discord_username", `${username}#${discriminator}`);
        window.close();
      })
      .catch(console.error);
  }else{
    setTimeout(()=>{
      window.close();
    },100)
  }


  return (
    <div></div>
  );
}

export default DiscordPopupRedirect;
