import React, { useEffect, useState } from "react";

import { HubGradientText, HubModal } from "../../commons/misc/front/hubComponents";
import { Avatar, Layout, List, Rate } from "antd";
import VirtualList from 'rc-virtual-list';
import { Course, Language, UserProgress } from "../../../models";
import { Gradients, Helpers } from "../../../lib/utils";
import stringHash from "@sindresorhus/string-hash";
import rainhubLogo from '../../../media/images/logo.png'
import UserClient from "../../../lib/api/user_client";
import { systemDefaults } from "../../../lib/utils/enums";

const { Content } = Layout;

type CourseReviewListModalProps = {
  isOpen:boolean,
  course: Course,
  onCancel: ()=>void,
}

const CourseReviewListModal = ({
  isOpen,
  course,
  onCancel,
}: CourseReviewListModalProps) => {

  const [lang] = useState<Language>(Helpers.getUserLang());
  const [data, setData] = useState<UserProgress[]>([]);
  const [nextToken, setNextToken] = useState<string|undefined>(undefined);
  const ContainerHeight = 400;

  useEffect(() => {
    appendData();
  }, []);

  /* append data by using token */
  const appendData = async () => {
    let result = await UserClient.getParentProgressByTextValue(course.id || "",systemDefaults.emptyDynamoString, false, 20, nextToken);
    /* set values */
    setData(data.concat(result.items));
    setNextToken(nextToken || undefined);
  };

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
      appendData();
    }
  };

  return (
    <HubModal
      title={
        <HubGradientText forceBlack size={24} colors={Gradients[stringHash(course?.id as string)%Gradients.length]}>
          <strong>{course?.title[lang]} reviews</strong>
        </HubGradientText>
      }
      open={isOpen}
      onCancel={onCancel}
      showCancel={true}
      cancelText={"Close"}
      showOk={false} 
    > 
      <Layout>
        <Content style={{ backgroundColor: "white"}}>
          <List itemLayout="vertical">
            <VirtualList
              data={data}
              height={400}
              itemHeight={47}
              itemKey="userId"
              onScroll={onScroll}
            >
              {(item: UserProgress) => (
                <List.Item key={item.userId}>
                  <List.Item.Meta
                    avatar={<Avatar src={rainhubLogo} />}
                    title={<span>RainHub Student &nbsp; <Rate value={item.numValue} disabled allowHalf /></span>}
                  />
                  <div>{item.textValue}</div>
                </List.Item>
              )}
            </VirtualList>
          </List>
        </Content>
      </Layout>
    </HubModal>
  );
};

export default CourseReviewListModal;
