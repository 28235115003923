/**
 * This script exports the Sign In component.
 * 
 * @author Victor Santos Uceta
 * @license Attribution-NonCommercial-NoDerivatives 4.0 International
 */
/* external imports */
import { Link } from "react-router-dom";
import {  Auth } from 'aws-amplify';
import { Form, Input, Divider } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
/* local imports */
import { Helpers } from '../../../../lib/utils';
import { Attention } from '../../../../lib/utils/notification';
import { HubButton } from "../../misc/front/hubComponents";
import {block} from '../../misc/front/miscSlice';
import AnalyticsClient, { AnalyticsEvent } from "../../../../lib/api/analytics_client";
import { Area } from "../../../../lib/utils/enums";
import { DiscordIcon } from "./discord-identity";
import {
  QuestionCircleOutlined
} from '@ant-design/icons';

const SignIn = () => {

  let navigate = useNavigate();

  /* send notification to user */
  const onSignIn = async (values: any) => {
    try {
      /* trim username */
      values.username = values.username.trim()
      await block(async()=>{
        const user = await Auth.signIn(values.username, values.password);
        /* Load current session */
        const session = await Auth.currentSession();
        /* setup user */
        await Helpers.authenticateUser(user, session);
      });
      AnalyticsClient.record(AnalyticsEvent.loginOk,{username:values.username});

    } catch (error: any) {
      AnalyticsClient.record(AnalyticsEvent.loginFailed,{username:values.username});
      Attention.notifyError('Oops!',error.message);
      /* if user not confirmed go to confirmation screen */
      if(error.code === 'UserNotConfirmedException'){
        navigate(`/${Area.CONFIRM}/${values.username}`);
      }
    }
  };

  return (
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onSignIn}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please provide your email!' }]}
        >
          <Input 
            style={{maxWidth:250}}
            prefix={<UserOutlined className="site-form-item-icon" />} 
            placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please provide your password!' }]}
        >
          <Input
            style={{maxWidth:250}}
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Link className='login-form-forgot' to="/forgot">Forgot Password</Link>
        </Form.Item>
  
        <Form.Item>
          <HubButton htmlType="submit" className="login-form-button">
            Log in
          </HubButton>
          <Divider />
          <HubButton type="default">
            <Link to="/register">Create Account</Link>
          </HubButton>
          <br/><br/>
          <HubButton icon={<DiscordIcon />} 
              onClick={()=>window.open(process.env.REACT_APP_COMMUNITY_LINK, "_blank")} 
              className='discord-button-compact' >Join Commnunity</HubButton>
          <br/><br/>
          <a href={"https://rainhub.net?utm_source=app.rainhub.net&utm_medium=login-link&utm_campaign=perpetual"} target="_blank">About This Platform <QuestionCircleOutlined /></a>
        </Form.Item>
      </Form>
  );
    
	
}

/* wrapped sign in form so component can be used */
export default SignIn;
