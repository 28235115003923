import { Row, Col, Form, Input, Typography, FormInstance } from 'antd';
import React, { useState, MouseEventHandler} from 'react';
import {  HubModal, HubPasswordPolicy } from '../../../commons/misc/front/hubComponents';
import { LockOutlined} from '@ant-design/icons';
import { PASSWORD_REGEX } from '../../../../lib/utils/constants';

type ChangePasswordProps  = {
  open: boolean,
  form: FormInstance,
  onCancel: (a:boolean)=>void,
  onOk: (a:boolean)=>void,
}

const ChangePasswordModal = (props:ChangePasswordProps)=>{

  const {
    open,
    form,
    onCancel,
    onOk
  } = props;

  return (
      <HubModal title="Change Password"
            titleIcon={<LockOutlined />}
            open={open}
            onOk={async ()=>{
              try{
                await form.validateFields();
                form.submit();
              }catch(e){}
            }}
            onCancel={()=> onCancel(false)} 
     
            cancelText="Cancel" 
            okText="Change">
        <Row>
          <Col span={24}>
            <Form
              layout='vertical'
              size='large'
              form={form}
              onFinish={onOk}
            >
              <Form.Item>
                <Row>
                  <Col span={24}>
                    <Form.Item
                    name="oldPwd"
                    noStyle
                    hasFeedback
                    rules={[{ required: true, message: "Old password is required." }]}
                    >
                      <Input.Password placeholder='current password' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row>
                  <Col span={24}>
                    <Form.Item
                    name="pwd"
                    noStyle
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      {
                        pattern: PASSWORD_REGEX,
                        message:<HubPasswordPolicy/>,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('oldPwd') === value) {
                            return Promise.reject(new Error('New password cannot be the same as old password.'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    >
                      <Input.Password placeholder='new password' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row>
                  <Col span={24}>
                    <Form.Item
                    name="pwdConfirmed"
                    noStyle
                    dependencies={['pwd']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your new password.',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('pwd') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match.'));
                        },
                      }),
                    ]}
                    >
                      <Input.Password placeholder='confirm new Password'/>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </HubModal>);

}

export default ChangePasswordModal;