
import React from 'react';
import Lottie from "lottie-react";
import { Divider, Typography } from 'antd';
import lock from '../../../../media/animations/lock.json';
const { Paragraph } = Typography;

const SingleSessionMessage = () => {

  return (
    <React.Fragment>
      <Lottie style={{margin:'-60px'}} loop={true} animationData={lock} />
      <Paragraph>
        You have been logged out of your account because we detected a new login on
        a different device. Only one device per account is allowed. If it was not
        you, please <strong>change your password</strong> as soon as possible. 
      </Paragraph>
    </React.Fragment>
  )
}


export default SingleSessionMessage;