import { Amplify, Analytics } from 'aws-amplify';

/* configure general amplify framework */
Amplify.configure({
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: 'AWS_LAMBDA',
  Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
  },
  Storage: {
      AWSS3: {
          region: process.env.REACT_APP_AWS_REGION,
          bucket: process.env.REACT_APP_SYSTEM_BUCKET
      }
  },
  Analytics: {
    AWSPinpoint: {
      appId: process.env.REACT_APP_PINPOINT_APP_CLIENT_ID,
      region: process.env.REACT_APP_AWS_REGION
    }
  }
});

/* enable auto tracking */
Analytics.autoTrack('session', {enable: true});
/* enable page view tracking */
Analytics.autoTrack('pageView', {
  enable: true,
  eventName: 'pageView',
  type: 'SPA',
  provider: 'AWSPinpoint',
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  }
});