import React, { ReactElement, useEffect, useState } from 'react';
import { Card, Switch, Form, Input, Select, Tag, 
  Space, InputNumber, Button, Upload, Row, Col, Badge, Divider, Radio } from 'antd';
  
import MDEditor from '@uiw/react-md-editor';
import { Language, LanguageMap, Resource, ResourceInput, ResourceSourceStatus, ResourceThumbnailStatus, ResourceLevelType, ResourceType } from '../../../../../models';
import { HubButton, HubGradientText, HubPopConfirm } from '../../../../commons/misc/front/hubComponents';
import {PictureOutlined, FileImageFilled, VideoCameraOutlined, CheckCircleOutlined, PlusOutlined, DeleteOutlined, FilePdfOutlined} from '@ant-design/icons';
import videoIcon from '../../../../../media/images/video.png';
import pdfIcon from '../../../../../media/images/pdf.png';
import imageIcon from '../../../../../media/images/image.png'; 
import { CanvasUtils, Helpers } from '../../../../../lib/utils';
import { ResourceClient} from '../../../../../lib/api';
import { Attention } from '../../../../../lib/utils/notification';
import { FileClient } from '../../../../../lib/api';
import { block } from '../../../../commons/misc/front/miscSlice';
import { ResourceAccessType, ResourceColorMap, ResourceSourceType } from '../../../../../models/Resource';
import ImageEditModal from '../../../../commons/misc/front/imageEditModal';
// const { ReactTinyLink }  = require("react-tiny-link");


type ResourceDetailProps = {
  data: Resource | ResourceInput,
  onFinish: (data:Resource, existing: boolean)=>void,
  onCancel: ()=>void
}

const { Option } = Select;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
/* the color map */
const colorMap = ResourceColorMap;


const ResourceDetail = ({data, onFinish, onCancel}: ResourceDetailProps) => {

  const [lang, setLang] = useState<Language>(Language.espanol);
  const [type, setType] = useState<ResourceType>(ResourceType.webinar);
  const [sourceType, setSourceType] = useState<ResourceSourceType>(ResourceSourceType.rainhub_video);
  const [description, setDescription] = useState<any>({es:"emtpy", en:"emtpy"});
  const [title, setTitle] = useState<any>({es:"emtpy", en:"emtpy"});
  const [subtitles, setSubtitles] = useState(new Map<string, string>());
  const [thumbnailFile, setThumbnailFile] = useState<any>([]);
  const [thumbnailSrc, setThumbnailSrc] = useState<string|null>(null);
  const [thumbnailUploading, setThumbnailUploading] = useState<boolean>(false);
  const [videoFile, setVideoFile] = useState<any>([]);
  const [pdfFile, setPdfFile] = useState<any>([]);
  const [imageFile, setImageFile] = useState<any>([]);
  const [sourceFileUploading, setSourceFileUploading] = useState<boolean>(false);
  const [sourceUrl, setSourceUrl] = useState<string>("");
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data);
    setTitle(data.title);
    setType(data.resourceType as ResourceType);
    setSourceUrl(data.source as string);
    setSourceType(data.resourceSourceType as ResourceSourceType);
    setDescription(data.description);

    /* init sources */
    setVideoFile([]);
    setPdfFile([]);
    setThumbnailFile([]);
    setThumbnailSrc(null);
    
    decodeExistingThumbnailFile(data as Resource);
    decodeExistingSourceFile(data as Resource);
   }, [data]);

  const setMarkdownDescription = (desc:string) =>{
    setDescription({
      ...description,
      [lang]: desc
    })
  }

  const setTitleFromInput = (ttl:string) =>{
    setTitle({
      ...title,
      [lang]: ttl
    })
  }

  const fetchVideoSubtitles = async (data: Resource) =>{
    const root = Helpers.buildStoredSubtitleRoot(data, "resource");
    let subs = await FileClient.listDirectoryFiles(root);
    let present = new Map<string, string>();
    for(let s of subs){
      let lang = s.replace(/^.*[\\\/]/, '').split('.')[0];
      present.set(lang,s);
    }
    /* set the new map */
    setSubtitles(present);
  }
  const decodeExistingThumbnailFile = async(data: Resource)=>{
    if(data.thumbnail == ResourceThumbnailStatus.None){
      setThumbnailFile([]);
      return;
    }
    /* build key */
    let key = Helpers.buildResourceSourceURL(data, sourceType, 'thumbnail_large.jpeg');
    let blob = await FileClient.getStoredMedia(key,true);
    let url = await FileClient.getStoredMedia(key,false);
    /* proceed to decode remote thumbnail file */
    let f = new File([blob], 'thumbnail.jpeg', {type:"image/jpeg"});
    /* set existing thumbnail */
    setThumbnailFile([{
      uid: key,
      name: 'thumbnail.jpeg',
      percent: 0,
      size: f.size,
      originFileObj: f,
      thumbUrl: url,
      url: url
    }]);
  }
  const decodeExistingSourceFile = async(data: Resource)=>{

    let icon, setFile = (a:any)=>{}, type, extension, blobs:any = [];
    switch(data.resourceSourceType){
      case ResourceSourceType.image:
        icon = imageIcon;
        setFile = setImageFile;
        type = "image/png";
        extension = "png";
        break;
      case ResourceSourceType.pdf:
        icon = pdfIcon;
        setFile = setPdfFile;
        type = "application/pdf";
        extension = "pdf";
        break;
      case ResourceSourceType.rainhub_video:
        icon = videoIcon;
        setFile = setVideoFile;
        type =  "video/mp4";
        extension = "mp4";
        break;
      default:
        return;
    }

    if(data.sourceStatus == ResourceSourceStatus.None){
      setFile([]);
      return;
    }
    /* build key */
    let key = Helpers.buildResourceSourceURL(data, data.resourceSourceType);
    let url = await FileClient.getStoredMedia(key,false);
    /* proceed to decode remote thumbnail file */
    let f = new File(blobs, `source.${extension}`, {type});
    /* set existing thumbnail */
    setFile([{
      uid: key,
      name: `source.${extension}`,
      percent: 0,
      size: f.size,
      originFileObj: f,
      thumbUrl: icon,
      url: url
    }]);
    /* fetch video subtitles */
    if(data.resourceSourceType == ResourceSourceType.rainhub_video){
      fetchVideoSubtitles(data);
    }
  }
  const onFormSubmit = async (values: any) => {
    /* split and trim tags */
    values.tags = `${values.tags}`.split(',').map((v)=>v.trim());
    let source = "";
    switch(sourceType){
      case ResourceSourceType.website:
        source = sourceUrl;
        break;
      case ResourceSourceType.youtube:
        source = sourceUrl;
        break;
    }

    /* set title and description properies */
    values = {
      ...values,
      source,
      title: title,
      description: description
    }

    let operation = data.id?"updated":"created";
    let update:any = {};
    let entry = {...values, id: data.id} as ResourceInput;
    if(data.id){
      await ResourceClient.updateResource(entry);
    }else{
      entry.id = await ResourceClient.createResource(entry);
    }
    update = {...data, ...entry};
    /* show success */
    Attention.notifySuccess(`${type} ${operation}!`,
      `${type} ${values.title[lang]} was successfully ${operation}`
    );
    /* pull new data from remote in upper component*/
    onFinish(update, operation=="updated");
  };
  const onReset = () => {
    form.resetFields();
  };
  const handleThumbnailChange = async ({ fileList, skipDimensionCheck }:any) => {

    if(fileList.length > 0){
      let {originFileObj} =  fileList[0];
      let img = await CanvasUtils.blobToImage(originFileObj);
      const isLt512Kb = originFileObj.size / 1000 < 1024;
      if (!isLt512Kb) {
        Attention.notifyError("Invalid Image", 'Image must be smaller than 512KB.');
        return
      }
      if ((img.height != 680 || img.width != 1024) && !skipDimensionCheck) {
        // Attention.notifyError("Invalid Image", `Image dimensions must be 1024x680, you provided: ${img.width}x${img.height}`);
        setThumbnailSrc(await CanvasUtils.blobToBase64(originFileObj));
        return
      }
    }

    setThumbnailFile(fileList);
    if(fileList.length == 0 && data.thumbnail != ResourceThumbnailStatus.None){
      deleteThumbnail();
    }
  };
  const handleImageChange = ({ fileList }:any) => {
    setImageFile(fileList);
    if(fileList.length == 0 && data.sourceStatus != ResourceSourceStatus.None){
      deleteSource();
    }
  };
  const handleVideoChange = ({ fileList }:any) => {
    setVideoFile(fileList);
    if(fileList.length == 0 && data.sourceStatus != ResourceSourceStatus.None){
      deleteSource();
    }
  };
  const handlePDFChange = ({ fileList }:any) => {
    setPdfFile(fileList);
    if(fileList.length == 0 && data.sourceStatus != ResourceSourceStatus.None){
      deleteSource();
    }
  };
  const previewMedia = async (file: any, isThumbnail: boolean)=>{
    if(isThumbnail){
      if(data.thumbnail == ResourceThumbnailStatus.None){
        CanvasUtils.previewImage(file);        
      }else{
        window.open(thumbnailFile[0].url, '_blank');
      }
    }else{
      if(data.sourceStatus == ResourceSourceStatus.None){
        let file;
        switch(sourceType){
          case ResourceSourceType.image:
            file = imageFile;
            break;
          case ResourceSourceType.pdf:
            file = pdfFile;
            break;
          case ResourceSourceType.rainhub_video:
            file = videoFile;
            break;
          default:
            Attention.notifyWarning(`Usupported source type: ${sourceType}`)
        }
        let blob = new Blob([await Helpers.readFile(file[0].originFileObj, true) as any], { type: file[0].type })
        let url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      }else{
        window.open(file.url, '_blank');
      }
    }
  }
  const updateMediaEntry = async (changes:any ) =>{
      /* save state */
      let entry = {id: data.id, ...changes} as ResourceInput;
      await ResourceClient.updateResource(entry);
  }
  const uploadThumbnail = async()=>{
    setThumbnailUploading(true);
    try{
      let {originFileObj}:{originFileObj:File} = thumbnailFile[0];
      let large = await CanvasUtils.compressImage(originFileObj, 1024, 680, 0.25, true);
      let medium = await CanvasUtils.compressImage(originFileObj, 512, 340, 0.07, true);
      let small = await CanvasUtils.compressImage(originFileObj, 256, 170, 0.025, true);

      /* build upload string */
      let keyLarge = Helpers.buildResourceSourceURL(data, sourceType, "thumbnail_large.jpeg");
      let keyMedium = Helpers.buildResourceSourceURL(data, sourceType, "thumbnail_medium.jpeg");
      let keySmall = Helpers.buildResourceSourceURL(data, sourceType, "thumbnail_small.jpeg");

      let uploads:any = [];
      uploads.push(FileClient.uploadStoredMedia(keyLarge,large,"image/jpeg"));
      uploads.push(FileClient.uploadStoredMedia(keyMedium,medium,"image/jpeg"));
      uploads.push(FileClient.uploadStoredMedia(keySmall,small,"image/jpeg"));

      await Promise.all(uploads);
      await updateMediaEntry({'thumbnail':ResourceThumbnailStatus.Ready});
    }catch(e:any){
      Attention.notifyError("Could not upload thumbnail!", e.message);
    }
    finally{
      setThumbnailUploading(false);
    }
    /* pull new data from remote in upper component*/
    onFinish({...data, thumbnail: ResourceThumbnailStatus.Ready} as Resource, true);
    Attention.notifySuccess("Thumbnail uploaded!");
  }
  const uploadSource = async()=>{
    setSourceFileUploading(true);
    let sourceStatus;
    try{
      let file;
      switch(sourceType){
        case ResourceSourceType.image:
          file = imageFile;
          break;
        case ResourceSourceType.pdf:
          file = pdfFile;
          break;
        case ResourceSourceType.rainhub_video:
          file = videoFile;
          break;
        default:
          Attention.notifyWarning(`Usupported source type: ${sourceType}`)
          return;
      }
      let {originFileObj}:{originFileObj:File} = file[0];
      /* build upload string */
      let key = Helpers.buildResourceSourceURL(data, sourceType);
      await FileClient.uploadStoredMedia(key,originFileObj,file[0].type );
      /* update entry */
      sourceStatus = sourceType == ResourceSourceType.rainhub_video?ResourceSourceStatus.Uploaded:ResourceSourceStatus.Ready;
      await updateMediaEntry({sourceStatus, resourceSourceType: sourceType});
    }catch(e:any){
      Attention.notifyError(`Could not upload ${sourceType}!`, e.message);
    }
    finally{
      setSourceFileUploading(false);
    }
    /* pull new data from remote in upper component*/
    onFinish({...data, sourceStatus: sourceStatus} as Resource, true);
    Attention.notifySuccess(`${sourceType} uploaded!`);
  }
  const deleteThumbnail = async()=>{
    setThumbnailUploading(true);
    try{
      /* get keys to be deleted */
      let keyLarge = Helpers.buildResourceSourceURL(data, sourceType, "thumbnail_large.jpeg");
      let keyMedium = Helpers.buildResourceSourceURL(data, sourceType, "thumbnail_medium.jpeg");
      let keySmall = Helpers.buildResourceSourceURL(data, sourceType, "thumbnail_small.jpeg");

      let deletes:any = [];
      deletes.push(FileClient.deleteStoredMedia(keySmall));
      deletes.push(FileClient.deleteStoredMedia(keyMedium));
      deletes.push(FileClient.deleteStoredMedia(keyLarge));

      await Promise.all(deletes);
      await updateMediaEntry({thumbnail:ResourceThumbnailStatus.None});
    }catch(e:any){
      Attention.notifyError("Could not delete thumbnail!", e.message);
    }
    finally{
      setThumbnailUploading(false);
    }
    /* pull new data from remote in upper component*/
    onFinish({...data, thumbnail: ResourceThumbnailStatus.None} as Resource, true);
    Attention.notifySuccess("Thumbnail deleted!");
  }

  const deleteSource= async()=>{
    setSourceFileUploading(true);
    try{
      /* build upload string */
      let key = Helpers.buildResourceSourceURL(data, sourceType);
      /* get directory only */
      let dirKey = key.substring(0, key.lastIndexOf('/'));
      /* block ui while files are deleted */
      await block(async ()=>{
        if (data.resourceSourceType == ResourceSourceType.rainhub_video){
          await FileClient.deleteStoredDirectory(dirKey);
        }else{
          await FileClient.deleteStoredMedia(key)
        }
        await updateMediaEntry({sourceStatus: ResourceSourceStatus.None});
      });
    }catch(e:any){
      Attention.notifyError("Could not delete source!", e.message);
    }
    finally{
      setSourceFileUploading(false);
    }
    /* pull new data from remote in upper component*/
    onFinish({...data, sourceStatus: ResourceSourceStatus.None} as Resource, true);
    Attention.notifySuccess("Source deleted!");
  }

  const uploadSubtitle = async (options: any, lang:string, desc: string)=>{
    const { onSuccess, onError, file, onProgress } = options;
    try{
      let key = Helpers.buildStoredSubtitleURL(data, "resource", lang, false);
      let res = await FileClient.uploadStoredMedia(key,file,"text/vtt");
      await fetchVideoSubtitles(data as Resource);
      Attention.notifySuccess(`${desc} subtitle uploaded!`);
      onSuccess();
    }catch(e:any){
      onError(e.message);
      Attention.notifyError(`${desc} subtitle could not be uploaded!`, e.message);
    }
  }
  const deleteSubtitle = async (lang:string, desc: string)=>{

    try{
      let key = Helpers.buildStoredSubtitleURL(data, "resource", lang, false);
      await FileClient.deleteStoredMedia(key);
      fetchVideoSubtitles(data as Resource);
      Attention.notifySuccess(`${desc} subtitle deleted!`);
    }catch(e:any){
      Attention.notifyError(`${desc} subtitle could not be deleted!`, e.message);
    }
  }

  const renderSubtitles  = ()=>{
    const langs = Object.keys(Language);
    return Object.values(Language).map((v, i)=>{
      const desc = langs[i];
      if(subtitles.has(v)){
        return <Col key={desc}>
          <HubPopConfirm onConfirm={()=>deleteSubtitle(v, desc)} title={`Confirm subtitle detetion`} okText={'delete it'} >
            <HubButton icon={<DeleteOutlined/>} size='middle' type='primary' >{desc}</HubButton>
          </HubPopConfirm>
          
        </Col>
      }else{
        return <Col key={desc}>
            <Upload listType="text" accept="text/vtt"
                  maxCount={1}
                  fileList={[]}
                  customRequest={(options)=>uploadSubtitle(options, v, desc)}>
                    <HubButton icon={<PlusOutlined/>}  size='middle' type='default' >
                      {desc}
                    </HubButton>
            </Upload>
        </Col>
      }
    }); 
  }

  const onSourceTypeChange = (value: ResourceSourceType) => {
    setSourceType(value)
  };

  /* render available languages */
  const renderAvailableLanguages = ()=>{
    let options:any = [];
    for(let k of LanguageMap.keys()){
      options.push(
        <Radio.Button key={k} value={k}>{LanguageMap.get(k)}</Radio.Button>
      );
    }
    return options;
  }

  const renderImageInput = ()=>{
    return (<React.Fragment>
        <Upload listType="picture-card" accept="image/png"
              fileList={imageFile}
              beforeUpload={()=>{
                return false;
              }}
              onChange={handleImageChange}
              onPreview={(file:any)=>previewMedia(file, false)}>
          {imageFile.length >= 1 ? null : 
          <Button icon={<FileImageFilled />}>Image</Button>}
        </Upload>
        { imageFile.length >= 1  && data.sourceStatus == ResourceSourceStatus.None?
          <HubButton loading={sourceFileUploading} size="middle" type="default" onClick={uploadSource}>
            Upload
          </HubButton>:null
        }
      </React.Fragment>)
  }
  const renderPDFInput = () =>{
    return  (
      <React.Fragment>
          <Upload listType="picture-card" accept="application/pdf"
                  fileList={pdfFile}
                  beforeUpload={()=>{
                    return false;
                  }}
                  onChange={handlePDFChange}
                  onPreview={(file:any)=>previewMedia(file, false)} 
                  previewFile={async(): Promise<string>=>pdfIcon}>
              {pdfFile.length >= 1 ? null : 
              <Button icon={<FilePdfOutlined />}>PDF</Button>}
          </Upload>
          { pdfFile.length >= 1  && data.sourceStatus == ResourceSourceStatus.None?
            <HubButton loading={sourceFileUploading} size="middle" type="default" onClick={uploadSource}>
              Upload
            </HubButton>:null
          }
      </React.Fragment>)
  }
  const renderVideoInput = () =>{
    return  (
    <React.Fragment>
        <Upload listType="picture-card" accept="video/mp4"
                fileList={videoFile}
                beforeUpload={()=>{
                  return false;
                }}
                onChange={handleVideoChange}
                onPreview={(file:any)=>previewMedia(file, false)} 
                previewFile={async(): Promise<string>=>videoIcon}>
            {videoFile.length >= 1 ? null : 
            <Button icon={<VideoCameraOutlined />}>Video</Button>}
        </Upload>
        { videoFile.length >= 1  && data.sourceStatus == ResourceSourceStatus.None?
          <HubButton loading={sourceFileUploading} size="middle" type="default" onClick={uploadSource}>
            Upload
          </HubButton>:null
        }
        { data.sourceStatus == ResourceSourceStatus.Ready?
          <Tag color="success" icon={<CheckCircleOutlined />}>READY</Tag>:null
        }
    </React.Fragment>)
  }
  const renderYoutubeInput = () =>{
    let match = sourceUrl.match(/^(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?$/);
    return <React.Fragment>
      <Input placeholder='Youtube Link' value={sourceUrl} onChange={({target})=>{
        setSourceUrl(target.value);
      }}/>
      {!match &&
        <div style={{color:'red'}}>invalid youtube link format</div>
      }
      {match &&
        <HubButton  onClick={()=>window?.open(sourceUrl, '_blank')?.focus()}>open</HubButton>
      }
    </React.Fragment>
  }
  const renderWebInput = () =>{
    let exp = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/gi
    let match = sourceUrl.match(exp);
    return <React.Fragment>
      <Input placeholder='Web Link' value={sourceUrl} onChange={({target})=>{
        setSourceUrl(target.value);
      }}/>
      {!match &&
        <div style={{color:'red'}}>invalid web link format</div>
      }
      {match &&
        <HubButton  onClick={()=>window?.open(sourceUrl, '_blank')?.focus()}>open</HubButton>
      }
    </React.Fragment>
  }
  const renderSourceInput = ():ReactElement =>{

    switch(sourceType){
      case ResourceSourceType.image:
        return renderImageInput();
      case ResourceSourceType.pdf:
        return renderPDFInput();
      case ResourceSourceType.rainhub_video:
        return renderVideoInput();
      case ResourceSourceType.website:
        return renderWebInput();
      case ResourceSourceType.youtube:
        return renderYoutubeInput();
      case ResourceSourceType.text:
          return <Button>Description Only</Button>
      default:
          return <>unknown</>
    }

  }
  
  return (
    <Badge.Ribbon placement='start' style={{display: data.id?'none':''}} color={colorMap[type]} text={`NEW RESOURCE`}>
      <Card  size="small" title={
              <>
                <Tag style={{visibility: !data.id?'hidden':'visible'}} color={colorMap[type]}>{`${data.resourceType}`.toUpperCase()}</Tag>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <HubGradientText  forceBlack size={24} colors={Helpers.colorsFromString(title[lang], 4)}><strong>{title[lang].toUpperCase()}</strong></HubGradientText>
              </>
            }>
              <Form {...layout} form={form} initialValues={data} name="control-hooks" 
                      onFinish={onFormSubmit}>
                <Form.Item label="Language">
                  <Radio.Group defaultValue={lang} buttonStyle="solid" onChange={(e)=>setLang(e.target.value)}>
                    {renderAvailableLanguages()}
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Title" rules={[{ required: true }]}>
                  <Input  value={title[lang]} onChange={(evnt)=>setTitleFromInput(evnt.target.value)}/>
                </Form.Item>
                <Form.Item label="Description" valuePropName="value" rules={[{ required: true }]} data-color-mode="light">
                      <MDEditor
                        previewOptions={{
                          rehypeRewrite: (node) => {
                            if (node.type === "element" && node.tagName === "a") {
                              node.properties = { ...node.properties, target: "_blank" };
                        }}}}
                        value={description[lang]}
                        onChange={(desc:string|undefined)=>setMarkdownDescription(desc as string)}
                      />
                </Form.Item>
                <Form.Item label="Published" valuePropName="checked" name="published">
                  <Switch />
                </Form.Item>
                <Form.Item label="Order" name="ordering" rules={[{ required: true }]}>
                    <InputNumber min={0} max={10000}/>
                </Form.Item>
                <Form.Item name="access" label="Access" rules={[{ required: true }]}>
                  <Select
                    placeholder="Select who can access."
                    allowClear>
                    {Object.values(ResourceAccessType).map((v)=><Option key={v} value={v}>{v}</Option>)}
                  </Select>
                </Form.Item>
                <Form.Item name="tags" label="Tags" hasFeedback rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z]+(,\s*[a-zA-Z]+)*$/),
                    message: 'Tags must be comma separated words only.'
                  },{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="level" label="Level" rules={[{ required: true }]}>
                  <Select
                    placeholder="Select resource target audience."
                    allowClear
                  >
                    {Object.values(ResourceLevelType).map((v)=><Option key={v} value={v}>{v}</Option>)}
                  </Select>
                </Form.Item>
                <Form.Item name="resourceType" label="Type" rules={[{ required: true }]}>
                  <Select
                    placeholder="Select resource type."
                    allowClear>
                    {Object.values(ResourceType).map((v)=><Option key={v} value={v}>{v}</Option>)}
                  </Select>
                </Form.Item>
                <Form.Item name="resourceSourceType" label="Source Type" rules={[{ required: true }]}>
                  <Select
                    placeholder="Select source type."
                    onChange={onSourceTypeChange}
                    allowClear>
                    {Object.values(ResourceSourceType).map((v)=><Option key={v} value={v}>{v}</Option>)}
                  </Select>
                </Form.Item>

                {/* *********************** UPLOAD CONTROL FOR THUMBNAIL *********************/}
                {data.id?
                  <Form.Item label="Media">
                    <Row justify='center'>
                      <Col span={8}>
                          <Upload listType="picture-card" accept="image/png,image/jpeg,image/jpg"
                                fileList={thumbnailFile}
                                beforeUpload={()=>{
                                  return false;
                                }}
                                onChange={handleThumbnailChange}
                                onPreview={(file:any)=>previewMedia(file, true)}>
                            {thumbnailFile.length >= 1 ? null : 
                            <Button icon={<PictureOutlined />}>Thumbnail</Button>}
                          </Upload>
                          { thumbnailFile.length >= 1 && data.thumbnail == ResourceThumbnailStatus.None?
                            <HubButton loading={thumbnailUploading} size="middle" type="default" onClick={uploadThumbnail}>
                              Upload
                            </HubButton>:null
                          }
                      </Col>
                      <Col span={16}>
                          {renderSourceInput()}
                      </Col>
                    </Row>
                  </Form.Item>: null
                }
                {/* *********************** UPLOAD CONTROL FOR THUMBNAIL *********************/}
                {data.sourceStatus == ResourceSourceStatus.Ready && data.resourceSourceType == ResourceSourceType.rainhub_video?
                  <Form.Item label="Subtitles">
                   <Row gutter={[6,0]} justify='center'>
                      {renderSubtitles()}
                    </Row>
                  </Form.Item>: null
                }
                <Divider/>
                <Form.Item {...tailLayout}>
                  <Space>
                    <HubButton size='large' type="primary" htmlType="submit">
                      Save
                    </HubButton>
                    {data.id?
                      <HubButton size='large' type="default" onClick={onReset}>
                        Reset
                      </HubButton>:
                      <HubButton size='large' type="default" onClick={onCancel}>
                        Cancel
                      </HubButton>
                    }
                  </Space>
                </Form.Item>
              </Form>
        </Card>
        {/* the crop modal */}
        <ImageEditModal 
          imageSrc={thumbnailSrc}
          aspect={1.51}
          onComplete={async (croppedImg:string)=>{        
            if(croppedImg){
              let blob = await CanvasUtils.base64ToBlob(croppedImg);
              let file = {originFileObj:blob};
              console.log(data);
              handleThumbnailChange({fileList:[file], skipDimensionCheck: true});
              setThumbnailFile([file]);
            }
            /* remove original */
            setThumbnailSrc(null);
          }}/>
      </Badge.Ribbon>)
};

export default ResourceDetail;