export type Address ={    
  addressLine: string,
  addressLine2?: string,
  city: string,
  state: string,
  zip: string,
  country: string
}

export type Metadata ={
  [key: string]: string
}

export type StripeCustomer ={
  id?: string,
  address?: Address,
  description?: string,
  email: string,
  name: string,
  phone?: string,
  shippingAddress?: Address,
  metadata?: Metadata
}

export type StripeTermsOfService ={
  date: Date | null,
  ip: string,
  userAgent: string
}

export type StripeSubscription = {
  id?: string,
  currentPeriodStart: Date,
  currentPeriodEnd: Date,
  customerId: string,
  priceId?: string,
  collectionMethod?: string,
  status: string,
  isActive: boolean,
  isTrial: boolean,
  invoiceStatus: string,
  paymentStatus: string,
  startDate: Date,
  daysUntilDue: number,
  cancelled: boolean,
  cancel?: boolean,
  metadata?: Metadata,
  trialEnd?: Date
}

export type BankAccount ={
  accountNumber: string,
  routingNumber: string,
  accountHolderName?: string,
  accountHolderType?: "individual" | "company" | undefined,
  currency: string,
  country: string,
  metadata?: Metadata,
  defaultForCurrency?: boolean
}

export type Card ={
  number: string,
  expMonth: string,
  expYear: string,
  zip: string,
  cvc: string,
  country: string,
  metadata?: Metadata
  
  /*
   Applicable only on accounts (not customers or recipients).
   If you set this to true (or if this is the first external account being added in this currency),
   this card will become the default external account for its currency.
   */
  defaultForCurreny?: boolean
}

export type StripePaymentMethod ={
  id: string,
  number: string,
  bank: string,
  type: "card" | "bank_account",
  country: string,
  isDefault: boolean,
  isVerified: boolean,
  isExpired: boolean,
  metadata?: Metadata|null
}

export type StripePaymentMethodList ={
  paymentMethods: Array<StripePaymentMethod>,
  startingAfter?: string,
  endingBefore?: string
}

export type PendingBankAccountIntent ={
  id: string,
  paymentMethodId: string,
  clientSecret: string,
  status: string,
  verificationUrl: string,
  bank: string,
  number: string
}


export enum UserTypeRH {
  Power = "Power",
  Legacy = "Legacy",
  Regular = "Regular"
}