import  { useEffect, CSSProperties } from 'react';
import {Card, Col, Divider, Form, Layout, Row, Slider, Switch,Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Gateway } from '../../../commons/gateway/front/Gateway';
import { Area } from '../../../../lib/utils/enums';
import { ConfigKeys, UserConfiguration } from '../../../../models';
import { HubButton, HubPopConfirm } from '../../../commons/misc/front/hubComponents';
import { MailOutlined, SaveOutlined, SoundOutlined, QuestionCircleTwoTone} from '@ant-design/icons';
import { ConfigClient } from '../../../../lib/api';
import { block } from '../../../commons/misc/front/miscSlice';
import { Attention } from '../../../../lib/utils/notification';
import { setUserConfig } from '../../../commons/auth/front/authSlice';
import { isMobile } from 'react-device-detect';

/* false or undefined function */
const fOu = (value:any, dflt:any)=>{
  return (value == null || value == undefined)? dflt:value;
}
/* provide hints to user */
const hints = {
  [ConfigKeys.emailSubscriptionCreated]: <div>Email will be sent when you enroll in a subscription.</div>,
  [ConfigKeys.emailSubscriptionCancelled]: <div>Email will be sent when a cancelled subscription period ends.</div>,
  [ConfigKeys.emailSubscriptionUpdated]: <div>Email will be sent when you set your subscription to cancel or it is automatically renewed(monthly or yearly).</div>,
  [ConfigKeys.emailPaymentFailed]: <div>Email will be sent when an automatic payment fails, for example a subscription payment due to insufficient funds.</div>,
  [ConfigKeys.emailPaymentSucceed]: <div>Email will be sent when an automatic payment is successfull.</div>,
  systemVolume: <div>The platform system-wide volume. Adjust this to lower or increse all sounds.</div>,
  [ConfigKeys.soundSuccess]: <div>The platform will emit a sound when a <strong>successfull</strong> notification arrives.</div>,
  [ConfigKeys.soundInfo]: <div>The platform will emit a sound when a <strong>information</strong> notification arrives.</div>,
  [ConfigKeys.soundWarning]: <div>The platform will emit a sound when a <strong>warning</strong> notification arrives.</div>,
  [ConfigKeys.soundError]: <div>The platform will emit a sound when an <strong>error</strong> notification arrives.</div>,
}

const Settings = ()=> {

  let config = useAppSelector((state) => state.auth.config) as UserConfiguration;
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  /* if config changes, set the new values */
  useEffect(() => {
    form.setFieldsValue({
      [ConfigKeys.emailSubscriptionCreated]: fOu(config.emailConfigs[ConfigKeys.emailSubscriptionCreated], false),
      [ConfigKeys.emailSubscriptionCancelled]: fOu(config.emailConfigs[ConfigKeys.emailSubscriptionCancelled], false),
      [ConfigKeys.emailSubscriptionUpdated]: fOu(config.emailConfigs[ConfigKeys.emailSubscriptionUpdated], false),
      [ConfigKeys.emailPaymentFailed]: fOu(config.emailConfigs[ConfigKeys.emailPaymentFailed], false),
      [ConfigKeys.emailPaymentSucceed]: fOu(config.emailConfigs[ConfigKeys.emailPaymentSucceed], false),
      systemVolume: fOu(config.systemVolume, 0),
      [ConfigKeys.soundSuccess]: fOu(config.soundConfigs[ConfigKeys.soundSuccess], false),
      [ConfigKeys.soundInfo]: fOu(config.soundConfigs[ConfigKeys.soundInfo], false),
      [ConfigKeys.soundWarning]: fOu(config.soundConfigs[ConfigKeys.soundWarning], false),
      [ConfigKeys.soundError]: fOu(config.soundConfigs[ConfigKeys.soundError], false)
    })
  }, [config]);


  /* on form finish */
  const onFinish = async (values: any) => {
    /* config entry */
    const entry: any = {
      systemVolume: values.systemVolume,
      emailConfigs: {
        [ConfigKeys.emailSubscriptionCreated]: values[ConfigKeys.emailSubscriptionCreated],
        [ConfigKeys.emailSubscriptionCancelled]: values[ConfigKeys.emailSubscriptionCancelled],
        [ConfigKeys.emailSubscriptionUpdated]: values[ConfigKeys.emailSubscriptionUpdated],
        [ConfigKeys.emailPaymentFailed]: values[ConfigKeys.emailPaymentFailed],
        [ConfigKeys.emailPaymentSucceed]: values[ConfigKeys.emailPaymentSucceed]
      },
      soundConfigs: {
        [ConfigKeys.soundSuccess]: values[ConfigKeys.soundSuccess],
        [ConfigKeys.soundInfo]: values[ConfigKeys.soundInfo],
        [ConfigKeys.soundWarning]: values[ConfigKeys.soundWarning],
        [ConfigKeys.soundError]: values[ConfigKeys.soundError]
      }
    }
    try{
      await block(async ()=>{
        /* push configuration */
        await ConfigClient.updateUserConfiguration(entry);
        let cfg = await ConfigClient.getUserConfiguration();
        dispatch(setUserConfig(cfg));
      });
      Attention.notifySuccess('Configuration Saved!')
    }catch(e:any){
      Attention.notifyError('Error Saving Configuration',e.message);
    }

  }

  const cardStyle: CSSProperties = {
    borderRadius: 6,
    border: 'solid 1px lightgray'
  }

  return <Layout>
      <Form layout="horizontal" labelCol={{span: 18}} size="middle"
            form={form} onFinish={onFinish}>
            <Row gutter={[6,0]} justify='center'>
              <Col span={isMobile?24:8}>
                  <Divider><HubButton icon={<MailOutlined />} type='default'>Emails</HubButton></Divider>
                  <Card style={cardStyle}> 
                    <Form.Item name={ConfigKeys.emailSubscriptionCreated} 
                              valuePropName="checked"
                              label={<div>
                                Subscription Created&nbsp; 
                                <Tooltip title={hints[ConfigKeys.emailSubscriptionCreated]}>
                                  <QuestionCircleTwoTone/>
                                </Tooltip>
                              </div>}>
                      <Switch checkedChildren="on" unCheckedChildren="off" />
                    </Form.Item>
                    <Form.Item name={ConfigKeys.emailSubscriptionCancelled} 
                              valuePropName="checked"
                              label={<div>
                                Subscription Cancelled&nbsp; 
                                <Tooltip title={hints[ConfigKeys.emailSubscriptionCancelled]}>
                                  <QuestionCircleTwoTone/>
                                </Tooltip></div>}>
                      <Switch checkedChildren="on" unCheckedChildren="off" />
                    </Form.Item>
                    <Form.Item name={ConfigKeys.emailSubscriptionUpdated} 
                              valuePropName="checked"
                              label={<div>
                                Subscription Updated&nbsp; 
                                <Tooltip title={hints[ConfigKeys.emailSubscriptionUpdated]}>
                                  <QuestionCircleTwoTone/>
                                </Tooltip></div>}>
                      <Switch checkedChildren="on" unCheckedChildren="off" />
                    </Form.Item>
                    <Form.Item name={ConfigKeys.emailPaymentFailed} 
                              valuePropName="checked"
                              label={<div>
                                Invoice Failed&nbsp; 
                                <Tooltip title={hints[ConfigKeys.emailPaymentFailed]}>
                                  <QuestionCircleTwoTone/>
                                </Tooltip></div>}>
                      <Switch checkedChildren="on" unCheckedChildren="off" />
                    </Form.Item>
                    <Form.Item name={ConfigKeys.emailPaymentSucceed} 
                              valuePropName="checked"
                              label={<div>
                                Invoice Paid&nbsp; 
                                <Tooltip title={hints[ConfigKeys.emailPaymentSucceed]}>
                                  <QuestionCircleTwoTone/>
                                </Tooltip></div>}>
                      <Switch checkedChildren="on" unCheckedChildren="off" />
                    </Form.Item>
                  </Card>
              </Col>
              <Col span={isMobile?24:8}>
                  <Divider><HubButton icon={<SoundOutlined />} type='default'>Sounds</HubButton></Divider>
                  <Card style={cardStyle}> 
                    <Form.Item labelCol={{span:6}} name="systemVolume" 
                                label={<div>
                                  Volume&nbsp; 
                                <Tooltip title={hints["systemVolume"]}>
                                  <QuestionCircleTwoTone/>
                                </Tooltip></div>}>
                      <Slider max={1} min={0} step={0.05} dots={true} />
                    </Form.Item>
                    <Form.Item name={ConfigKeys.soundSuccess} 
                              valuePropName="checked"
                              label={<div>
                                Success Notification&nbsp; 
                                <Tooltip title={hints[ConfigKeys.soundSuccess]}>
                                  <QuestionCircleTwoTone/>
                                </Tooltip></div>}>
                      <Switch checkedChildren="on" unCheckedChildren="off" />
                    </Form.Item>
                    <Form.Item name={ConfigKeys.soundInfo} 
                              valuePropName="checked"
                              label={<div>
                                Info Notification&nbsp; 
                                <Tooltip title={hints[ConfigKeys.soundInfo]}>
                                  <QuestionCircleTwoTone/>
                                </Tooltip></div>}>
                      <Switch checkedChildren="on" unCheckedChildren="off" />
                    </Form.Item>
                    <Form.Item name={ConfigKeys.soundWarning} 
                              valuePropName="checked"
                              label={<div>
                                Warning Notification&nbsp; 
                                <Tooltip title={hints[ConfigKeys.soundWarning]}>
                                  <QuestionCircleTwoTone/>
                                </Tooltip></div>}>
                      <Switch checkedChildren="on" unCheckedChildren="off" />
                    </Form.Item>
                    <Form.Item name={ConfigKeys.soundError} 
                              valuePropName="checked"
                              label={<div>
                                Error Notification&nbsp; 
                                <Tooltip title={hints[ConfigKeys.soundError]}>
                                  <QuestionCircleTwoTone/>
                                </Tooltip></div>}>
                      <Switch checkedChildren="on" unCheckedChildren="off" />
                    </Form.Item>
                  </Card>
              </Col>
            </Row>
            <br></br>
            <Row justify='center'>
              <Col>
                <HubPopConfirm
                  title={<div>Please confirm you want to update<br/>your system settings</div>}
                  onConfirm={async () => {
                    await form.validateFields();
                    form.submit()
                  }}
                  position={"top"}
                  okText="Yes"
                  cancelText="No"
                >
                  <HubButton icon={<SaveOutlined />} >Save</HubButton>
                </HubPopConfirm>
              </Col>
            </Row>
      </Form>
    </Layout>
  
}

export default Gateway(Settings, Area.ACCOUNT_SETTINGS);