import React, { useState } from 'react';
import {Layout, Row, Col, Form, 
  Input, Select, Divider, InputNumber, Tooltip} from 'antd';
import { Auth } from 'aws-amplify';
import ProfilePic from './profilePic';
import ChangePasswordModal from './changePasswordModal';
import VerifyModal from './verificationModal';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { block } from '../../../commons/misc/front/miscSlice';
import { Gateway } from '../../../commons/gateway/front/Gateway';
import { WarningTwoTone , SaveOutlined, LockOutlined} from '@ant-design/icons';
import { HubButton, HubPopConfirm } from '../../../commons/misc/front/hubComponents';
import { User } from '../../../../models';
import { Attention } from '../../../../lib/utils/notification';
import { setUser } from '../../../commons/auth/front/authSlice';
import { Area } from '../../../../lib/utils/enums';
import { StripeClient } from '../../../../lib/api';
import AnalyticsClient, { AnalyticsEvent } from '../../../../lib/api/analytics_client';
import { Helpers } from '../../../../lib/utils';
import { isMobile } from 'react-device-detect';

const { Content } = Layout;
const { Option } = Select;

const Profile = ()=> {

  const dispatch = useAppDispatch();
  let user: User = useAppSelector((state) => state.auth.user) as User;
  let [emailVerified, setEmailVerified] = useState(user.email_verified);
  let [verifyEmailModalVisible, setVerifyEmailModalVisible] = useState(false);
  let [phoneVerified, setPhoneVerified] = useState(user.phone_number_verified);
  let [verifPhoneModalVisible, setVerifPhoneModalVisible] = useState(false);
  let [emailChanged, setEmailChanged] = useState(false);
  let [changePwdModalVisible, setChangePwdModalVisible] = useState(false);

  /* The initial form state */
  let [state, setState] = useState({
    name: user.given_name,
    lastname: user.family_name,
    email: user.email,
    gender: user.gender,
    language: user['custom:language'],
    areacode: "US",
    phone: user.phone_number?.slice(2)
  });

  const [form] = Form.useForm();
  const [confirmForm] = Form.useForm();
  const [pwdForm] = Form.useForm();

  const onEmailConfirmFinish = async (values: any) => {
    try {
      
      await block(async()=>{
        let currentUser = await Auth.currentAuthenticatedUser();
        await Auth.verifyUserAttributeSubmit(currentUser, "email", values.code);
        await Auth.updateUserAttributes(currentUser, {
          "preferred_username": currentUser.attributes.email
        });
        await StripeClient.update_customer_email(currentUser.attributes.email);
        setVerifyEmailModalVisible(false);
        setEmailVerified(true);
        dispatch(setUser({...user, email_verified: true}));
      });
      /* clear fields and hide modal */
      confirmForm.resetFields();
      /* send notification */
      Attention.notifySuccess('Email confirmed!','Your email is now confirmed.');
      /* send analytics */
      AnalyticsClient.record(AnalyticsEvent.confirmEmailOk, {
        name: user.given_name,
        email: user.email,
        discord: user['custom:discord_user'],
      });

    } catch (error: any) {
      Attention.notifyError('Unable to confirm email!',error.message);
      /* send analytics */
      AnalyticsClient.record(AnalyticsEvent.confirmEmailFailed, {
        name: user.given_name,
        email: user.email,
        discord: user['custom:discord_user'],
      });
    }
  };
  const onPhoneConfirmFinish = async (values: any) => {
    try {
      await block(async()=>{
        let currentUser = await Auth.currentAuthenticatedUser();
        await Auth.verifyUserAttributeSubmit(currentUser, "phone_number", values.code);
        /* clear fields and hide modal */
        setVerifPhoneModalVisible(false);
        setPhoneVerified(true);
        dispatch(setUser({...user, phone_number_verified:true}));
      });
      confirmForm.resetFields();
      /* send notification */
      Attention.notifySuccess('Phone confirmed!',`Your phone number is now confirmed.`);
      /* send analytics */
      AnalyticsClient.record(AnalyticsEvent.confirmPhoneOk, {
        name: user.given_name,
        email: user.email,
        discord: user['custom:discord_user'],
      });
    } catch (error: any) {
      /* send notification */
      Attention.notifyError('Unable to confirm phone!',error.message);
      /* send analytics */
      AnalyticsClient.record(AnalyticsEvent.confirmPhoneFailed, {
        name: user.given_name,
        email: user.email,
        discord: user['custom:discord_user'],
      });
    }
  };
  const onResendConfirmation = async (type:"phone_number"|"email", nValue?:string) =>{
    /* send notification */
    let [name, value] = type=="email"?["Email", state.email]:["Phone", state.phone];
    /* if there is a new value */
    if(nValue){
      value = nValue;
    }
    try {
      await block(async()=>{
        await Auth.verifyCurrentUserAttribute(type);
      });
      /* send notification */
      Attention.notifySuccess(
        `${name} confirmation sent!`,
        `A confirmation was sent to ${value}, please provide the confirmation code to complete your profile.`
      );

      /* send analytics */
      AnalyticsClient.record(AnalyticsEvent.resendConfirmationOk, {
        type, value,
        confirProp: name,
        name: user.given_name,
        email: user.email,
        discord: user['custom:discord_user'],
      });
    } catch (error: any) {
      Attention.notifyError('Error resending confirmation.',error.message)
      /* send analytics */
      AnalyticsClient.record(AnalyticsEvent.resendConfirmationFailed, {
        type, value,
        confirProp: name,
        name: user.given_name,
        email: user.email,
        discord: user['custom:discord_user'],
      });
    }
  }
  /* we currently support phone */
  const buildPhoneNumber = (phone:string)=>{
    return `${'+1'}${phone}`;
  }
  const onFinish = async (values: any) => {
      try {
        await block(async()=>{

          let user = await Auth.currentAuthenticatedUser();

          let userData = {
            'email': values.email,
            'given_name': values.name,
            'family_name': values.lastname,
            'gender': values.gender,
            'custom:language': values.language
          };

          let phone = buildPhoneNumber(values.phone);
          let oldPhone = user.attributes.phone_number;

          if(values.phone) {
            /* build phone */
            userData = Object.assign({}, userData, {'phone_number': phone});
          }

          // update user in cognito
          await Auth.updateUserAttributes(user, userData);
          /* send analytics */
          AnalyticsClient.record(AnalyticsEvent.saveProfile);

          setState({
            name: values.name,
            lastname: values.lastname,
            email: values.email,
            gender: values.gender,
            areacode: values.areacode,
            phone: values.phone,
            language: values.language
          });
          let currentUser = await Auth.currentAuthenticatedUser();
          if(!currentUser.attributes.email_verified){
            /* send notification */
            Attention.notifySuccess(
              `${values.name} confirmation sent!`,
              `A confirmation was sent to ${values.email}, please provide the confirmation code to complete your profile.`
            );
          }
          // Save customer data except email in stripe
          await StripeClient.update_customer(values.name, values.lastname);

          /* evaluate if a new notification needs to be sent */
          const sendPhoneVerification = values.phone && !currentUser.attributes.phone_number_verified &&
                                        phone !== oldPhone;

          if(sendPhoneVerification){
            /* confirm with new value */
            await onResendConfirmation("phone_number", phone);
          }
          setEmailVerified(currentUser.attributes.email_verified);
          setPhoneVerified(currentUser.attributes.phone_number_verified);
          setEmailChanged(false);
          /* refresh current user token */
          await Helpers.refreshUser();
        });
        /* send notification */
        Attention.notifySuccess('Profile updated!','Profile information successfully updated.')
      } catch (error: any) {
        Attention.notifyError('Error updating profile.',error.message)
      }
  }

  const renderSaveButton = () => {
    if(emailChanged) {
      return (
        <HubPopConfirm
          title={<div>We noticed you changed your <strong>email</strong>.
                      <br/>You will need to confirm it. Press "Yes" to continue with the update.
                  </div>}
          onConfirm={async () => {
            await pwdForm.validateFields();
            form.submit()
          }}
          position={"top"}
          okText="Yes"
          cancelText="No"
        >
          <HubButton icon={<SaveOutlined />} >Save</HubButton>
        </HubPopConfirm>
      );
    }
    else {
      return (
        <HubButton             
          onClick={async ()=> {
              await pwdForm.validateFields();
              form.submit();
          }}
          icon={<SaveOutlined />} >Save</HubButton>
      );
    }
  }
  const onPasswordChange = async (values: any) => {
    try {
      await block(async()=>{
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, values.oldPwd, values.pwd);
      });
      pwdForm.resetFields();
      /* send notification */
      Attention.notifySuccess(
        'Password changed!',
        `Your password was successfully changed.`
      )
      /* hidding the modal */
      setChangePwdModalVisible(false);
    } catch (error: any) {
      /* send notification */
      Attention.notifyError(
        'Error changing password.',
        error.message
      )
    }
  }
  /* The area codes for phone number */
  const areaCodes = (
    <Form.Item name="areacode" noStyle>
      <Select style={{ width: 120 }} >
        <Option value="US">+1 🇵🇷🇺🇸</Option>
      </Select>
    </Form.Item>
  );
  /* the phone message */
  const phoneMsg = "Phone number is required for text message alerts and two factor authentication."
  return (
    <React.Fragment>
      <Layout>
        <Content>
          <Row>
            <Col offset={4} span={14}>              
                <ProfilePic size={120}/>
            </Col>
          </Row>
          <Row>
            <Col offset={isMobile?0:4} span={isMobile?24:14}>
                <Form layout="vertical" name="basic" size="large"
                  form={form} initialValues={state} onFinish={onFinish}>
                  <Form.Item>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item
                          name="name"
                          noStyle
                          rules={[{ required: true, message: "required" }]}>
                          <Input addonBefore="Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item  name="lastname" noStyle
                                    rules={[{ required: true, message: "required" }]}>
                            <Input placeholder='Last Name' />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                          name="email"
                          noStyle
                          rules={[{ type: 'email', message: 'The input is not valid E-mail!',}, { required: true, message: 'Please input your E-mail!' }]}>
                              <Input  addonBefore='E-mail' 
                                      onChange={(e: any) => setEmailChanged(e.target.value != user.email)}
                                      addonAfter={
                                        emailVerified?
                                        undefined:
                                        <HubButton  onClick={()=>{setVerifyEmailModalVisible(true)}}
                                                    icon={<WarningTwoTone twoToneColor={["black","yellow"]}/>} 
                                                    size={'small'}>
                                                    verify
                                        </HubButton>
                                      }/>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      rules={[{ required: false }, { pattern:  /[0-9]{10}/, message:"Please provide a valid phone number."}]}>
                        <InputNumber placeholder='Phone Number' maxLength={10} controls={false} 
                                      addonBefore={areaCodes} style={{ width: '100%' }}
                                      addonAfter={
                                        phoneVerified || !state.phone?
                                        <Tooltip title={phoneMsg} color={"blue"}  placement={"right"}>
                                          <WarningTwoTone />
                                        </Tooltip>:
                                        <HubButton  onClick={()=>{setVerifPhoneModalVisible(true)}}
                                                    icon={<WarningTwoTone twoToneColor={["black","yellow"]}/>} 
                                                    size={'small'}>
                                                    verify
                                        </HubButton>
                                      } />
                    </Form.Item>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item
                          name="gender"
                          rules={[{ required: true, message: 'Please select gender!' }]}>
                          <Select placeholder="Gender">
                            <Option value="male">Male</Option>
                            <Option value="female">Female</Option>
                            <Option value="other">Other</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                          <Form.Item
                            name="language"
                            rules={[{ required: true, message: 'Please select yourlanguage!' }]}>
                            <Select placeholder="Language">
                              <Option value="en">English</Option>
                              <Option value="es">Español</Option>
                            </Select>
                          </Form.Item>
                      </Col>
                    </Row>
                </Form>
            </Col>
          </Row>
          <Row>
            <Col offset={4} span={14} style={{textAlign:"center"}}>
                {renderSaveButton()}
              </Col>
          </Row>
          <Row>
            <Col offset={4} span={14} style={{textAlign:"center"}}>
                <Divider/>
                <HubButton  type={"link"}
                            icon={<LockOutlined />}
                            onClick={()=>{
                              setChangePwdModalVisible(true);
                            }}>
                    Change Password
                </HubButton>
                <ChangePasswordModal  form={pwdForm} onOk={onPasswordChange} 
                                      open={changePwdModalVisible} onCancel={setChangePwdModalVisible}/>
            </Col>
          </Row>
        </Content>
      </Layout>
      {/* the verify email and phone modal */}
      <VerifyModal open={verifyEmailModalVisible}
                        onOk={onEmailConfirmFinish}
                        onCancel={setVerifyEmailModalVisible}
                        form={confirmForm} 
                        verificationType="email"
                        value={state.email} 
                        onResend={onResendConfirmation}/>
      <VerifyModal open={verifPhoneModalVisible}
                        onOk={onPhoneConfirmFinish}
                        onCancel={setVerifPhoneModalVisible}
                        form={confirmForm} 
                        verificationType="phone_number"
                        value={buildPhoneNumber(state.phone)} 
                        onResend={onResendConfirmation}/>
    </React.Fragment>);
}
export default Gateway(Profile, Area.ACCOUNT_PROFILE);