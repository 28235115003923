
import { UserProgress} from './User';


export enum ResourceAccessType {
  open = "open",
  exclusive = "exclusive"
}

export enum ResourceType {
  webinar = "webinar",
  documentary = "documentary",
  roadmap = "roadmap",
  explainer = "explainer",
  tutorial = "tutorial",
  infographic = "infographic",
  training = "training",
  annoucement = "annoucement",
  interview = "interview",
  analysis = "analysis",
  podcast = "podcast",
  article = "article",
  book = "book",
  tool = "tool"
}
export enum ResourceSourceType {
  rainhub_video = "rainhub_video",
  // rainhub_audio = "rainhub_audio",
  // rainhub_platform = "rainhub_platform",
  // rainhub_roadmap = "rainhub_roadmap",
  // spotify = "spotify",
  // apple_podcast = "apple_podcast",
  // soundcloud = "soundcloud",
  youtube = "youtube",
  website = "website",
  image = "image",
  pdf = "pdf",
  text = "text"
}
export enum ResourceSourceStatus {
  None = "none",
  Ready = "ready",
  Processing = "processing",
  Uploaded = "uploaded"
}
export enum ResourceThumbnailStatus {
  None = "none",
  Ready = "ready"
}
export enum ResourceLevelType {
  Everyone = "everyone",
  Beginner = "beginner",
  Intermediate = "intermediate",
  Advanced = "advanced",
  Specialized = "specialized"
}

export type ResourceInput = {
  id?: string
  title?: {[key: string]: string}
  published?: boolean
  level?: ResourceLevelType
  access?: ResourceAccessType
  resourceType?: ResourceType
  resourceSourceType?: ResourceSourceType
  tags?: Array<string>
  description?: {[key: string]: string}
  ordering?: number
  sourceStatus?: ResourceSourceStatus
  source?: string,
  thumbnail?: ResourceThumbnailStatus
}

export type Resource = {
  id: string
  title: {[key: string]: string}
  published: boolean
  level: ResourceLevelType
  access: ResourceAccessType
  resourceType: ResourceType
  resourceSourceType: ResourceSourceType
  tags: Array<string>
  description: {[key: string]: string}
  ordering: number
  viewCount: number
  sourceStatus: ResourceSourceStatus
  source: string,
  thumbnail: ResourceThumbnailStatus
  createdBy: string
  progress?: ResourceUserProgress
  createdAt: string
  updatedAt: string
}

export interface ResourceUserProgress extends Omit<UserProgress, 'metadata'> { 
  metadata:{
    completed:boolean
  }
}

export type ResourcePage = {
  resources: Array<Resource>
  nextToken?: string
}

export const ResourceColorMap = {
  [ResourceType.webinar]: "#FF5722",
  [ResourceType.documentary]: "#FF6F00",
  [ResourceType.roadmap]: "#9C27B0",
  [ResourceType.explainer]: "#673AB7",
  [ResourceType.tutorial]: "#3F51B5",
  [ResourceType.infographic]: "#6D4C41",
  [ResourceType.training]: "#03A9F4",
  [ResourceType.annoucement]: "#00BCD4",
  [ResourceType.interview]: "#009688",
  [ResourceType.analysis]: "#8BC34A",
  [ResourceType.podcast]: "#CDDC39",
  [ResourceType.article]: "#DDA0DD",
  [ResourceType.book]: "#FA8072",
  [ResourceType.tool]: "#FFC107"
}