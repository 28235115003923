
import { Course, CourseInput, CourseModuleElementInput, CourseModuleInput, CourseUserProgress, ProgressType, UserCourseReportType, UserProgressInput} from '../../models'
import wrapper from './wrapper';


/* full course properties string */
const fullCourseProps = `
  modules {
    courseId
    elements {
      id
      moduleId
      video
      description
      published
      createdAt
      courseId
      tags
      ordering
      thumbnail
      title
      totalTimeHours
      updatedAt
    }
    published
    description
    createdAt
    id
    tags
    ordering
    title
    updatedAt
    thumbnail
    totalTimeHours
  }
  updatedAt
  totalTimeHours
  title
  published
  thumbnail
  tags
  studentCount
  ratingTotal
  ratingCount
  price
  video
  ordering
  createdAt
  createdBy
  description
  id
  level
  userReviews {
    parentId
    type
    createdAt
    metadata
  }
  progress {
    createdAt
    metadata
    parentId
    type
    updatedAt
    numValue
    textValue
    completed
    userId
  }
`;
/* post process course, sort elements and modules and parse progress */
const postProcessCourse = (course: Course)=>{

    /* parse title an description */
    course.title = JSON.parse(`${course.title}`);
    course.description = JSON.parse(`${course.description}`);
    /* sort modules */
    course.modules = course.modules?.sort((a,b)=>a.ordering - b.ordering);
    /* sort course module elements */
    for(let m of course.modules || []){
      /* course module parsing */
      m.title = JSON.parse(`${m.title}`);
      m.description = JSON.parse(`${m.description}`);
      /* sort elements */
      m.elements = m.elements?.sort((a,b)=>a.ordering - b.ordering);
      /* parsing of elements */
      for(let e of m.elements){
        /* module element parsing */
        e.title = JSON.parse(`${e.title}`);
        e.description = JSON.parse(`${e.description}`);
      }
    }
    /* parse course progress metadata if present */
    if(course.progress){
      let metadata = course.progress.metadata as any;
      course.progress.metadata = JSON.parse(metadata);
    }
}

/* post process course, sort elements and modules and parse progress */
const preprocessProcessElement = (el: any)=>{
  return {
    ...el,
    title: el.title?JSON.stringify(el.title):undefined,
    description: el.description?JSON.stringify(el.description):undefined
  }
}

export default class CourseClient {

  /* Create course graphql function*/
  public static async createCourse(course:CourseInput): Promise<any> {

    /* access list query */
    const mutation = `mutation ($course: CourseInput) {
      createCourse(newCourse: $course) {
        id
      }
    }`;
    /* stringify props */
    course = preprocessProcessElement(course);
    /* execute with query with map */
    let {createCourse}:any = await wrapper.grapql(mutation,{course});
    /* return course id */
    return createCourse.id;
  }
  /* update course entry*/
  public static async updateCourse(entry:CourseInput): Promise<any> {

    /* access list query */
    const mutation = `mutation ($entry: CourseInput) {
      updateCourse(entry: $entry){
        id
      }
    }`;
    /* stringify props */
    entry = preprocessProcessElement(entry);
    /* execute with query with map */
    let {updateCourse} :any = await wrapper.grapql(mutation,{entry: entry});
    return updateCourse;
  }
 /* Create course graphql function*/
 public static async deleteCourse(id:string): Promise<any> {

    /* access list query */
    const mutation = `mutation ($id: ID) {
      deleteCourse(courseId: $id) {
        status
      }
    }`;
    /* execute with query with map */
    let result:any = await wrapper.grapql(mutation,{id});
    /* return course id */
    return result;
  }
  /* update course entry*/
  public static async chargeCourseToCustomer(courseId:string): Promise<Course> {
    /* access list query */
    const mutation = `mutation ($courseId: ID) {
      chargeCourseToCustomer(courseId: $courseId){
        ${fullCourseProps}
      }
    }`;
    /* execute with query with map */
    let {chargeCourseToCustomer} :any = await wrapper.grapql(mutation,{courseId});
    /* post process course */
    postProcessCourse(chargeCourseToCustomer);
    
    return chargeCourseToCustomer;
  }
  /* Create course graphql function*/
  public static async getCourses(): Promise<Course[]> {

    /* access list query */
    const query = `query {
      getCourses{
        courses{
          id
          title
          level
          totalTimeHours
          tags
          description
          ratingCount
          ratingTotal
          studentCount
          published
          ordering
          price
          video
          thumbnail
          createdBy
          createdAt
          updatedAt
        }
      }
    }`;

    /* execute with query with map */
    let {getCourses}:any = await wrapper.grapql(query, {});

    /* if there is data */
    if (getCourses) {
      let courses = getCourses.courses.sort((a:Course,b:Course)=>a.ordering - b.ordering);
      for(let c of courses){
        /* parse title an description */
        c.title = JSON.parse(`${c.title}`);
        c.description = JSON.parse(`${c.description}`);
      }
      /* sort by ordering and return */
      return courses;
    } else {
      throw Error("Could not retrieve courses!");
    }

  }
  /* Create course graphql function*/
  public static async getCourseProgress(): Promise<CourseUserProgress[]> {

    /* access list query */
    const query = `query ($type: progressType) {
      getUserProgressByType(type: $type){
        items {
          createdAt
          metadata
          parentId
          type
          updatedAt
          numValue
          textValue
          completed
          userId
        }
      }
    }`;
    /* execute with query with map */
    let {getUserProgressByType: {items}} = await wrapper.grapql(query,{type: ProgressType.CourseProgress});
    /* the user progress completely parsed */
    return items.map((i:any)=>{return {...i, metadata: JSON.parse(i.metadata)}});
  }
  /* Create course graphql function*/
  public static async getCourseById(id:string): Promise<Course> {

    /* access list query */
    const query = `query ($id: ID) {
      getCourseById(courseId: $id){
        ${fullCourseProps}
      }
    }`;
    /* execute with query with map */
    let {getCourseById}:{getCourseById:Course} = await wrapper.grapql(query,{id});
    /* post process course */
    postProcessCourse(getCourseById);
    /* if there is data */
    return getCourseById;
  }

  /* Create course module graphql function*/
  public static async CreateCourseModule(module:CourseModuleInput): Promise<any> {

    /* access list query */
    const mutation = `mutation ($module: CourseModuleInput) {
      createCourseModule(newModule: $module){
        id
      }
    }`;
    /* stringify props */
    module = preprocessProcessElement(module);
    /* execute with query with map */
    let result:any = await wrapper.grapql(mutation,{module});
  }
  /* update course module entry*/
  public static async updateCourseModule(entry:CourseModuleInput): Promise<any> {
    /* access list query */
    const mutation = `mutation ($entry: CourseModuleInput) {
      updateCourseModule(entry: $entry){
        id
      }
    }`;
    /* stringify props */
    entry = preprocessProcessElement(entry);
    /* execute with query with map */
    let {updateCourseModule} :any = await wrapper.grapql(mutation,{entry: entry});
    return updateCourseModule;
  }
 /* Create course graphql function*/
 public static async deleteCourseModule(moduleId:string, courseId: string): Promise<any> {

    /* access list query */
    const mutation = `mutation ($moduleId: ID, $courseId: ID,) {
      deleteCourseModule(moduleId: $moduleId, courseId: $courseId) {
        status
      }
    }`;
    /* execute with query with map */
    let result:any = await wrapper.grapql(mutation,{moduleId, courseId});
    /* return course id */
    return result;
  }
  /* Create course module element graphql function*/
  public static async CreateCourseModuleElement(element:CourseModuleElementInput): Promise<any> {

    /* access list query */
    const mutation = `mutation ($element: CourseModuleElementInput) {
      createCourseModuleElement(newElement: $element){
        id
      }
    }`;
    /* stringify props */
    element = preprocessProcessElement(element);
    /* execute with query with map */
    let result:any = await wrapper.grapql(mutation,{element});
  }
  /* update course module entry*/
  public static async updateCourseModuleElement(entry:CourseModuleElementInput): Promise<any> {
    /* access list query */
    const mutation = `mutation ($entry: CourseModuleElementInput) {
      updateCourseModuleElement(entry: $entry){
        id
      }
    }`;
    /* stringify props */
    entry = preprocessProcessElement(entry);
    /* execute with query with map */
    let {updateCourseModuleElement} :any = await wrapper.grapql(mutation,{entry: entry});
    return updateCourseModuleElement;
  }
  /* Create course graphql function*/
  public static async deleteCourseModuleElement(elementId:string, courseId: string): Promise<any> {

    /* access list query */
    const mutation = `mutation ($elementId: ID, $courseId: ID,) {
      deleteCourseModuleElement(elementId: $elementId, courseId: $courseId) {
        status
      }
    }`;

    /* execute with query with map */
    let result:any = await wrapper.grapql(mutation,{elementId, courseId});
    /* return course id */
    return result;
  }
  /* report course action to remote endpoint */
  public static async reportUserCourseAction(action:UserCourseReportType, courseId: string, title: string, details: string): Promise<any> {

    /* access list query */
    const mutation = `mutation ($title: String, $courseId: String, $details: String, $action: CourseUserReportAction) {
      reportUserCourseAction(title: $title, courseId: $courseId, details: $details, action: $action)
    }`;

    /* execute with query with map */
    let result:any = await wrapper.grapql(mutation,{action, courseId, details, title});
    /* return course id */
    return result;
  }
}