import { Col, Row, Divider, Rate } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams} from 'react-router';
import {CourseClient} from '../../../lib/api';
import { Course, CourseDataType, CourseUserProgress, Language, StripePaymentMethod, ThumbnailStatus, UserCourseReportType, VideoStatus } from '../../../models';
import { block } from '../../commons/misc/front/miscSlice';
import MDEditor from '@uiw/react-md-editor';
import { HubButton, HubGradientText, HubPriceLabel, HubTitle, HubVideoPlayer } from '../../commons/misc/front/hubComponents';
import stringHash from "@sindresorhus/string-hash";
import { Gradients, Helpers } from '../../../lib/utils';
import { useInView } from 'react-intersection-observer';
import ContentMenu from './ContentMenu';
import PurchaseCourseModal from './PurchaseModal';
import FinishCourseModal from './FinishModal';
import ReviewModal from './ReviewModal';
import { isMobile } from 'react-device-detect';
import ReviewListModal from './ReviewListModal';
import PageNotFound from '../../commons/misc/front/404';
import { FileClient, StripeClient } from '../../../lib/api';
import { OnProgressProps } from 'react-player/base';
import UserClient from '../../../lib/api/user_client';
import { Attention } from '../../../lib/utils/notification';
import { systemDefaults } from '../../../lib/utils/enums';
import AnalyticsClient, { AnalyticsEvent } from '../../../lib/api/analytics_client';


const CourseBody: React.FC = () =>  {

  const { courseId, moduleId, elementId } = useParams();
  let navigate = useNavigate();
  const [lang] = useState<Language>(Helpers.getUserLang());
  const { ref, inView, entry } = useInView({threshold: 0.1});
  let [renderBottomDiv, setRenderBottomDiv] = useState<boolean>(false);
  let [subtitles, setSubtitles] = useState(new Map<string, string>());
  let [course, setCourse] = useState<Course|null>(null);
  let [selectedItem, setSelectedItem] = useState<any>(null);
  let [progressMap, setProgressMap] = useState<Map<string,boolean>>(new Map());
  let [courseProgress, setCourseProgress] = useState<CourseUserProgress| null>();
  let [paymentMethods, setPaymentMethods] = useState<StripePaymentMethod[]>([]);
  let [showReviewListModal, setShowReviewListModal] = useState<boolean>(false);
  let [showPurchaseModal, setShowPurchaseModal] = useState<boolean>(false);
  let [showFinishModal, setShowFinihsModal] = useState<boolean>(false);
  let [showReviewModal, setShowReviewModal] = useState<boolean>(false);
  let [courseFetched, setCourseFetched] = useState<boolean>(false);
  let [videoToken, setVideoToken] = useState<string>("");
  let [videoUrl, setVideoUrl] = useState<string>("");
  let [thumbnailUrl, setThumbnailUrl] = useState<string>("");

  /* fetch course */
  useEffect(() => {
    (async () => {
      if(courseId){
        try{
          await block(async ()=>{
            const [course, pm] = await Promise.all([
              CourseClient.getCourseById(courseId),
              StripeClient.read_payment_methods()
            ]);
            setCourse(course);
            setCourseProgress(course.progress);
            setPaymentMethods(pm.paymentMethods);
            setProgressMap(buildProgressMap(course));
          }); 
        }finally{
          setCourseFetched(true);
        }
      }
    })();
  }, [courseId]);

  /* build progress map to track course progress */
  const buildProgressMap = (course: Course)=>{
    let progress = new Map<string, boolean>();
    /* add all ids */
    progress.set(course.id,false);
    /* iterate modules */
    for(let m of course.modules || []){
      progress.set(m.id, false);
      /* iterate elements */
      for(let e of m.elements || []){
        progress.set(e.id, false);
      }
    }
    /* update the existing entries */
    let entries = Object.entries(course.progress?.metadata?.completeElements || {});
    for (const [key, value] of entries) {
      progress.set(key, value);
    }
    return progress;
  }

  const updateProgressHelper = async () =>{
      /* update progress into course */
      if(course && courseProgress){
        /* update local state */
        progressMap.set(selectedItem.id,true);
        courseProgress.metadata.completeElements[selectedItem.id] = true;
        await UserClient.updateUserProgress({parentId: course.id, 
                                            metadata:courseProgress.metadata,
                                            completed: JSON.stringify(Array.from(progressMap.values()).every((v)=> v))
                                          });
        /* save state */
        setProgressMap(new Map(progressMap));
        setCourseProgress({...courseProgress});
        /* show finish modal */
        if(Array.from(progressMap.values()).every((v)=> v)){
          setShowFinihsModal(true);
          /* send webhook */
          CourseClient.reportUserCourseAction(UserCourseReportType.finish,
            course.id,
            course.title[lang],
            "course finished!");
        }

      }
  }
   /* this will track the played video progress, update to complete when reached 90% */
  const onPlayBackProgress = async ({played}:OnProgressProps)=>{
    if(played > 0.9 && !progressMap.get(selectedItem.id) && courseProgress){
        /* update course */
        updateProgressHelper();
    }
  }
  const fetchCourseSubtitles = async (data: any, type: any, ) =>{
    const root = Helpers.buildStoredSubtitleRoot(data, type);
    let subs = await FileClient.listDirectoryFiles(root);
    let present = new Map<string, string>();
    for(let s of subs){
      let lang = s.replace(/^.*[\\\/]/, '').split('.')[0];
      let key = await FileClient.getFile(s);
      present.set(lang,key);
    }
    /* set the new map */
    setSubtitles(present);
  } 
  /* update completion if scroll or percent of video is reached or both */
  useEffect(() => {
      /* call to update */
      (async ()=>{
        if(inView && !progressMap.get(selectedItem.id) && 
        (selectedItem.video == VideoStatus.None || !selectedItem.video) 
        && courseProgress){ 
          /* update course */
          updateProgressHelper();
        }
      })()
  }, [entry]);

  /* if module */
  useEffect(() => {
    (async () => {
      let videoKey = "", thumbnailKey = "", ref:any = {}, type: CourseDataType = CourseDataType.course;
      if(moduleId && elementId){
        let m = course?.modules?.find((m)=> m.id == moduleId);
        ref = m?.elements?.find((e)=> e.id == elementId);
        type = CourseDataType.element;
        if(ref?.video == VideoStatus.Ready){
          videoKey = Helpers.buildCourseMediaURL(ref, CourseDataType.element,true,true,true);
        }
      }else if (moduleId){
        ref = course?.modules?.find((m)=> m.id == moduleId);
        type = CourseDataType.module;
      }else{
        ref = course;
        type = CourseDataType.course;
        if(course?.video == VideoStatus.Ready){
          videoKey = Helpers.buildCourseMediaURL(course, CourseDataType.course,true, true, true);
        }
      }
      /* set states */
      setSelectedItem(ref);
      /* hidde bottom div */
      setRenderBottomDiv(false);
      /* fetch thumbnail if present */
      if(ref?.thumbnail == ThumbnailStatus.Ready){
        thumbnailKey =  Helpers.buildCourseMediaURL(ref, type ,false);
      }
      /* fetch subtitles if present */
      if(ref?.video == VideoStatus.Ready){
        await fetchCourseSubtitles(ref, type);
      }
      /* fetch url's */
      await fetchAndSetURLs(videoKey, thumbnailKey, type);
      /* run timer to re-render bottom div */
      setTimeout(()=>{
        setRenderBottomDiv(true);
      },1000);
    })();
  }, [moduleId, elementId, course]);

  const fetchAndSetURLs = async (videoKey:string, thumbnailKey:string, type: CourseDataType)=>{

    if((type == CourseDataType.element && !courseProgress) || !videoKey){
      setVideoUrl("");
    }else{
      let vUrl = await FileClient.getStoredMedia(videoKey,false, true);
      let root = videoKey.substring(0, videoKey.lastIndexOf('/'));
      let token = await FileClient.getMediaToken(root);
      /* set the state */
      setVideoToken(token);
      setVideoUrl(vUrl);
    }

    if(thumbnailKey){
      let tUrl = await FileClient.getStoredMedia(thumbnailKey, false, false);
      setThumbnailUrl(tUrl);
    }else{
      setThumbnailUrl("");
    }
  }

  const onItemSelected = (data: any, type: CourseDataType)=>{
    /* append routes */
    switch(type){
      case CourseDataType.course:
        navigate(`/courses/${data.id}`,{replace:true});
        break;
      case CourseDataType.module:
        navigate(`/courses/${data.courseId}/${data.id}`, {replace:true})
        break;
      case CourseDataType.element:
        navigate(`/courses/${data.courseId}/${data.moduleId}/${data.id}`, {replace:true})
        break;
    }
  }

  /* if course is not fetched, return none */
  if(!courseFetched){
    return <></>
  }else if(courseFetched && !course){
    return ( 
      <Row justify='center' align='middle'>
        <Col style={{textAlign:'center'}}>
        <PageNotFound/>
          <HubTitle size='large'>Sorry, this course could not be found...</HubTitle>
          <br/><br/>
          <HubButton onClick={()=>navigate('/courses')}>Back to Courses</HubButton>
        </Col>
      </Row>)
  }

  const renderVisualElement = ()=>{
    /* the element reference */
    let element = null;
    /* if video is present */
    if(videoUrl){
      element = <HubVideoPlayer url={videoUrl}
                                subtitles={subtitles} 
                                token={videoToken} 
                                showLoading={true} 
                                onProgress={onPlayBackProgress}/>
    }else if(selectedItem && selectedItem.video == VideoStatus.Ready && !videoUrl){
      element = <HubVideoPlayer showLoading={!!courseProgress}/>
    }else if(thumbnailUrl){
      element = <HubVideoPlayer banner={thumbnailUrl} showLoading={true}/>
    }
    /* if there is an element, return */
    if(element){
      return (
        <Row justify='start' align='middle'>
          <Col span={24} style={{textAlign:'center', marginBottom:10}}>
              {element}
          </Col>
        </Row>
      )
    }
  }

  const submitReview = async(review: string, score: number)=>{

    try{
      await block(async()=>{
        let courseId = course?.id as string;
        await UserClient.updateUserProgress({parentId: courseId, 
        textValue: review, numValue: score});
        /* update course progress */
        if(courseProgress){
          courseProgress.textValue = review;
          courseProgress.numValue = score;
          setCourseProgress({...courseProgress});
        }
        /* hide modal if successful review */
        setShowReviewModal(false);
        /* send review webhook */
        CourseClient.reportUserCourseAction(UserCourseReportType.reviewed,
                                            course?.id || "",
                                            course?.title[lang] || "",
                                            `${score}/5 ${review}`);

        setTimeout(async()=>{
          let nCourse = await CourseClient.getCourseById(courseId);
          setCourse(nCourse);
        },3000)
      });

      Attention.notifySuccess("Thanks for the feedback! 😃");
    }catch(e:any){
      Attention.notifyError("Cannot write review at this time", e.message)
    }
  }

  const openReviewModal = ()=>{
    setShowFinihsModal(false);
    setShowReviewModal(true);
    AnalyticsClient.record(AnalyticsEvent.openCourseReview,{
      title: course?.title[lang] || "",
      id: course?.id || ""
    });
  }

  const cornerTopBoxStyles: React.CSSProperties = {
    background: 'white',
    border: '1px solid #FF8642',
    padding: 6,
    margin: 6,
    borderRadius: 6
  }

  const renderPriceBuyButton = ()=>{
    return <Row justify='center' align='middle' gutter={[10,0]} style={cornerTopBoxStyles}>
          <Col>
            <HubPriceLabel price={course?.price as number} size={34} showCents />
          </Col>
          <Col>
            <HubButton onClick={()=> setShowPurchaseModal(true)} size='large'>Buy Course</HubButton>
          </Col>
      </Row>
  }
  const renderReviewButton = ()=>{
    let isFinished = Array.from(progressMap.values()).every((v)=> v);
    if(isFinished && courseProgress && courseProgress.textValue === systemDefaults.emptyDynamoString){
      return <Row justify='center' align='middle' gutter={[10,0]} style={cornerTopBoxStyles}>
        <Col>
          <HubButton onClick={()=> setShowReviewModal(true)} shake size='large'>Leave Review</HubButton>
        </Col>
      </Row>
    }else{
      return null;
    }
  }
  const onPurchaseConfirmed = async (course: Course)=>{
    setCourse(course);
    setCourseProgress(course.progress);
    /* send webhook */
    CourseClient.reportUserCourseAction(UserCourseReportType.purchased,
                                      course.id,
                                      course.title[lang],
                                      "course purchased!");
  }
  const openReviewListModal = async ()=>{
    setShowReviewListModal(true);                            
  }
  /* content div */
  const contentDiv = <ContentMenu course={course as Course} progressMap={progressMap} onSelect={onItemSelected} selectedKey={selectedItem?.id}/>
  const buyReviewDiv = !courseProgress?renderPriceBuyButton():renderReviewButton();
  return <>
      <Row align='middle'>
        <Col span={isMobile?24:18}>
          <Row align='middle' justify='start'>
            <Col >
              <HubButton size='large' onClick={()=>navigate('/courses')}>Go Back</HubButton>
            </Col>
            <Col style={{paddingLeft: 6}}>
              <HubGradientText forceBlack size={isMobile?18:30} colors={Gradients[stringHash(course?.id as string)%Gradients.length]}>
                <strong>{course?.title[lang]}</strong>
              </HubGradientText>
              {!course?.progress &&
                <HubGradientText forceBlack size={isMobile?16:28} colors={Gradients[stringHash(course?.id as string)%Gradients.length]}>
                  <strong>{" 🔒 (PREVIEW)"}</strong>
                </HubGradientText>
              }
            </Col>
            <Col style={{paddingLeft: 6}}>
                <Rate disabled allowHalf value={(course?.ratingTotal || 0)/(course?.ratingCount || 1)} />
                &nbsp;
                <HubButton onClick={openReviewListModal} size='small' type='default'>
                  {course?.ratingCount}
                </HubButton>
            </Col>
          </Row>
        </Col>
        <Col span={isMobile?0:6}>
            {buyReviewDiv}
        </Col>
      </Row>
      {isMobile && 
        <Row>
           <Col span={24}>
            {buyReviewDiv}
          </Col>
        </Row>
      }
      <Row gutter={[6,2]}>
        <Col span={isMobile?24:18}>
            {renderVisualElement()}
            <Row justify='center' align='top' data-color-mode="light">
              <Col span={24}>             
                <MDEditor.Markdown source={selectedItem?.description[lang]} 
                  style={{ padding:24 ,borderRadius: 8, fontFamily:'roboto', border:'solid 1px #FF8642' }} 
                  linkTarget="_blank" 
                /> 
              </Col>
            </Row>
        </Col>
        <Col span={isMobile?0:6}>
            {contentDiv}
        </Col>
      </Row>
      <Row>
        <Col span={isMobile?24:0}>
            <br/>
            {contentDiv}
        </Col>
      </Row>
      {renderBottomDiv?<div key={selectedItem && selectedItem.id} ref={ref} style={{height:1}}></div>:null} 
      <PurchaseCourseModal  course={course as Course}
                            isOpen={showPurchaseModal}
                            paymentMethods={paymentMethods}
                            onCancel={()=>setShowPurchaseModal(false)}
                            onPurchaseConfirmed={onPurchaseConfirmed} />
      <FinishCourseModal  course={course as Course}
                      isOpen={showFinishModal}
                      onCancel={()=>setShowFinihsModal(false)}
                      onGoToReview={openReviewModal} />
      <ReviewModal  course={course as Course}
                      isOpen={showReviewModal}
                      onCancel={()=>setShowReviewModal(false)}
                      submitReview={submitReview} />
      <ReviewListModal  course={course as Course}
                      isOpen={showReviewListModal}
                      onCancel={()=>setShowReviewListModal(false)}/>
    </>;
}

export default CourseBody;