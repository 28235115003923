import { CognitoUserSession} from 'amazon-cognito-identity-js';
import Pub from '../../components/commons/pubsub/front/publications';
import { CourseDataType, Language, ResourceType, User, UserConfiguration } from '../../models';
import { AccessClient, ConfigClient } from '../api';
import { Auth} from 'aws-amplify';
import { store } from '../../app/store';
import { Attention } from './notification';
import PubSubConfigure from '../../components/commons/pubsub/front/PubSubConfigure';
import UserClient from '../api/user_client';
import { Role, RoleHierarchy } from './enums';
import stc from "string-to-color";
import AnalyticsClient from '../api/analytics_client';
import { ResourceSourceType } from '../../models/Resource';

export default class Helpers {

  public static async configureUser(config:UserConfiguration){
    let {isConnected} = store.getState().pubsub;
     /* check if configuration is in place */
     if(!config.isPubSubConfigured){
      let results = await ConfigClient.configureUserPubSub();
      /* if configuration was successful, refetch*/
      if(results && !results.error){
        config = await ConfigClient.getUserConfiguration();
      }else{
        throw new Error("Unable to configure user, please contact administrator.")
      }
    }
    /* if pup sub configured and is not already connected */
    if(config.isPubSubConfigured && !isConnected){
      /* configure IOT mqtt endpoint */
      PubSubConfigure.init();
    }
  }

  public static async userExist(username:String){
    try{
      await Auth.signIn(username.toLowerCase(), '123');
    }catch(e:any){
      const code = e.code;
      switch (code) {
          case 'UserNotFoundException':
              return false
          case 'NotAuthorizedException':
              return true;
          case 'PasswordResetRequiredException':
            return true;
          case 'UserNotConfirmedException':
              return true;
          default:
              return false;
      }
    }
  }


  public static async refreshUser(showWelcome:boolean=false){
    const session = await Auth.currentSession();
    if(session && session.isValid()) {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true
      });
      await new Promise((resolve, reject)=>{
        user.refreshSession(session.getRefreshToken(), async (err:any, session:CognitoUserSession) => {
          if(!err){
            await Helpers.authenticateUser(user, session, showWelcome);
            resolve(true);
          }else{
            reject(err);
          }
        });
      })
    }
  }

  public static async authenticateUser(user: any, session: CognitoUserSession, showWelcome: boolean= true){
    /* get user attributes */
    let dUser = Helpers.decodeUser(user, session);
    /* update analytics endpoint: Yielding error, dissabled at the moment. */
    // await AnalyticsClient.update(dUser);
    /* set current user */
    store.dispatch({
      type: "auth/setUser",
      payload: dUser,
    });
    /* get access map and user configuration */
    let operations = [
      AccessClient.getAccessMap(),
      ConfigClient.getUserConfiguration()
    ]
    /* await both operations */
    let [accessMap, config] = await Promise.all(operations);
    /* check configuration */
    await Helpers.configureUser(config);
    /* set values */
    store.dispatch({
      type: "auth/setUserConfig",
      payload: config
    });
    store.dispatch({
      type: "auth/setAccess",
      payload: accessMap
    });
    /* fetch profile pic if exist */
    await UserClient.getAndCacheUserProfileImage();
    /* send token refresh message */
    setTimeout(() => {
      Pub.sendTokenRefreshedMsg(dUser.refreshToken);
    }, 1000);
    /* build welcome message */
    let msg = `Hi there, ${dUser.given_name} ${dUser.family_name}`;
    /* send notification */
    if(showWelcome){
      Attention.notifySuccess("Welcome Back!",msg);
    }
    /* return user */
    return dUser;
  }
  public static async logout(){
    /* disconnect pubsub */
    PubSubConfigure.cleanUp();
    /* get user attributes */
    store.dispatch({
      type: "auth/resetSession"
    });
    /* logout from amplify */
    await Auth.signOut({ global: false });
    /* clear local storage */
    localStorage.clear();
  }
  public static getHighestRole(roles:Role[]): Role{
    let role = roles[0] as Role;
    for(let r of roles){
      if((RoleHierarchy.get(r) as number) < (RoleHierarchy.get(role) as number) ){
        role = r;
      }
    }
    return role;
  }
  public static decodeUser(user: any, session: CognitoUserSession): User {
    
     /* get user attributes */
     const attr = user.attributes;
     const role = Helpers.getHighestRole(session.getAccessToken().payload['cognito:groups']);
     const accessToken = session.getAccessToken().getJwtToken();
     const accessTokenExp = session.getAccessToken().getExpiration();
     const accessTokenIssued = session.getAccessToken().getIssuedAt();
     const idToken = session.getIdToken().getJwtToken();
     const idTokenExp = session.getIdToken().getExpiration();
     const idTokenIssued = session.getIdToken().getIssuedAt();
     const refreshToken = session.getRefreshToken().getToken();
     /* set current user */
     return {...attr,
            role, 
            accessToken, 
            accessTokenExp, 
            accessTokenIssued,
            idToken, 
            idTokenExp,
            idTokenIssued, 
            refreshToken}
  }
  public static getUserLang = () => {
    return store.getState().auth.user?.["custom:language"] as Language;
  }
  public static isUserStaff = () => {
    const role = store.getState().auth.user?.role;
    return role === Role.ADMIN || role === Role.STAFF;
  }

  public static buildStorageFilePrefix = (data:any, type: any, filename: string) => {
    switch(type){
      case CourseDataType.course:
        return `courses/${data.id}/${filename}`;
      case CourseDataType.module:
        return `courses/${data.courseId}/modules/${data.id}/${filename}`;
      case CourseDataType.element:
        return `courses/${data.courseId}/modules/${data.moduleId}/elements/${data.id}/${filename}`;
      case "resource":
        return `resources/${data.id}/${filename}`;
      default:
        return ""
    }

  }
  public static buildCourseMediaURL = (data:any, type: CourseDataType, isVideo: boolean=false, m3u8=false, full=false) =>{
    /* file name */
    const filename = isVideo?`video/source.${m3u8?'m3u8':'mp4'}`:'thumbnail.png';
    let url = Helpers.buildStorageFilePrefix(data, type, filename);
    if(full){
      return `public/${url}`;
    }
    return url;
  }

  public static buildResourceSourceURL = (data:any, type: ResourceSourceType, thumbnailName: string="", m3u8=false, full=false) =>{
    /* file name */
    let filename = ""
    /* build file url */
    if(thumbnailName){
      filename = thumbnailName;
    }else{
      switch(type){
        case ResourceSourceType.image:
          filename = "source.png";
          break;
        case ResourceSourceType.pdf:
          filename = "source.pdf"
          break;
        case ResourceSourceType.rainhub_video:
          filename = `video/source.${m3u8?'m3u8':'mp4'}`
          break;
        default:
          filename = "";
      }
    }
    let url = Helpers.buildStorageFilePrefix(data, "resource", filename);
    if(full){
      return `public/${url}`;
    }
    return url as string;
  }
  public static buildStoredSubtitleURL = (data:any, type: any, lang: string, full=false): string =>{
    /* file name */
    const filename = `video/subs/${lang}.vtt`;
    let url = Helpers.buildStorageFilePrefix(data, type, filename);
    if(full){
      return `public/${url}`;
    }
    return url;
  }
  public static buildStoredSubtitleRoot = (data:any, type: any) =>{
    /* file name */
    const filename = `video/subs`;
    let url = Helpers.buildStorageFilePrefix(data, type, filename);
    return url as string;
  }
  public static colorsFromString = (text: string, count: number): string[] =>{
    if(text.length==0) return ['red','green', 'blue'];
    return text.match(new RegExp(`.{1,${Math.ceil(text.length/count)}}`, 'g'))?.map((s)=>stc(s)) || [] as string[]
  }
  public static readFile = (file: File, asBinary: boolean = false): Promise<string|ArrayBuffer>=> {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result as any), false)
      if(asBinary){
        reader.readAsArrayBuffer(file)
      }else{
        reader.readAsDataURL(file)
      }
    })
  }
}

