import React, { CSSProperties, useRef , useState} from 'react';
import { Carousel, Button, Row, Col} from 'antd';
import './index.css';
import { CarouselRef } from 'antd/lib/carousel';
import AddBankAccount from './addBankAccount';
import AddCard from './addCard';
import CreditCard from '../CreditCard';
import BankAccount from '../BankAccount';
import { HubModal, HubTitle } from '../../../../commons/misc/front/hubComponents';
import { isMobile } from 'react-device-detect';


export default function AddPaymentMethodModal(props: any) {

    const carousel = useRef<CarouselRef|null>();
    const [slideIdx, setSlideIdx] = useState<number>(0);

    const handleCancel = () => {
        props.setIsModalVisible(false);
        goTo(0);
    }
    const goTo = (page: number) => {
        if(carousel.current)
        {
            carousel.current.goTo(page);
            setSlideIdx(page);
        }
    }

    const buttonStyles: CSSProperties =  {
              padding: 5, 
              height: "100%", 
              width: "90%", 
              borderRadius: 8,
              textAlign: 'center'
    };

    return (
        <React.Fragment>
            <HubModal open={props.isModalVisible} onCancel={()=>props.setIsModalVisible(false)} showOk={false}>
                <Carousel swipe={false}  
                          swipeToSlide={false} 
                          style={{textAlign: "center", minHeight:200, display: 'contents'}} 
                          ref={ref => carousel.current = ref 
                          }>
                    <div>
                      <Row justify='center' align='middle'>
                        <Col span={isMobile?24:12}>
                          <Button type="default" style={buttonStyles} onClick={() => goTo(1)}>
                            <div>
                              <CreditCard number='1234' country='US' type='MY CARD'/>
                            </div>
                            <HubTitle >Credit Card </HubTitle>
                          </Button>
                        </Col>
                        <Col span={isMobile?24:12}>
                          <Button type="default" style={buttonStyles} onClick={() => goTo(2)}>
                              <div>
                                <BankAccount number='1234' country='US' type='MY BANK'/>
                              </div>
                              <HubTitle>Bank Account </HubTitle>
                            </Button>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      {slideIdx ==1 &&
                        <AddCard goTo={goTo} close={handleCancel} reloadPaymentMethods={props.reloadPaymentMethods}/>
                      }
                    </div>
                    <div>
                      {slideIdx ==2 &&
                          <AddBankAccount goTo={goTo} close={handleCancel} reloadPaymentMethods={props.reloadPaymentMethods} />
                      }
                    </div>
                </Carousel>
            </HubModal>
        </React.Fragment>
    );
}