import React from 'react';
import { Routes, Route } from 'react-router';
import { Area } from '../../../lib/utils/enums';
import { Gateway } from '../../commons/gateway/front/Gateway';
import CourseMenu from './CourseList';
import CourseBody from './CourseBody'

const Menu: React.FC = () =>  {

  let styles:React.CSSProperties = {
    height: '100vh', 
    overflowY:'scroll', 
    overflowX:'hidden'
  };
  return <div style={styles}>
    <Routes>
      <Route path="/" element={<CourseMenu/>} />
      <Route path=":courseId" element={<CourseBody/>}/>
      <Route path=":courseId/:moduleId" element={<CourseBody/>}/>
      <Route path=":courseId/:moduleId/:elementId" element={<CourseBody/>}/>
    </Routes>
    </div>;
}

export default Gateway(Menu, Area.COURSES, true);