import React, { useState } from "react";

import { HubButton, HubGradientText, HubModal, HubPriceLabel } from "../../commons/misc/front/hubComponents";
import { Col, Layout, Row, Typography } from "antd";
import { Course, Language, StripePaymentMethod } from "../../../models";
import { Gradients, Helpers } from "../../../lib/utils";
import stringHash from "@sindresorhus/string-hash";
import { useNavigate } from "react-router";
import {PlusOutlined } from '@ant-design/icons';
import fireworks from '../../../media/animations/fireworks.json';
import { Area } from "../../../lib/utils/enums";
import Lottie from "lottie-react";
import { block } from "../../commons/misc/front/miscSlice";
import {CourseClient} from "../../../lib/api";
import { Attention } from "../../../lib/utils/notification";

const { Text } = Typography;
const { Content } = Layout;

type PurchaseCourseModalProps = {
  isOpen:boolean,
  course: Course,
  paymentMethods: StripePaymentMethod[],
  onPurchaseConfirmed: (course: Course)=>void,
  onCancel: ()=>void,
}

const PurchaseCourseModal = ({
  isOpen,
  course,
  paymentMethods,
  onPurchaseConfirmed,
  onCancel,
}: PurchaseCourseModalProps) => {

  const navigate = useNavigate();
  
  const [lang] = useState<Language>(Helpers.getUserLang());
  const onPurchaseConfirm = async()=>{
    try{
      let nCourse = await block(async ()=>{
        return await CourseClient.chargeCourseToCustomer(course.id)
      }) as Course;
      /* call parent on confirm */
      await onPurchaseConfirmed(nCourse);
    }catch(e:any){
      Attention.notifyError("Could not purchase course.",
        e.message
      )
    }
  }

  const onPurchaseCompleted = ()=>{
    onCancel();
  }
  
  const renderConfirmPurchase = ()=>{
    /* get default payment method */
    let payment = paymentMethods.find((p)=>p.isDefault);
    if(!payment){
      return <React.Fragment>
              <Text type='danger'>
                  Please setup a payment method before purchasing a course
              </Text>
              <br />
              <HubButton 
                  style={{margin:10}}
                  icon={<PlusOutlined/>}
                  size={'middle'}
                  onClick={()=>{
                    navigate(`/${Area.ACCOUNT_WALLET}`);
                  }}>
                    Add Payment Method
                </HubButton>
              <br />
            </React.Fragment>
    }else{
      return <>
      <HubButton type="link" style={{marginBottom: 6}}>
        {payment.bank.capitalize()} ending in {payment.number.replaceAll('*','')}
      </HubButton>
        
        <br/>
        <HubButton onClick={onPurchaseConfirm} size='large'>Confirm Purchase</HubButton> 
      </>
    }
  }

  return (
    <HubModal
      title={
        <HubGradientText forceBlack size={24} colors={Gradients[stringHash(course?.id as string)%Gradients.length]}>
          <strong>{course?.title[lang]}</strong>
        </HubGradientText>
      }
      open={isOpen}
      onCancel={onCancel}
      showCancel={!course.progress}
      closable={!course.progress}
      showOk={false} 
    > 
      <Layout>
        <Content style={{ backgroundColor: "white", textAlign: "center" }}>
          {!course.progress?
            <>
                <Row align="middle" justify="center">
                  <Col>
                    <HubPriceLabel price={course?.price as number} size={64} showCents />
                  </Col> 
                </Row>
                <Row>
                  <Col style={{ textAlign: "center" }} span={24}>
                      {renderConfirmPurchase()}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Text type="secondary">
                      <br></br>
                      By pressing "Confirm Purchase" you authorize RainHub, LLC to
                      electronically debit your configured <strong>default payment method</strong> for
                      the price of this course. No Refunds. All sales are final.
                    </Text>
                  </Col>
                </Row>
              </>:
              <>
                <Row align="middle" justify="center">
                  <Col>
                    <Lottie loop={true} animationData={fireworks} style={{margin: "-60px"}} />
                  </Col>
                  <Col span={24}>
                      <h2>
                        You are ready to begin your learning journey!
                      </h2>
                  </Col> 
                </Row>
                <Row>
                  <Col style={{ textAlign: "center" }} span={24}>
                    <HubButton onClick={onPurchaseCompleted} size='large'>Let's Go!</HubButton> 
                  </Col>
                </Row>
              </>
          }
        </Content>
      </Layout>
    </HubModal>
  );
};

export default PurchaseCourseModal;
