import { ConnectionState, CONNECTION_STATE_CHANGE } from '@aws-amplify/pubsub';
import { Amplify, Hub, PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import APIWrapper from '../../../../lib/api/wrapper';
import Subs from './subscriptions';
import { store } from '../../../../app/store';
import AnalyticsClient, { AnalyticsEvent } from '../../../../lib/api/analytics_client';
import { User } from '../../../../models';

export default class PubSubConfigure {
  /* init flag to prevent first disconect status recursion */
  public static INITIAL_EVENT = true;
  public static PUPSUB_LISTENER:any = null;
  /* the subscriptions set */
  public static init(): any {

      const provider = new AWSIoTProvider({
        aws_pubsub_region: process.env.REACT_APP_AWS_REGION,
        aws_pubsub_endpoint: `wss://${process.env.REACT_APP_IOT_CORE_ENDPOINT}/mqtt`
      });
      // console.log("pubsubconfigured")
      /* configure IOT mqtt endpoint */
      Amplify.addPluggable(provider);
      /* set the pubsub listener */
      PubSubConfigure.PUPSUB_LISTENER = (data: any) => {
        const { payload } = data;
        if (payload.event === CONNECTION_STATE_CHANGE) {
          const connectionState = payload.data.connectionState as ConnectionState;
          // console.log('event', connectionState);
          /* get store */
          let {isUserIdle, hasInternet} = store.getState().pubsub;
          let user = store.getState().auth.user as User;
          /* if disconnected, check if user is idle before retrying connection */
          if(connectionState === ConnectionState.Disconnected &&  !PubSubConfigure.INITIAL_EVENT){
            /* clean up and reconnect after 1 second */
            PubSubConfigure.cleanUp();
            /* send analytics */
            if(user){
              AnalyticsClient.record(AnalyticsEvent.pubsubDisconnect,{
                discord: user["custom:discord_user"],
                email: user.email,
                name: user.given_name,
                lastName: user.family_name
              });
            }
            /* wait 1 second to reconnect if not idle */
            if(user && !isUserIdle && hasInternet){
              setTimeout(()=>PubSubConfigure.init(), 1000);
            }
          }
          /* dispatch store */
          store.dispatch({
            type: "pubsub/setStatus",
            payload: connectionState
          });
          /* set initial event */
          PubSubConfigure.INITIAL_EVENT = false;
        }
      }
      /* connect Hub */
      Hub.listen('pubsub', PubSubConfigure.PUPSUB_LISTENER);

      /* connect pub sub */
      APIWrapper.connect();
      /* initialize channels */
      Subs.init();

      /* connection and disconnection handlers */
      window.onoffline = (event:any) => {
        store.dispatch({
          type: "pubsub/setHasInternet",
          payload: false
        });
      };
      window.ononline = (event:any) => {
        store.dispatch({
          type: "pubsub/setHasInternet",
          payload: true
        });
        /* if the socket is disconnected then reinit */
        let {status} = store.getState().pubsub;
        let {user} = store.getState().auth;
        if(user && status === ConnectionState.Disconnected){
          PubSubConfigure.cleanUp();
          setTimeout(()=>PubSubConfigure.init(), 1000);
        }
      };
  }
  public static cleanUp(): any {
    /* clear all subscriptions */
    APIWrapper.unsubscribeAll();
    Hub.remove('pubsub', PubSubConfigure.PUPSUB_LISTENER);
    PubSubConfigure.INITIAL_EVENT = true;
    /* remove plugable */
    PubSub.removePluggable('AWSIoTProvider');
  }
}