import { API, Auth } from 'aws-amplify';
import {store} from '../../app/store';
import { User } from '../../models';
import { Helpers } from '../utils';
import { Attention } from '../utils/notification';
import { PubSub, Storage } from 'aws-amplify';
import { ErrorType, FILE_ACCESS_LEVEL } from '../utils/enums';
import UserClient from './user_client';
import GeneralClient from './general_client';
import AnalyticsClient, { AnalyticsEvent } from './analytics_client';

export default class APIWrapper {
  /* the subscriptions set */
  static subscriptions = new Map<string, any>();
  /* get access based on area and user */
  public static async grapql(query: string, variables: Object = {}): Promise<any> {
    /* get user from reducer */
    let user = store.getState().auth.user as User;
    let token = user? user.idToken: "unauthenticated";
    let tknExp = user? user.idTokenExp*1000: 0;

    /* verify if toke is expired */
    if(token !== "unauthenticated" && tknExp < Date.now()){
      /* fetch new user session */
      const session = await Auth.currentSession();
      /* check if session is valid */
      if(session && session.isValid()) {
        /* get current user info */
        user = await Auth.currentUserInfo();
        /* decode user */
        user = Helpers.decodeUser(user, session);
        /* set the new token for request */
        token = user.idToken;
        /* dispatch new user updated user */
        store.dispatch({
          type: "auth/setUser",
          payload: user
        });
        /* set profile pic if present */
        await UserClient.getAndCacheUserProfileImage();
      }else{
        /* logout user */
        await Auth.signOut({ global: true });
        /* dispatch new user updated user */
        store.dispatch({
          type: "auth/setUser",
          payload: null
        });
        Attention.notifyWarning(
          "Session Expired!",
          "Please provide your credentials to re-authenticate."
        );
      }
    }
    
    try{
      /* execute lamba authorizer endpoing */
      let {data}: any = await API.graphql({
        query: query,
        variables: variables,
        authToken: token
      });
      /* return data */
      return data;
    }catch(e:any){
      const error = e.errors && e.errors[0]? e.errors[0].message: e.toString();
      /* report error */
      if(!query.includes('FrontEndErrorReporter')){
        await GeneralClient.reportError(`${query}|${error}`, ErrorType.API, variables);
      }
      throw new Error(error)
    }
  }
  /* publish to topic helper */
  public static subscribe(topic:string, callback: any, 
                          error: any = (e:Error) => console.error(e)){
    if(!APIWrapper.subscriptions.has(topic)){
      const sub = PubSub.subscribe(topic).subscribe({
        next: (data) => callback(data.value),
        error: error
      });
      APIWrapper.subscriptions.set(topic, sub);
    }
  }
  /* publish to topic helper */
  public static unsubscribe(topic:string){

    if(APIWrapper.subscriptions.has(topic)){
      /* get the topic */
      const sub = APIWrapper.subscriptions.get(topic);
      /* unsubscribe */
      sub.unsubscribe();
      /* remove from collection */
      APIWrapper.subscriptions.delete(topic);
    }
  }
  /* publish to topic helper */
  public static unsubscribeAll(){
    /* unscribe from all topics */
    for(const topic of APIWrapper.subscriptions.keys()){
      /* get the topic */
      const sub = APIWrapper.subscriptions.get(topic);
      /* unsubscribe */
      sub.unsubscribe();
      /* remove from collection */
      APIWrapper.subscriptions.delete(topic);
    }
  }
  public static async connect(){
    let {isConnected, hasInternet} = store.getState().pubsub;
    
    if(!isConnected && hasInternet){
      APIWrapper.unsubscribe('init');
      APIWrapper.subscribe('init',()=>{});
    }
  }
  /* publish to topic helper */
  public static async publish(topic:string, data: any){
    let {isConnected} = store.getState().pubsub;
    let user = store.getState().auth.user as User;
    if(isConnected){
      await PubSub.publish(topic, data);
    }else{
      AnalyticsClient.record(AnalyticsEvent.pubsubNotSend, {
        topic,
        email:user.email,
        discord: user['custom:discord_user'],
        name: user.given_name,
        last_name: user.family_name
      })
      Attention.notifyWarning(
        "Message not send!",
        "You are currently disconnected from the system..."
      );
    }
  }
  /* publish to topic helper */
  public static async putFile(name: string, file:Blob, level: FILE_ACCESS_LEVEL, contentType: string, progressFn=()=>{}){
    return await Storage.put(name, file, {
      level: level,
      contentType: contentType,
      progressCallback: progressFn
    });
  }
  /* delete s3 directory */
  public static async deleteDirectory(name: string, level: FILE_ACCESS_LEVEL){
    let list = await Storage.list(name, {
      level: level,
      pageSize: 'ALL',
    })
    /* remove all files */
    let requests = [];
    for(let f of list.results){
      let p = Storage.remove(f.key as string, { level: level});
      requests.push(p);
    }
    return await Promise.all(requests);
  }
  /* delete s3 directory */
  public static async listDirectory(name: string, level: FILE_ACCESS_LEVEL){
    let list = await Storage.list(name, {
      pageSize: 'ALL',
      level: level,
    })
    /* remove all files */
    let files = [];
    for(let f of list.results){
      files.push(f.key as string);
    }
    return files;
  }
  /* publish to topic helper */
  public static async deleteFile(name: string, level: FILE_ACCESS_LEVEL){
    return await Storage.remove(name, {
      level: level
    });
  }
  /* publish to topic helper */
  public static async getFile(name: string, level: FILE_ACCESS_LEVEL, download=false, progressFn = ()=>{}){
    return await Storage.get(name,{
      level: level,
      progressCallback:progressFn,
      download: download,
      cacheControl: 'no-cache'
    });
  }

}