import React from 'react';
import { Layout, Tabs } from 'antd';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";

import menuKeys from '../../../media/animations/keys.json';
import menuUsers from '../../../media/animations/users.json';
import menuContent from '../../../media/animations/content.json';
import menuSettings from '../../../media/animations/settings.json';
/* local components */
import Access from './access/Access';
import Lottie from "lottie-react";
import AdminContent from './content/Index';

const { Content} = Layout;

const Admin = () => {
  
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selectedTab = pathname.split('/')[2] || 'content';

  /* navigate key */
  const onTabClick:any = (key: string)=>{
    navigate(key)
  }
  
  return (
          <Content style={{ paddingTop: 10}}>
            <Routes>
                <Route path="content/*" element={<span></span>}/>
                <Route path="users" element={<span></span>}/>
                <Route path="access" element={<span></span>}/>
                <Route path="settings" element={<span></span>}/>
                <Route path="*" element={<Navigate to="content" replace />} />
              </Routes>
            <Tabs activeKey={selectedTab} tabPosition={"left"} onTabClick={onTabClick}  destroyInactiveTabPane={true}
                style={{height:"100%"}}
              items={[
                {
                  label:(<span>
                        <Lottie loop={true} animationData={menuContent} style={{width:'90px', margin:'-14px'}}/>
                        Content
                      </span>),
                  key:"content",
                  children:<AdminContent/>
                },
                {
                  label:(<span>
                        <Lottie loop={true} animationData={menuUsers} style={{width:'80px', margin:'-10px'}}/>
                        Users
                      </span>),
                  key:"users",
                  children:<h1>users</h1>
                },
                {
                  label:(<span>
                          <Lottie loop={true} animationData={menuKeys} style={{width:'80px', margin:'-12px'}}/>
                          Access
                        </span>),
                  key:"access",
                  children: <Access/>
                },
                {
                  label:(<span>
                        <Lottie loop={true} animationData={menuSettings} style={{width:'80px', margin:'-8px'}}/>
                        Settings
                      </span>),
                  key:"settings",
                  children:<h1>settings</h1>
                },
              ]}/>
          </Content>);
};

/* component styles */
const styles = {
  logo:{
      width: '100%',
      marginBottom: 15,
      userSelect: 'none',
      pointerEvents: 'none'
  },
  row:{
      height: '100%',
      width: '100%'
  },
  col: {
      textAlign: 'center'
  }
};
export default Admin;