import { StripePaymentMethodList, PendingBankAccountIntent, StripeSubscription } from '../../models';
// Metadata, StripeCustomer, Address, StripePaymentMethod, 
import {API, graphqlOperation} from 'aws-amplify';
import wrapper from './wrapper';

export default class StripeClient {

  public static async read_payment_methods(): Promise<StripePaymentMethodList> {
    return new Promise(async (resolve, reject) => {
      try {
        const listPaymentMethods = `query {
          listPaymentMethods {
            paymentMethods {
              id
              country
              bank
              isDefault
              isExpired
              isVerified
              metadata
              number
              type
            }
          }
        }`;
    
        const result: any = await wrapper.grapql(listPaymentMethods);
        
        resolve(result.listPaymentMethods as StripePaymentMethodList);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public static async set_default_payment_method(paymentMethodId: string, ifNoneExists: boolean = false): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        
        const setDefaultPaymentMethod = `mutation ($paymentMethodId: String!, $ifNoneExists: Boolean!) {
          setDefaultPaymentMethod(paymentMethodId: $paymentMethodId, ifNoneExists: $ifNoneExists)
        }`;

        await wrapper.grapql(setDefaultPaymentMethod, {
          paymentMethodId, ifNoneExists
        });
        
        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public static async get_bank_account_setup_intent(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        
        const getBankAccountSetupIntent = `query {
          getBankAccountSetupIntent
        }
        `;

        const result: any = await wrapper.grapql(getBankAccountSetupIntent);
        resolve(result.getBankAccountSetupIntent as string);

      } catch (error) {
        console.log(error);
        reject(error);
      } 
    });
  }

  public static async get_card_setup_intent(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        
        const getCardSetupIntent = `query {
          getCardSetupIntent
        }
        `;
        const result: any = await wrapper.grapql(getCardSetupIntent);
        resolve(result.getCardSetupIntent as string);

      } catch (error) {
        console.log(error);
        reject(error);
      } 
    });
  }

  public static async add_card_to_customer(token: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {

        const addCardToCustomer = `mutation ($token: String!) {
          addCardToCustomer(token: $token)
        }`;
        
        // let result: any =  await API.graphql(graphqlOperation(
        //   addCardToCustomer,
        //   {customerId, token},
        //   authToken
        // ));

        const result: any = await wrapper.grapql(addCardToCustomer, {
          token
        });

        resolve(result.addCardToCustomer);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public static async read_pending_bank_accounts(): Promise<PendingBankAccountIntent[]> {
    return new Promise(async (resolve, reject) => {
      try {
        
        const listPendingBankAccounts = `query {
          listPendingBankAccounts {
            clientSecret
            paymentMethodId
            id
            status
            verificationUrl
            bank
            number
          }
        }`;

        const result: any = await wrapper.grapql(listPendingBankAccounts);
        resolve(result.listPendingBankAccounts as PendingBankAccountIntent[]);

      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public static async delete_payment_method(paymentMethodId: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        
        const deletePaymentMethod = `mutation ($paymentMethodId: String!) {
          deletePaymentMethod(paymentMethodId: $paymentMethodId)
        }`;
        
        // let result: any =  await API.graphql(graphqlOperation(
        //   deletePaymentMethod,
        //   {paymentMethodId},
        //   token
        // ));

        const result: any = await wrapper.grapql(deletePaymentMethod, {
          paymentMethodId
        });

        resolve(result.deletePaymentMethod as string);
        
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public static async read_customer_subscription(): Promise<StripeSubscription> {
    return new Promise(async (resolve, reject) => {
      try {
        
        const getCustomerSubscription = `query {
          getCustomerSubscription {
            cancel
            cancelled
            collectionMethod
            currentPeriodEnd
            currentPeriodStart
            customerId
            daysUntilDue
            id
            invoiceStatus
            isActive
            isTrial
            metadata
            paymentStatus
            priceId
            startDate
            status
            trialEnd
          }
        }`;

        // let result: any =  await API.graphql(graphqlOperation(
        //   getCustomerSubscription,
        //   {customerId},
        //   token
        // ));
        const result: any = await wrapper.grapql(getCustomerSubscription);

        resolve(result.getCustomerSubscription as StripeSubscription);

      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public static async create_premium_subscription(): Promise<string> {

      const createPremiumSubscription = `mutation {
        createPremiumSubscription
      }`;

      const result: any = await wrapper.grapql(createPremiumSubscription);
      return result.createPremiumSubscription as string;
  }

  public static async top_up_subscription(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        
        const topUpPremiumSubscription = `mutation {
          topUpPremiumSubscription
        }`;
        
        // let result: any =  await API.graphql(graphqlOperation(
        //   topUpPremiumSubscription,
        //   {customerId},
        //   token
        // ));
        const result: any = await wrapper.grapql(topUpPremiumSubscription);

        resolve(result.topUpPremiumSubscription as string);
        
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public static async cancel_subscription(reason: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        
        const cancelPremiumSubscription = `mutation($reason: String!) {
          cancelPremiumSubscription(reason: $reason)
        }`;
        
        const result: any = await wrapper.grapql(cancelPremiumSubscription, {
          reason
        });

        resolve(result.cancelPremiumSubscription as string);
        
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public static async update_customer(name: string, lastname: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        
        const updateCustomer = `mutation($name: String!, $lastname: String!) {
          updateCustomer(name: $name, lastname: $lastname)
        }`;
        
        await wrapper.grapql(updateCustomer, {
          name,
          lastname
        });

        resolve();
        
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public static async update_customer_email(email: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        
        const updateCustomerEmail = `mutation($email: String!) {
          updateCustomerEmail(email: $email)
        }`;
        
        await wrapper.grapql(updateCustomerEmail, {
          email
        });

        resolve();
        
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

}
