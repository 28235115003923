

import { Col, Row, Tag} from 'antd';
import visaLogo from '../../../../media/images/card-logo-visa.png';
import amexLogo from '../../../../media/images/card-logo-amex.png';
import dinersclubLogo from '../../../../media/images/card-logo-dinersclub.png';
import discoverLogo from '../../../../media/images/card-logo-discover.png';
import jcbLogo from '../../../../media/images/card-logo-jcb.png';
import mastercardLogo from '../../../../media/images/card-logo-mastercard.png';
import creditChip from '../../../../media/images/card-chip.png';
import logoSmall from '../../../../media/images/logo-small.png';
import stringHash from "@sindresorhus/string-hash";
import fontColorContrast from 'font-color-contrast'

type CreditCardProps ={
  type: string,
  country: string,
  number: string
}
/* the credit card colors */
const colors:string[] = [
  "#1B1819",
  "#297AB1",
  "#0798F2",
  "#EBECEE",
  "#1FA68D",
  "#F2B90F",
  "#FF6507",
  "#D2380C"
]

const CreditCard = ({type, number, country}:CreditCardProps) => {

  /* pick the card color */
  const bgColor = colors[stringHash(number) % colors.length];
  const fontColor = fontColorContrast(bgColor);

  const Cardstyles: React.CSSProperties = {
    width:200,
    height:130,
    color: fontColor,
    background: bgColor,
    borderRadius: 10,
    display: 'inline-block',
    boxShadow: "0 1px 5px 1px rgba(0,0,0,0.3)"
  }
  const CardLogoStyles: React.CSSProperties = {
    top: "0%",
    float: "right",
    width: "100%",

  }
  const CardChipStyles: React.CSSProperties = {
    top: "0%",
    float: "left",
    width: "80%",
  }

  const cardTypeStyles: React.CSSProperties = {
    transform: "rotate(-0deg)",
    fontSize: "12pt",
    fontWeight: "900",
    opacity: "20%"
  }

  let cardLogo = "";
  switch(type) {
    case "VISA":
      cardLogo = visaLogo;
      break
    case "MASTERCARD":
      cardLogo = mastercardLogo;
      break
    case "DISCOVER":
      cardLogo = discoverLogo;
      break
    case "AMEX":
      cardLogo = amexLogo;
      break
    case "JCB":
      cardLogo = jcbLogo;
      break
    case "DINERS":
      cardLogo = dinersclubLogo;
      break
    default:
      cardLogo = logoSmall;
  }

  return (
    <div style={Cardstyles} className={"noselect"}>
        <Row >
          <Col span={1}></Col>
          <Col span={6}><img style={CardChipStyles} className="noselect" src={creditChip}/></Col>
          <Col span={10}></Col>
          <Col span={6}><img style={CardLogoStyles} className="noselect" src={cardLogo}/></Col>
          <Col span={1}></Col>
        </Row>
        <Row style={{marginTop:"5px", fontFamily: "monospace"}}>
          <Col span={2}></Col>
          <Col span={4}>****</Col>
          <Col span={1}></Col>
          <Col span={4}>****</Col>
          <Col span={1}></Col>
          <Col span={4}>****</Col>
          <Col span={1}></Col>
          <Col span={4}>{number}</Col>
          <Col span={2}></Col>
        </Row>
        <Row style={{marginTop:"5px"}}>
          <Col span={2}></Col>
          <Col span={5}><Tag color={bgColor}><strong>{country}</strong></Tag></Col>

          <Col span={8} style={cardTypeStyles}>{type}</Col>
          <Col span={2}></Col>
          <Col span={5}></Col>
          <Col span={2}></Col>
        </Row>
    </div>
  )
}


export default CreditCard;