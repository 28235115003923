
import { Col, Row, Tag} from 'antd';
import visaLogo from '../../../../media/images/card-logo-visa.png';
import amexLogo from '../../../../media/images/card-logo-amex.png';
import dinersclubLogo from '../../../../media/images/card-logo-dinersclub.png';
import discoverLogo from '../../../../media/images/card-logo-discover.png';
import jcbLogo from '../../../../media/images/card-logo-jcb.png';
import mastercardLogo from '../../../../media/images/card-logo-mastercard.png';
import bankIcon from '../../../../media/images/bank.png';
import stringHash from "@sindresorhus/string-hash";

type creditCardProps ={
  type: string,
  country: string,
  number: string
}
/* the credit card colors */
const colors:string[] = [
  "#1B1819",
  "#297AB1",
  "#0798F2",
  "#EBECEE",
  "#1FA68D",
  "#F2B90F",
  "#FF6507",
  "#D2380C"
]

const BankAccount = ({type, number, country}:creditCardProps) => {

  /* pick the card color */
  const bgColor = colors[stringHash(number) % colors.length];

  const Cardstyles: React.CSSProperties = {
    width:200,
    height:120,
    padding: 4,
    color: 'black',
    background: "white",
    border: `solid 2px ${bgColor}`,
    borderRadius: 10,
    display: 'inline-block',
    boxShadow: `0 1px 5px 1px rgba(0,0,0,0.3)`
  }
  const bankIconStyles: React.CSSProperties = {
    top: "0%",
    float: "right",
    width: "100%",

  }


  const bankNametyles: React.CSSProperties = {
    margin: "10px",
    textAlign: "left",
    fontSize: "10pt",
    fontWeight: "900",
    opacity: "80%"
  }

  let cardLogo = "";
  switch(type) {
    case "VISA":
      cardLogo = visaLogo;
      break
    case "MASTERCARD":
      cardLogo = mastercardLogo;
      break
    case "DISCOVER":
      cardLogo = discoverLogo;
      break
    case "AMEX":
      cardLogo = amexLogo;
      break
    case "JCB":
      cardLogo = jcbLogo;
      break
    case "DINERS":
      cardLogo = dinersclubLogo;
      break
    default:
      cardLogo = "";
  }

  return (
    <div style={Cardstyles} className={"noselect"}>
        <Row >
          <Col span={1}></Col>
          <Col span={14} style={bankNametyles}>{type}</Col>
          <Col span={5}><img style={bankIconStyles} src={bankIcon}/></Col>
          <Col span={1}></Col>
        </Row>
        <Row style={{marginTop:"5px", fontFamily: "monospace"}}>
          <Col span={2}></Col>
          <Col span={20}></Col>
          <Col span={2}></Col>
        </Row>
        <Row style={{marginTop:"5px"}}>
          <Col span={2}></Col>
          <Col span={5}><Tag color={bgColor}><strong>{country}</strong></Tag></Col>
          <Col span={15}>********{number}</Col>
          <Col span={2}></Col>
        </Row>
    </div>
  )
}


export default BankAccount;