
import '@dotlottie/player-component';
import {
  useNavigate
} from "react-router-dom";
import { Col, Row, Card , Badge, Result} from 'antd';
import { Area } from '../../../../lib/utils/enums';
import { HubButton } from '../../misc/front/hubComponents';
import HubBadge from '../../../account/front/subscriptions/Badge';

const UpgradeToAccess = ({access}:any) => {

  const navigate = useNavigate();
  const {requiredRoles} = access;


  /* badge render function */
  const renderBadges = ()=>{
    let badges = [];
    for(let r of requiredRoles){
      badges.push(
          <Col key={r}>
            <Badge.Ribbon text={r.toUpperCase()}>
              <Card
                style={{
                  borderRadius: "20px",
                  boxShadow: "2px 2px lightgray",
                  overflow: "hidden"
                }}
                size="default"
                type="inner"
              >
                <HubBadge height={150} role={r} showRole={false}/>
              </Card>
            </Badge.Ribbon>
          </Col>
    )};
    return badges;
  }

  return (
    <div className="container">
      <div className="vertical-center">
        <Row justify='center' gutter={[20, 10]}>
            {renderBadges()}
        </Row>
        <Row>
          <Col span={24} style={{textAlign:'center'}}>
            <Result
              style={{margin:'-35px'}}
              icon={<span></span>}
              title="Membership Upgrade Required"
              subTitle="One of the above memberships is needed to access this area."
              extra={[
                <HubButton 
                  key={"upgrade"}
                  onClick={()=>{
                    navigate(`/${Area.ACCOUNT_SUBSCRIPTIONS}`);
                  }}>
                    <strong>Let's do it!</strong>
                </HubButton>
              ]}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}


export default UpgradeToAccess;