import { AnyAction, configureStore, Reducer } from '@reduxjs/toolkit'
/* import all reducers */
import auth from '../components/commons/auth/front/authSlice';
import misc from '../components/commons/misc/front/miscSlice';
import pubsub from '../components/commons/pubsub/front/pubSubSlice';
import { createRouterReducer, createRouterMiddleware, ReduxRouterState } from '@lagunovsky/redux-react-router'
import { createBrowserHistory } from 'history';
const history = createBrowserHistory()

export const store = configureStore({
  reducer: {
    auth,
    misc,
    pubsub,
    router: createRouterReducer(history) as Reducer<ReduxRouterState, AnyAction>
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    createRouterMiddleware(history),
  ],
  devTools: true
})

export { history }
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch