import {store} from '../../../../app/store';
import { AccessMapping, AccessType } from "../../../../models";
import UpgradeToAccess from "./UpgradeToAccess";
import Restricted from "./Restricted";
import Maintenance from "./Maintenance";
import Closed from "./Closed";
import { Badge } from 'antd';

export const Gateway = (Component: any, area: string, onlyRoot=false) => {

  /* return the wrapper */
  return (props:any = {}): any =>{
    
    /* get required properties */ 
    const access = store.getState().auth.access as AccessMapping;
    let route = store.getState().router.location.pathname.slice(1);
    
    /* if only root, trim and take into consideration upper path */
    if(onlyRoot){
      route = route.split('/')[0].replace('/','');
    }
    /* retrieve record access */
    const record = access[route];
    /* if this route is missing, throw exception */
    if(!record){
      throw new Error(`Not access defined for ${route} route...`);
    }

    /* build props object along with access */
    const p = {...props, access: record}
    /* if full access go ahead */
    if(area != route || record.type == AccessType.FULL || record.type == AccessType.PREVIEW){
      /* if there is access, push original component */
      return <Component {...p} />
    }else if (record.type == AccessType.NONE && record.requiredRoles.length == 0){
      return <Restricted {...p} />
    }else if (record.type == AccessType.NONE){
      return <UpgradeToAccess {...p} />
    }else if (record.type == AccessType.MAINTENANCE){
      return <Maintenance {...p} />
    }else if (record.type == AccessType.DISABLED){
      return <Closed {...p} />
    }

  }
}

/* helper function to wrap component */
const wrapComponentWithIcon = (desc:string[], component:any)=>{

  if('icon' in component){
    let tmp = component.icon;
    let [color, letter] = desc;
    component.icon = <Badge.Ribbon text={letter} color={color}>
      <div>
        {tmp}
      </div>
  </Badge.Ribbon>
  }

  return component; 
}

export const MenuGateway = (pathname:string, component: any) => {

  /* get required properties */
  const access = store.getState().auth.access as AccessMapping;
  /* retrieve record access */
  const record = access[pathname];
  /* if this route is missing, throw exception */
  if(!record){
    throw new Error(`Not access defined for ${pathname} provided menu...`);
  }
  /* if full access go ahead */
  if(record.type == AccessType.FULL || record.type == AccessType.PREVIEW){
    /* if there is access, push original component */
    return component;
  }else if (record.type == AccessType.DISABLED){
    return null;
  }else if (record.type == AccessType.NONE && record.requiredRoles.length == 0){
    return null;
  }else if (record.type == AccessType.NONE){
    return component;
  }else if (record.type == AccessType.MAINTENANCE){
    return component;
  }
}