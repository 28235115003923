import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col } from 'antd';
import {Gateway} from '../../commons/gateway/front/Gateway';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { User } from '../../../models';
import { MarketOverview , MarketOverviewTab, CopyrightStyles, EconomicCalendar} from "react-ts-tradingview-widgets";
import { HubButton, HubIcon } from '../../commons/misc/front/hubComponents';
import "./index.css"
import { Area } from '../../../lib/utils/enums';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { setIsLoading } from '../../commons/misc/front/miscSlice';
import { toCanvas } from 'html-to-image';
import { isMobile } from 'react-device-detect';

const futuresTab: MarketOverviewTab = {
  title:"Futures",
  originalTitle: "Futures",
  symbols: [
    {s:"ES1!", d:"S&P"},
    {s:"NQ1!", d:"NASDAQ"},
    {s:"YM1!", d:"DOW"},
    {s:"RTY1!", d:"RUSSEL"},
    {s:"2YY1!", d:"2Y YIELD"}
  ]
}
const indicesTab: MarketOverviewTab = {
  title:"Indices",
  originalTitle: "Indices",
  symbols: [
    {s:"SPY", d:"SPY"},
    {s:"QQQ", d:"QQQ"},
    {s:"DIA", d:"DIA"},
    {s:"VIX", d:"VIX"},
    {s:"BTCUSD", d:"BITCOIN"}
  ]
}

const Home: React.FC = () => {

    const dispatch = useAppDispatch()
    const [dheight, setDHeight] = useState(100);
    const [dwidth, setDWidth] = useState(100);
    const div = useRef<null| HTMLDivElement>(null);
    

    useEffect( () => {
        dispatch(setIsLoading(true));
        setTimeout(()=>{
          if(div.current){
            setDHeight(div.current.clientHeight);
            setDWidth(div.current.clientWidth);
          }
          dispatch(setIsLoading(false));
        },500);
    }, [div]);

    /* styles for offering division */
    const offeringStyles:React.CSSProperties  = {
      boxShadow: "0 1px 3px 1px rgba(0,0,0,0.3)",
      textAlign: "center",
      background: "white",
      border:`1px solid #FF8642`,
      borderRadius: 8, 
      padding: 6
    };
    const copyringhtStyles:CopyrightStyles = {
      span:{
        color: "#FF8642"
      }
    }
    const cameraIconStyles:React.CSSProperties = {
      position: "absolute",
      right: 12
    }

    const takeScreenshot = async ()=>{
      const element = document.querySelector("#futures");
      const canvas = await toCanvas(element as any);
      canvas.toBlob(async (blob:any) => {
        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob
          })]);
          console.log("in clipboard");
      }, 'image/png');
    }

    /* markets widgets dimensions */
    return <React.Fragment> 
      <Row style={{paddingTop: 20, height:"80%"}} gutter={[4, 4]} ref={div}>
        <Col>
          <div style={{...offeringStyles, height:'100%'}} id="calendar">
                  <HubButton size='middle'
                              type="ghost"
                              icon={<HubIcon/>}><strong>CALENDAR</strong></HubButton>
                  <EconomicCalendar key={"calendar"}
                                  height={dheight}
                                  width={isMobile?dwidth:Math.floor(dwidth/1.5)}
                                  isTransparent={true}
                                  importanceFilter={"0,1"}
                                  currencyFilter={"CAD,EUR,JPY,USD"}
                                  copyrightStyles={copyringhtStyles}/>
            </div>
        </Col>
        <Col>
          <Row>
            <Col>
              <div style={{...offeringStyles}} id="futures">
              <HubButton size='middle'
                          type="ghost"
                          icon={<HubIcon/>}><strong>INDICES</strong></HubButton>
              <MarketOverview 
                              key={"overview-indices"}
                              height={Math.floor(dheight/2)}
                              width={isMobile?dwidth:Math.floor(dwidth/3.5)}
                              tabs={[indicesTab]} 
                              showFloatingTooltip 
                              showChart={false} 
                              isTransparent={true}
                              copyrightStyles={copyringhtStyles}/>
            </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{...offeringStyles, height:'100%'}} id="futures">
              <HubButton size='middle'
                          type="ghost"
                          icon={<HubIcon/>}><strong>FUTURES</strong></HubButton>
              {/* <HubButton size='middle' 
                          type="ghost"
                          style={cameraIconStyles}
                          onClick={takeScreenshot}>
                      <CameraOutlined />
              </HubButton> */}
              <MarketOverview 
                              key={"overview-futures"}
                              height={Math.floor(dheight/2)}
                              width={isMobile?dwidth:Math.floor(dwidth/3.5)}
                              tabs={[futuresTab]} 
                              showFloatingTooltip 
                              showChart={false} 
                              isTransparent={true}
                              copyrightStyles={copyringhtStyles}/>
              </div>
            </Col>
          </Row>
        </Col>

      </Row>

    </React.Fragment>

};


export default Gateway(Home, Area.HOME);