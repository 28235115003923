
import Lottie from "lottie-react";
import {
  useNavigate
} from "react-router-dom";
import maintenance from '../../../../media/animations/maintenance.json';
import { Col, Row, Result, Button} from 'antd';
import { HubButton } from "../../misc/front/hubComponents";

const Maintenance = ({access}:any) => {

  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="vertical-center">
        <Row>
          <Col span={24} style={{textAlign:'center'}}>
            <Result
              style={{margin:'-35px'}}
              icon={<Lottie loop={true} animationData={maintenance} style={{height:'300px'}}/>}
              title="Under Maintenance"
              subTitle="Please comeback later when we finish doing some work."
              extra={[
                <HubButton 
                  key={"maintenance"}
                  onClick={()=>{
                    navigate(-1);
                  }}>
                    <strong>Go Back</strong>
                </HubButton>
              ]}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}


export default Maintenance;