import { Select, Table, Tag, Row, Col, PageHeader,Button,Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { SaveOutlined } from '@ant-design/icons';
import React, {useEffect,useState} from 'react';
import stc from "string-to-color";
import { AccessClient } from '../../../../lib/api';
import { AccessEntry } from '../../../../models';
import {Area, Role, AreaEndpointMap} from '../../../../lib/utils/enums';
import {Attention} from '../../../../lib/utils/notification';
import {block} from '../../../commons//misc/front/miscSlice';
import {useAppDispatch } from '../../../../app/hooks';
import {Gateway} from '../../../commons/gateway/front/Gateway';
import {ImSwitch} from 'react-icons/im';
import {BiWrench} from 'react-icons/bi';
import {VscOpenPreview} from 'react-icons/vsc'
import Pub from '../../../commons/pubsub/front/publications';
import { HubButton, HubPopConfirm } from '../../../commons/misc/front/hubComponents';

/* color for roles */
const roleOptions: any = Object.values(Role).map((r:string)=>{
  return {label: r, value: stc(r).toUpperCase()};
});
const roleColorMap: any = {};
roleOptions.forEach((ro:any)=>{
  roleColorMap[ro.label] = ro.value;
});

interface AreaDataType {
  key: string;
  name: string;
  roles: string[];
  enabled: boolean;
  hasPreview: boolean;
  underMainteinance: boolean;
  persisted: boolean;
}


const tagRender = (props: CustomTagProps) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};



/* map local and remote areas */
const mapLocalAndRemoteAreas = (rAreas: AccessEntry[] = []): AreaDataType[] =>{

  /* the resulting areas */
  const areas: AreaDataType[] = [];
  /* the local areas */
  const lAreas: string[] = Object.values(Area).filter((a=>{
    let endpoints = AreaEndpointMap.get(a);
    return endpoints && endpoints.size > 0;
  }));

  /* fill up area map */
  let areaMap = new Map<string, AccessEntry>();
  for(let e of rAreas){
    areaMap.set(e.area, e);
  }

  /* for each area value */
  for(let la of lAreas)
  {
    let e = areaMap.get(la);
    /* the area object */
    let a: AreaDataType = {
      enabled: e? e.enabled: false,
      hasPreview: e? e.hasPreview: false,
      key: la,
      name: la.toUpperCase(),
      persisted: e?true: false,
      roles: e? e.roles.split('|'): [],
      underMainteinance: e? e.underMainteinance: false,
    }
    areas.push(a);
  }
  /* return all areas */
  return areas;
}

const Access = () => {

  /* the areas and roles */
  const [areas, setAreas] = useState<AreaDataType[]>([]);
  const dispatch = useAppDispatch();

  /* first fetch access table */
  useEffect(() => {
    (async ()=>{
      try{
        let entries = await AccessClient.getAccessEntries();
        let areas = mapLocalAndRemoteAreas(entries);
        /* set areas */
        setAreas(areas);

      }catch(e:any){
        Attention.notifyError("Could not load areas from remote.", e.message);
      }
    })()
  }, []);

  /* columns definition */
  const areaColumns: ColumnsType<AreaDataType> = [
    {
      title: 'Area',
      dataIndex: 'name',
      key: 'key',
      sorter: (a, b) => a.key.localeCompare(b.key),
      filterSearch: true,
      onFilter: (value: any, record) => record.key.includes(value),
      render: text => <a>{text.replaceAll('/',' ► ')}</a>,
    },
    {
      title: <ImSwitch size={'24px'}/>,
      dataIndex: 'enabled',
      key: 'enabled',
      render: (_, { key, enabled }) => {
        return  <Switch checked={enabled} onChange={(checked:boolean)=>{
          let nAreas = areas.map((a)=>{
              if(a.key == key){
                return {
                  ...a,
                  enabled: checked
                }
              }
              return a;
          }); 
          setAreas(nAreas);
        }}/>;
      }
    },
    {
      title: <VscOpenPreview size={'24px'}/>,
      dataIndex: 'hasPreview',
      key: 'hasPreview',
      render: (_, { key, hasPreview }) => {
        return  <Switch checked={hasPreview} onChange={(checked:boolean)=>{
          let nAreas = areas.map((a)=>{
            if(a.key == key){
              return {
                ...a,
                hasPreview: checked
              }
            }
            return a;
        }); 
        setAreas(nAreas);
        }}/>;
      }
    },
    {
      title: <BiWrench size={'24px'}/>,
      dataIndex: 'underMainteinance',
      key: 'underMainteinance',
      render: (_, { key , underMainteinance}) => {
        return  <Switch checked={underMainteinance}  onChange={(checked:boolean)=>{
          let nAreas = areas.map((a)=>{
            if(a.key == key){
              return {
                ...a,
                underMainteinance: checked
              }
            }
            return a;
        }); 
        setAreas(nAreas);
        }}/>;
      }
    },
    {
      title: 'Roles',
      key: 'roles',
      dataIndex: 'roles',
      render: (_, { key, roles }) => {
        let vals : any = roles.map((r:string)=>{return {label: r, value: stc(r).toUpperCase()}});
        return (
          <Select
            mode="multiple"
            showArrow
            tagRender={tagRender}
            defaultValue={vals}
            style={{ width: '100%' }}
            options={roleOptions}
            onChange={( _, option:any)=>{
              let nAreas = areas.map((a)=>{
                if(a.key == key){
                  return {
                    ...a,
                    roles: option.map((v:any)=>v.label)
                  }
                }
                return a;
            }); 
            setAreas(nAreas);
            }}
  />)},}];

  /* persist entries client */
  const persistEntries = async ()=>{
    const entries:AccessEntry[] = [];
    /* build access entries */
    for(let a of areas){
      /* create area template */
      let tplt:AccessEntry = {
        area: a.key,
        queryName: "",
        enabled: a.enabled,
        hasPreview: a.hasPreview,
        underMainteinance: a.underMainteinance,
        roles: a.roles.join("|"),
      }
      /* if one role is missing, abort */
      if(tplt.roles.length == 0){
        Attention.notifyError(
          "Roles missing",
          "All areas must have at least 1 role assigned."
        );
        return;
      }
      /* get the map of endpoints */
      let endpoints = AreaEndpointMap.get(tplt.area);
      if(!endpoints || endpoints.size == 0){
        continue;
      }
      /* proceed to create table entries */
      for(let e of endpoints){
        /* add to entry collection */
        entries.push(
          {...tplt,
            queryName: e
          });
      }
    }
    try{
      /* execute insert */
      await block(async()=>{
        return await AccessClient.putAccessEntries(entries);
      });
      /* send access map refresh message to everyone */
      setTimeout(() => {
        Pub.sendRefreshAccessMsg();
      }, 1000);
      /* notify success */
      Attention.notifySuccess("Access setup success!", "Records where successfully persisted.")
    }catch(e:any){
      /* notify success */
      Attention.notifyError("Operation failed!", e.message);
    }
  }

  /* role options */
  return (
    <React.Fragment>
      <br/>
      <Row style={{textAlign:"center"}}>
          <Col span={20}>
            <PageHeader
              onBack={() => null}
              ghost={true}
              title="Platform Access"
            />
          </Col>
          <Col span={4}>
          <HubPopConfirm
            position={"left"}
            title="Are you sure you want to save access scheme?"
            onConfirm={persistEntries}
            okText="Yes"
            cancelText="No">
              <HubButton 
              icon={<SaveOutlined />} 
              >
                Save
            </HubButton>
        </HubPopConfirm>

          </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table 
            loading={areas.length == 0}
            columns={areaColumns} 
            dataSource={areas}
            pagination={{ pageSize: 8 }}
            />
        </Col>
      </Row>
    </React.Fragment>
  );

}

export default Gateway(Access, Area.ADMIN_ACCESS);