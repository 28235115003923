import {AccessEntry} from '../../models'
import wrapper from './wrapper';

export default class AccessClient {

  /* get access based on area and user */
  public static async getAccessMap(): Promise<any> {

    /* access list query */
    const query = `
      query {
        getAccessMap {
          area
          requiredRoles
          type
        }
    }`;
    /* execute with query with map */
    let { getAccessMap }: any = await wrapper.grapql(query);
    /* if there is data */
    if (getAccessMap) {
      const mp: any = {};
      getAccessMap.forEach((e:any) => {
        mp[e.area] = e;
      });
      return mp;
    } else {
      throw Error("Could not retrieve access map!");
    }
  }

  /* get access based on area and user */
  public static async getAccessEntries(): Promise<AccessEntry[]> {
 
    /* access list query */
    const query = `query {
      getAccessEntries {
        entries {
          area
          hasPreview
          enabled
          queryName
          roles
          underMainteinance
        }
      }
    }`;
    /* execute with query with map */
    let {getAccessEntries}:any = await wrapper.grapql(query, {});

    /* if there is data */
    if (getAccessEntries) {
      return getAccessEntries.entries;
    } else {
      throw Error("Could not retrieve entries!");
    }
  }

  /* get access based on area and user */
  public static async putAccessEntries(entries:AccessEntry[]): Promise<any> {

    /* access list query */
    const mutation = `mutation ($entries: [AccessEntryInput]) {
      putAccessEntries(entries: $entries)
    }`;
    try{
      /* max batch size insert */
      const size = 25;
      /* insert each 25 entries */
      while (entries.length > 0){
        let items = entries.splice(0, size);
        /* execute with query with map */
        let {error}:any = await wrapper.grapql(mutation,{entries: items});
        /* if there is data */
        if (error) {
          throw Error("Could not write access entries");
        }
      }
      return true;
    }catch(e:any){
      throw Error(e.errors[0].message);
    }

  }


  public static async getSupportedQueries(): Promise<any> {
    /* access list query */
    const query = `
      query getSupportedQueries {__schema { queryType { fields { name }}}}`;

    /* execute with query with map */
    let data: any = await wrapper.grapql(query);

    /* if query returned correctly */
    if ("__schema" in data) {
      /* get supported queries*/
      let {queryType: {fields}} = data["__schema"];
      return fields.map((e:any)=> e.name);
    } else {
      throw Error("Could not retrieve supported Queries!");
    }
  }
  public static async getSupportedMutations(): Promise<any> {
    /* access list query */
    const getSupportedMutations = `
      query getSupportedMutations {__schema { mutationType {fields { name }}}}`;
    /* execute lamba authorizer endpoing */
    let data: any = await wrapper.grapql(getSupportedMutations);
    /* if query returned correctly */
    if ("__schema" in data) {
      /* get supported queries*/
      let {mutationType: {fields}} = data["__schema"];
      return fields.map((e:any)=> e.name);
    } else {
      throw Error("Could not retrieve supported Mutations!");
    }
  }

}