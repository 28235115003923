import React from 'react';
import { Row, Col, Badge, Tag, Spin, Tooltip } from 'antd';
import { useAppSelector } from '../../../../app/hooks';
import { ConnectionState } from '@aws-amplify/pubsub';
import {MdSignalWifi4Bar, MdPermScanWifi, MdSignalWifiOff, MdOutlineSignalWifiBad} from 'react-icons/md';

const ConnectionUI: React.FC = () => {

    const {status} = useAppSelector((state) => state.pubsub);

    /* color connection status map */
    const ColorMap = {
      [ConnectionState.Connected]: "green",
      [ConnectionState.Connecting]: "yellow",
      [ConnectionState.Disconnected]: "red"
    } as any;
    /* the color */
    let color = ColorMap[status] || "yellow";
    let Icon = MdOutlineSignalWifiBad;
    switch(status){
      case ConnectionState.Connected:
        Icon = MdSignalWifi4Bar;
        break;
      case ConnectionState.Connecting:
        Icon = MdPermScanWifi;
        break;
      case ConnectionState.Disconnected:
        Icon = MdSignalWifiOff;
        break;
    }
    let statusText = (status !== ConnectionState.Connected &&
                      status !== ConnectionState.Connecting &&
                      status !== ConnectionState.Disconnected)? ConnectionState.Connecting: status;
    /* show status */
    return <React.Fragment> 

    <Row style={{textAlign:"center"}}>
      <Col span={24} style={{textAlign:"center"}}>
      <Tooltip title={statusText} color={color} key={color} placement={"right"}>
        <Badge color={color} offset={[-8,0]} style={{position:"initial"}} text={
          <Tag color={color}>
            <Spin size="small"
              style={{marginLeft:"5px"}}
              spinning={status == ConnectionState.Connecting} >
                <Icon style={{verticalAlign:"middle"}}/>
            </Spin>
          </Tag>
        } />
        </Tooltip>
      </Col>
    </Row>
    <br></br>
  </React.Fragment>

};


export default ConnectionUI;