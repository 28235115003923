import APIWrapper from "../../../../lib/api/wrapper";
import { Modal } from 'antd';
import {TopicBase, pfx, 
      SystemMsgBase, DirectMsgBase, 
      DirectMsgType, SystemMsgType, 
      DirectTokenRefresedMsg,
      SystemRefreshAccessMsg,
      DirectMembershipUpgradeddMsg,
      DirectMembershipDowngradeddMsg} from "./pubsubEnums";
import stringHash from '@sindresorhus/string-hash';
import { store } from '../../../../app/store';
import { User } from "../../../../models";
import { Helpers } from "../../../../lib/utils";
import SingleSessionMessage from "../../misc/front/singleSessionMessage";
import { AccessClient } from "../../../../lib/api";
import { Attention } from "../../../../lib/utils/notification";
import AnalyticsClient, { AnalyticsEvent } from "../../../../lib/api/analytics_client";
export default class Sub {
  /* the subscriptions set */
  public static init(): any {

    /* get user and access */
    const {user} = store.getState().auth;
    
    /* subscribe to system messages */
    APIWrapper.subscribe(`${pfx}${TopicBase.SYSTEM}`, async (msg:SystemMsgBase)=>{
      /* log system message */
      switch(msg.type){
        case SystemMsgType.REFRESH_ACCESS:
          let accessMap = await AccessClient.getAccessMap();
          /* dispatch new access map */
          store.dispatch({
            type: "auth/setAccess",
            payload: accessMap
          });
          Attention.notifyInfo(
            "Areas Updated",
            "You may encounter some closed, openned, or under maintenance areas.")
        break;  
      }
    });
    /* direct user token refreshed message */
    APIWrapper.subscribe(`${pfx}${TopicBase.DIRECT}/${user?.sub}`, (msg:DirectMsgBase)=>{
      /* the message to parse */
      let m;
      /* log user message */
      switch(msg.type){
        case DirectMsgType.TOKEN_REFRESHED:
          let user = store.getState().auth.user as User;
          m = msg as DirectTokenRefresedMsg;
          /* if token is not the same, kickout user */
          if(m.hash !== stringHash(user.refreshToken)){
            /* dual session track */
            AnalyticsClient.record(AnalyticsEvent.dualSession,{
              discord: user["custom:discord_user"],
              email: user.email,
              name: user.given_name,
              lastName: user.family_name
            });
            /* logout user */
            Helpers.logout();
            /* show warning message */
            Modal.warning({
              content: SingleSessionMessage(),
              icon: null,
              centered: true,
              onOk: ()=>{},
              okButtonProps:{shape:'round'},
              okText:'Got it'
            })
          }
          break;
        case DirectMsgType.MEMBERSHIP_UPGRADED:
          m = msg as DirectMembershipUpgradeddMsg;
          Attention.notifyInfo("Membership Upgraded 🎉",
          `Your membership has been upgraded to ${m.membership}!`);
          Helpers.refreshUser();
          break;
        case DirectMsgType.MEMBERSHIP_DOWNGRADED:
          m = msg as DirectMembershipDowngradeddMsg;
          Attention.notifyInfo("Membership Downgraded 😔",
          `Your membership has been downgraded from ${m.membership}!`);
          Helpers.refreshUser();
          break;
      }
    });

  }
}