import React, {CSSProperties, useRef, useState} from 'react';
import { MenuProps,
  Layout, Menu, Tooltip } from 'antd';
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';
import {useAppDispatch, useAppSelector } from '../../../app/hooks';
import menuProfile from '../../../media/animations/profile.json';
import menuBooks from '../../../media/animations/books.json';
import menuResources from '../../../media/animations/resources.json';
import menuControl from '../../../media/animations/control.json';
import menuLogout from '../../../media/animations/logout.json';
import menuHome from '../../../media/animations/home.json';
import Lottie from "lottie-react";
import { animated, useSpring } from '@react-spring/web'
/* import sub components*/
import Account from '../../account/front/Account';
import Home from '../../home/front/Home';
import Courses from '../../course/front/Index';
import Education from '../../education/front/Index';
import Admin from '../../admin/front/Admin';
import ConnectionUI from '../../../components/commons/pubsub/front/ConnectionUI';

/* access library */
import { Area, systemDefaults } from '../../../lib/utils/enums';
import { MenuGateway } from '../../commons/gateway/front/Gateway';
import { Helpers } from '../../../lib/utils';
import { setIsUserIdle } from '../../commons/pubsub/front/pubSubSlice';
import ProfilePic from '../../account/front/profile/profilePic';
import { HubButton, HubPopConfirm } from '../../commons/misc/front/hubComponents';
import { Attention } from '../../../lib/utils/notification';
import ReloadModal from './ReloadModal';
import AnalyticsClient, { AnalyticsEvent } from '../../../lib/api/analytics_client';
import { isMobile } from 'react-device-detect';
import JoinDiscordModal from '../../commons/auth/front/JoinDiscordModal';
import { DiscordPopupRedirect } from '../../commons/auth/front';
import { DiscordIcon } from '../../commons/auth/front/discord-identity';

type MenuItem = Required<MenuProps>['items'][number];
const { Header, Content, Footer, Sider } = Layout;
const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

/* menu items */
const HomeItem:MenuItem = getItem('Home', Area.HOME, <Lottie loop={true} animationData={menuHome} style={{width:'36px', marginLeft:'-8px'}}/>);
const EducationItem:MenuItem = getItem('Education', Area.EDUCATION,<Lottie loop={true} animationData={menuBooks} style={{width:'36px', marginLeft:'-8px'}}/>);
const CoursesItem:MenuItem = getItem('Courses', Area.COURSES,<Lottie loop={true} animationData={menuResources} style={{width:'44px', marginLeft:'-16px'}}/>);
const AccountItem:MenuItem = getItem('Account', Area.ACCOUNT,<Lottie loop={true} animationData={menuProfile} style={{width:'36px', marginLeft:'-8px'}}/>);
const CommunityItem:MenuItem = getItem('Community', Area.COMMUNITY,<DiscordIcon style={{width:'36px', marginLeft:'-4px', marginRight:'-4px'}}/>);
const AdminItem:MenuItem = getItem('Admin', Area.ADMIN,<Lottie loop={true} animationData={menuControl} style={{width:'64px', margin:'-12px', marginLeft:'-25px'}}/>)


const Main = () => {

  const user = useAppSelector((state) => state.auth.user);
  const [collapsed, setCollapsed] = useState<any>(JSON.parse(localStorage.getItem('menu-open')|| 'false'));
  const [layoutMobile, setLayoutMobile] = useState<boolean>(false);
  const idleTime = useRef<Date>(new Date());
  const [idleHours, setIdleHours] = useState<number>(0);
  const [showReloadModal, setShowReloadModal] = useState<boolean>(false);
  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 }, config: {duration: 500} });
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  /* on idle event */
  const onIdle = () => {
    let time = new Date();
    console.log(time.toString(), "User is now idle...");
    idleTime.current = time;
    dispatch(setIsUserIdle(true));
  }
  /* on active event */
  const onActive = async () => {

    /* if show modal is on, dont process this */
    if(showReloadModal)return;

    /* compute time difference */
    let time = new Date();
    let diffMs = (time.getTime() - idleTime.current.getTime());
    let diffSecs = Math.floor((diffMs/1000) % 60);
    let diffMins = Math.floor((diffMs/1000/60) << 0);
    let diffHrs = Math.floor(diffMins/60);

    /* set the timer status */
    idleTime.current = time;
    // console.log("User is now awake...", `${diffHrs}hr, ${diffMins}min, ${diffSecs}secs`, " difference", time.toString());
    /* if reload needs to be done */
    if(diffHrs >= parseInt(process.env.REACT_APP_RELOAD_IDLE_LIMIT as string)){
      setIdleHours(diffHrs);
      setShowReloadModal(true);
    /* if reconnect and refresh token needs to be done */
    }else if(diffHrs >= parseInt(process.env.REACT_APP_RECONNECT_IDLE_LIMIT as string)){
      /* quietly refresh user */
      Helpers.refreshUser();
    }
    /* remove idle status */
    dispatch(setIsUserIdle(false));
  }

  const onAction = (event:any) => {
    // Do something when a user triggers a watched event
    onActive();
  }

  /* the idle timer */
  const idleTimer = useIdleTimer({ onIdle, onActive, onAction, events:['visibilitychange', 'mousedown'] });

  /* decode the selected menu */
  let selectedMenu = pathname.split('/')[1] || 'home';

  const onLogout = async () =>{ 
    try{
      await Helpers.logout();
      navigate('/');
      AnalyticsClient.record(AnalyticsEvent.logout);
      Attention.notifySuccess("Successfully logged out!");
    }catch(e:any){
      Attention.notifyError("Could not logout!", e.message);
    }
  }

  const menuSelected:any = ({key}:any)=>{
    switch(key){
      case Area.COMMUNITY:
        window.open(process.env.REACT_APP_COMMUNITY_HOME, "_blank");
        break;
      default:
        navigate(key);
    }
    if(isMobile){
      setCollapsed(true);
    }
  }
  const onMenuCollapse = () =>{
    localStorage.setItem('menu-open', JSON.stringify(!collapsed));
    setCollapsed(!collapsed);
    AnalyticsClient.record(!collapsed?AnalyticsEvent.expandedSidePanel:AnalyticsEvent.collapseSidePanel);
  }

  /* check if admin should be pressent */
  let evalItems:any[][] = [
      [Area.HOME, HomeItem], 
      [Area.EDUCATION, EducationItem],
      [Area.COURSES, CoursesItem],
      [Area.ACCOUNT, AccountItem],
      [Area.COMMUNITY, CommunityItem]
    ];
  let menuItems = [];
  /* for each menu item iterate */
  for(let [area, item] of evalItems){
    let res = MenuGateway(area, item);
    if(res){
      menuItems.push(res);
    }
  }
  /* set admin manually */
  let adminItem = MenuGateway(Area.ADMIN, AdminItem);
  let admin = <React.Fragment></React.Fragment>;
  if(adminItem){
    /* add component */
    admin = <Route path="admin/*" element={<Admin/>}/>
    /* add menu item */
    menuItems.push(adminItem);
  }

  const siderMobileLayout: CSSProperties ={
    position: 'absolute',
    height: '100%',
    zIndex: '1000'
  }

  return (
    /* render a row with elements align in the middle of container */
    // @ts-ignore
    <animated.div style={props}>

      <Layout style={{ minHeight: '100vh'}}>
        <Sider style={layoutMobile?siderMobileLayout:{}}
              collapsed={collapsed} 
              onCollapse={onMenuCollapse} 
              collapsedWidth="0" 
              breakpoint="lg" theme="dark" 
              onBreakpoint={setLayoutMobile}>
          <div style={{width:"100%", marginTop:20, marginBottom: -20}}>
            <ProfilePic disabled={true} size={collapsed?55:100}/>
          </div>
          
          {/* Connection UI component */}
          <ConnectionUI/>
          {/* The selectable UI */}
          <Menu theme="dark" style={{fontWeight:'500'}} onSelect={menuSelected} selectedKeys={[selectedMenu]} mode="inline" items={menuItems} />
          <div style={{width:'100%', textAlign:"center", marginTop:"25%"}}>
              <HubPopConfirm
              title={<div>Ready to logout?<br/>When confirmed, you will need to authenticate again.</div>}
              onConfirm={onLogout}
              okText="Yes"
              cancelText="No">
                <Tooltip placement="right" title={'Logout'}>
                  <HubButton ><Lottie loop={true} animationData={menuLogout} style={{width:'25px'}}/></HubButton>
                </Tooltip>
              </HubPopConfirm>
          </div>
          
        </Sider>
        <Layout>
            {/* <Header >
                
            </Header> */}
            <Content style={{ margin: '0 16px', height: '90%' }}>
                  {/* the routes */}
                  <Routes>
                    <Route path="home" element={<Home/>}/>
                    <Route path="account/*" element={<Account/>}/>
                    <Route path="education/*" element={<Education/>}/>
                    <Route path="courses/*" element={<Courses/>}/>
                    <Route path="discord/redirect" element={<DiscordPopupRedirect/>} />
                    {admin}
                    {/* <Route path="journal" element={<Journal/>}/> */}
                    <Route path="*" element={<Navigate to="/home" replace />} />
                  </Routes>
            </Content>
            <Tooltip title={<div style={{color:"#FF8642"}}>Invierte en tu futuro ☂️</div>} color="white">
             {/* TODO: Move this somewhere else */}
              <div style={{ right: '3%', position:'absolute', bottom: '3%', display: 'none' }}>
                   {/* @ts-ignore*/}
                    RainHub, LLC ©{/\d{4}/.exec(Date())[0]}
              </div>
            </Tooltip>
        </Layout>
      </Layout>
      <ReloadModal isOpen={showReloadModal} idleHours={idleHours}/>
      <JoinDiscordModal isOpen={user?.['custom:discord_user'] == systemDefaults.emptyDynamoString} />
    </animated.div>)

};

export default Main;