import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConnectionState } from '@aws-amplify/pubsub';

/* define state */
interface PubSubState {
  status: string,
  isUserIdle: boolean,
  isConnected: boolean,
  hasInternet: boolean,
  statusTime: number
}
/* create initial state object */
const initialState: PubSubState = {
  status: ConnectionState.Disconnected,
  isUserIdle: false,
  isConnected: false,
  hasInternet: true,
  statusTime: Date.now()
}
/* create slice with reducers */
export const pubSubSlice = createSlice({
  name: 'pubsub',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
      state.isConnected = state.status == ConnectionState.Connected;
      state.statusTime = Date.now();
    },
    setHasInternet: (state, action: PayloadAction<boolean>) => {
      state.hasInternet = action.payload;
    },
    setIsUserIdle: (state, action: PayloadAction<boolean>) => {
      state.isUserIdle = action.payload;
    },
  },
})
/* export actions */
export const { setStatus, setIsUserIdle } = pubSubSlice.actions
/* export reducer */
export default pubSubSlice.reducer