import { Card, Col, Row, Rate, Spin } from 'antd';
import Layout from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FileClient } from '../../../lib/api';
import {CourseClient} from '../../../lib/api';
import { Gradients, Helpers } from '../../../lib/utils';
import { Course, CourseDataType, Language, UserProgress } from '../../../models';
import { HubButton, HubGradientText, HubPriceLabel, HubTitle } from '../../commons/misc/front/hubComponents';
import stringHash from "@sindresorhus/string-hash";
import { block } from '../../commons/misc/front/miscSlice';
import thumbnailBanner from '../../../media/images/banner-thumbnail.png';
import {BsFillCartCheckFill} from 'react-icons/bs';
import AnalyticsClient, { AnalyticsEvent } from '../../../lib/api/analytics_client';
import { isMobile } from 'react-device-detect';

const gridStyle: React.CSSProperties = {
  height: '70px',
  display: 'flex',
  padding: '12px',
  textAlign: 'center',
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  alignContent: 'center'
}

const CourseList: React.FC = () =>  {
  let navigate = useNavigate();
  let [lang] = useState<Language>(Helpers.getUserLang());
  let [isStaff] = useState<boolean>(Helpers.isUserStaff());
  let [courses, setCourses] = useState<Course[]>([]);
  let [thumbnails, setThumbnails] =useState<Map<string, string>>(new Map());
  let [thumbnailReady, setThumbnailReady] =useState<Map<string, boolean>>(new Map());

  /* runs when component is mount */
  useEffect(() => {
    (async () => {
      let [courses, progress] = await block(async ()=>{
        let requests = [
          fetchCourses(),
          CourseClient.getCourseProgress()
        ]
        return await Promise.all(requests);
      }); 
      /* transfer progress for this user */
      courses = assignUserProgress(courses, progress);
      /* resolve thumbnail */
      let thumbnails, thumbnailStatus;
      [thumbnails, thumbnailStatus] = await generateThumbnailObjects(courses);
      setCourses(courses);
      setThumbnails(thumbnails);
      setThumbnailReady(thumbnailStatus);
    })();
  }, []);

  /* assing user progress to list */
  const assignUserProgress = (courses:Course[], progress: UserProgress[])=>{
    /* first create a map */

    let m = progress.reduce((map:any,p)=>{
      return {...map, [p.parentId]: p};
    },{});
    /* set progress to all courses */
    for(let c of courses){
      c.progress = m[c.id];
    }
    return courses;
  }

  const generateThumbnailObjects = async (courses:Course[]): Promise<[Map<string, string>, Map<string, boolean>]>=>{
    let requests = [];
    for(let c of courses){
      let key = Helpers.buildCourseMediaURL(c, CourseDataType.course);
      requests.push(FileClient.getStoredMedia(key,false));
    }
    let keys =  await Promise.all(requests);
    let kMap = new Map<string, string>();
    let rMap = new Map<string, boolean>();
    for(let i = 0; i < courses.length; i++){
      kMap.set(courses[i].id, keys[i]);
      rMap.set(courses[i].id, false);
    }
    return [kMap, rMap];
  }
  /* fetch courses from backend */
  const fetchCourses = async (): Promise<Course[]>=>{
    return await CourseClient.getCourses();
  }

  const thumbnailLoaded = (id: string) =>{
    setThumbnailReady(prevMap => {
      let m = new Map(prevMap);
      m.set(id, true);
      return m;
    });
  }

  const renderCourses = ()=>{
    
    let imgWidth = 342;
    let imgHeight = 226;
    imgWidth = isMobile? imgWidth * .90:imgWidth

    const enrolledStyles: React.CSSProperties = {
      position: 'absolute',
      top: '5%',
      display: 'block',
      right: '5%'
    }

    const selectCourse = (id: string, title: string)=>{
      navigate(id);
      AnalyticsClient.record(AnalyticsEvent.openCourse,{
        title,
        id
      });
    }
    
    return courses.map((c)=>{

      /* if the course is not published and is not staff, return nothing */
      if(!c.published && !isStaff){
        return null;
      }
      /* set opacity in case it is unlisted */
      const opacity = !c.published && isStaff? 0.5:1
      /* return card component */
      return <Col key={c.id}>
        <div style={{borderRadius: 16, overflow: 'auto', border: "1px #FF8642 solid"}}>
        <Card
          onClick={()=>selectCourse(c.id, c.title[lang])}
          hoverable
          style={{ width: imgWidth, opacity: opacity }}
            cover={<Spin spinning={!thumbnailReady.get(c.id)} style={{width: imgWidth, height: imgHeight}}>
                  <img alt={'bannner'} style={{display: thumbnailReady.get(c.id)?'none':''}} width={imgWidth} height={imgHeight} src={thumbnailBanner} />
                  <img alt={c.title[lang]} style={{display: !thumbnailReady.get(c.id)?'none':''}}  width={imgWidth} height={imgHeight} onLoad={()=>thumbnailLoaded(c.id)} src={thumbnails.get(c.id)} />
                  <HubButton  style={enrolledStyles} size='small' type='default'>{c.studentCount} enrolled</HubButton>
                </Spin>      
              }
          >
          <Card.Grid hoverable={false} style={{...gridStyle,width:'100%'}}>
              <h3>{c.title[lang]}</h3>
          </Card.Grid>
          <Card.Grid hoverable={false} style={{...gridStyle, width:'35%'}}>
              {!c.progress?<HubPriceLabel price={c.price} size={24} showCents/>:
                <BsFillCartCheckFill color='#FF8642' size={24}/>
              } 
          </Card.Grid>
          <Card.Grid hoverable={false} style={{...gridStyle, width:'65%', color:'lightgray', padding: 6}}>
              <Row justify='center' align='middle'>
                <Col>
                  <Rate disabled allowHalf value={c.ratingTotal/(c.ratingCount || 1)} />
                </Col>
                <Col style={{paddingLeft: 10}}>
                  (<HubTitle size='large'>{c.ratingCount}</HubTitle>)
                </Col>
              </Row>
          </Card.Grid>
        </Card>
        </div>
      </Col>
    })
  }

 return  (<Layout style={{margin:12}}>
    <Row gutter={[12, 12]} justify={isMobile?'center':'start'}>
      {renderCourses()}
    </Row>
 </Layout>);
 
};

export default CourseList;