import React, {useState} from 'react';
import { Layout, Typography, Row, Col, Input} from 'antd';
import { StripeClient } from '../../../../lib/api';
import { block } from '../../../commons/misc/front/miscSlice';
import { HubButton, HubModal } from '../../../commons/misc/front/hubComponents';
import { Attention } from '../../../../lib/utils/notification';
import sadShiba from '../../../../media/animations/sad-shiba.json';
import Lottie from 'lottie-react';

const { TextArea } = Input;
const { Text, Title } = Typography;
const { Content } = Layout;

const CancelSubscriptionModal =(props: any)=> {

  const [reason, setReason] = useState("");

  const cancelSubscription = async () => {
    try {

      await block(async () => {
        await StripeClient.cancel_subscription(reason);
        /* TODO: SEND CANCELLATION REASON */
        

      });
      Attention.notifySuccess(
        "RainHub Premium Cancelled",
        "Continue to enjoy RainHub's premium features until the end of the period 😢."
      );

      props?.closeCancelSubModal(true);

    } catch (error) {
      console.log(error);
      Attention.notifyError(
        'Cancelling Subscription failed',
        'Couldn\'t cancel your subscription. Please try again later.'
      );
    }
  }

  const onReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
  };

  return (
    <React.Fragment>
      <HubModal title={"Cancelling Subscription"}
                open={props.showCancelSubModal || false} 
                onCancel={()=>props.closeCancelSubModal()} 
                showOk={false}>
        <Layout>
          <Content style={{backgroundColor: "white"}}>
            <Row>
              <Col style={{textAlign: "center"}} span={24}>
                <Lottie loop={true} animationData={sadShiba} style={{height: 150, marginTop:-35}}/>
                <Title level={4}>We're sad to see you go...<br/>Please tell us why...</Title>
                <TextArea showCount minLength={25} maxLength={200} style={{ height: 100 }} onChange={onReasonChange} />
              </Col>
            </Row>
            <Row>
              <Col style={{textAlign: "center"}} span={24}>
                <br />
                <HubButton disabled={reason.length < 12} onClick={cancelSubscription} >Confirm Cancellation</HubButton>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <Col>
                <Text type='secondary'>
                    By pressing "Confirm Cancellation" you authorize RainHub, LLC to cancel your subscription. You can continue to
                    access all subscription features until the current period end date of the cancelled subscription.
                </Text>
              </Col>
            </Row>
          </Content>
        </Layout>
      </HubModal>
    </React.Fragment>
  );
}

export default CancelSubscriptionModal;