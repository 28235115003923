
import imageCompression from 'browser-image-compression';


export default class CanvasUtils {

  public static previewImage(file: any){
    const fr = new FileReader();
    fr.onload =  ()=> {
        const image = new Image();
        image.src = fr.result as string;
        const w = window.open("");
        w?.document.write(image.outerHTML);
    }
    fr.readAsDataURL(file.originFileObj);
  }
  public static previewVideo(file: any){
    const objectURL = URL.createObjectURL(file.originFileObj);
    const video = document.createElement('video');
    video.controls = true;
    video.src = objectURL;
    let w = window.open("");
    w?.document.write(video.outerHTML);
  }
  public static async blobToBase64(b:Blob): Promise<string> {
    return await new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(b);
    });
  }
  public static async blobToImage(b:Blob): Promise<HTMLImageElement> {
    return await new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image;
        img.onload = ()=> {
          resolve(img);
        };
        img.src = reader.result as string;
      };
      reader.readAsDataURL(b);
    });
  }
  public static async base64ToBlob(url:string): Promise<Blob> {
    return await (await fetch(url)).blob(); 
  }

  public static async compressImage(blob:Blob, maxWidth:number,maxHeight:number, maxMB: number, useJpeg: boolean= false): Promise<File> {

    console.log(`originalFile size ${blob.size / 1024 / 1024} MB`);
    const options = {
      maxSizeMB: maxMB,
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      fileType: useJpeg?'image/jpeg':'image/png',
      useWebWorker: true
    }

    const compressedFile = await imageCompression(blob as any, options);
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

    return compressedFile;

  }
  public static createImage(url:string): Promise<HTMLImageElement>{
    return   new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', (error) => reject(error))
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
    })
  }

  public static getRadianAngle(degreeValue:number) {
    return (degreeValue * Math.PI) / 180
  }

  public static rotateSize(width:number, height:number, rotation:number){
    const rotRad = CanvasUtils.getRadianAngle(rotation)
    return {
      width:
        Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
  }

  public static async getCroppedImg (
    imageSrc: string,
    pixelCrop: any,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ):Promise<string>{
    const image  = await CanvasUtils.createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
  
    if (!ctx) {
      return ""
    }
  
    const rotRad = CanvasUtils.getRadianAngle(rotation)
  
    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = CanvasUtils.rotateSize(
      image.width,
      image.height,
      rotation
    )
  
    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight
  
    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)
  
    // draw rotated image
    ctx.drawImage(image, 0, 0)
  
    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    )
  
    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
  
    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)
  
    // As Base64 string
    // return canvas.toDataURL('image/jpeg');
  
    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((file:any) => {
        resolve(URL.createObjectURL(file))
      }, 'image/jpeg')
    })
  }

  public static async getRotatedImage (imageSrc:string, rotation = 0):Promise<string>{
    const image = await CanvasUtils.createImage(imageSrc);
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  
    const orientationChanged =
      rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270
    if (orientationChanged) {
      canvas.width = image.height
      canvas.height = image.width
    } else {
      canvas.width = image.width
      canvas.height = image.height
    }
  
    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate((rotation * Math.PI) / 180)
    ctx.drawImage(image, -image.width / 2, -image.height / 2)
  
    return new Promise((resolve) => {
      canvas.toBlob((file:any) => {
        resolve(URL.createObjectURL(file))
      }, 'image/png')
    })
  }
  
}



