export enum systemDefaults{
  emptyDynamoString = "*__empty__*",
  trueDynamoString = "true",
  falseDynamoString = "false",
}

/* AREA ENUMERATOR */
export enum Area {
  /* root area, this is reserved */
  ROOT = "root",
  /* home routes */
  HOME = "home",
  /* courses routes */
  COURSES = "courses",
  /* account routes */
  ACCOUNT = "account",
  ACCOUNT_PROFILE = "account/profile",
  ACCOUNT_SUBSCRIPTIONS = "account/subscriptions",
  ACCOUNT_WALLET = "account/wallet",
  ACCOUNT_SETTINGS = "account/settings",
  /* admin routes */
  ADMIN = "admin",
  ADMIN_CONTENT = "admin/content",
  ADMIN_CONTENT_COURSES = "admin/content/courses",
  ADMIN_CONTENT_EDUCATION = "admin/content/education",
  ADMIN_USERS = "admin/users",
  ADMIN_ACCESS = "admin/access",
  ADMIN_SETTINGS = "admin/settings",
  /* education routes */
  EDUCATION = "education",
  /* community routes */
  COMMUNITY = "community",
  /* register area */
  REGISTER = "register",
  CONFIRM =  "confirm"
}

/* SYSTEM ERROR TYPES */
export enum ErrorType {
  UNHANDLED = "unhandled",
  API = "api"
}

/* ROLE ENUMERATORS */
export enum Role {
  ADMIN = "Admin",
  STAFF = "Staff",
  EXEMPT = "Exempt",
  PREMIUM = "Premium",
  CUSTOMER = "Customer",
  PUBLIC = "Public"
}

/* ROLE ENUMERATORS */
export enum SpecialRoles {
  ADMIN = "Admin",
  STAFF = "Staff",
  EXEMPT = "Exempt",
  PUBLIC = "Public"
}

export const RoleHierarchy = new Map<Role, number>([
  [Role.ADMIN, 1],
  [Role.STAFF, 2],
  [Role.EXEMPT, 3],
  [Role.PREMIUM, 4],
  [Role.CUSTOMER, 5],
  [Role.PUBLIC, 6],
]);

/* ENDPOINTS ENUMERATORS */ 
export enum Endpoints {
  /* the placeholder special endpoint */
  PLACEHOLDER = "PLACEHOLDER",
  /* Queries */
  GET_PRODUCT_BY_ID = "getProductById",
  LIST_PRODUCTS = "listProducts",
  LIST_ORDERS = "listOrders",
  LIST_ORDER_ITEMS = "listOrderItems",
  VALIDATE_DISCORD_USER = "validateDiscordUser",
  LIST_PAYMENT_METHODS = "listPaymentMethods",
  GET_BANK_ACCOUNT_SETUP_INTENT = "getBankAccountSetupIntent",
  GET_CARD_SETUP_INTENT = "getCardSetupIntent",
  LIST_PENDING_BANK_ACCOUNTS = "listPendingBankAccounts",
  GET_CUSTOMER_SUBSCRIPTION = "getCustomerSubscription",
  GET_ACCESS_MAP = "getAccessMap",
  GET_ACCESS_ENTRIES = "getAccessEntries",
  GET_USER_CONFIGURATION = "getUserConfiguration",
  GET_COURSE_BY_ID = "getCourseById",
  GET_COURSES = "getCourses",
  GET_MEDIA_ACCESS_TOKEN = "getMediaAccessToken",
  GET_USER_PROGRESS_BY_TYPE = "getUserProgressByType",
  GET_PARENT_PROGRESS_BY_TEXTVALUE = "getParentProgressByTextValue",
  GET_RESOURCES = "getResources",
  GET_RESOURCE_BY_ID = "getResourceById",
  /* Mutations */
  FRONTEND_ERROR_REPORTER = "FrontEndErrorReporter",
  CREATE_PRODUCT = "createProduct",
  CREATE_USER_ORDER = "createUserOrder",
  SET_DEFAULT_PAYMENT_METHOD = "setDefaultPaymentMethod",
  ADD_CARD_TO_CUSTOMER = "addCardToCustomer",
  DELETE_PAYMENT_METHOD = "deletePaymentMethod",
  CREATE_PREMIUM_SUBSCRIPTION = "createPremiumSubscription",
  TOP_UP_PREMIUM_SUBSCRIPTION = "topUpPremiumSubscription",
  CANCEL_PREMIUM_SUBSCRIPTION = "cancelPremiumSubscription",
  PUT_ACCESS_ENTRIES = "putAccessEntries",
  PUT_USER_CONFIGURATION = "updateUserConfiguration",
  CONFIGURE_USER_PUBSUB = "configureUserPubSub",
  CREATE_COURSE = "createCourse",
  UPDATE_COURSE = "updateCourse",
  DELETE_COURSE = "deleteCourse",
  CREATE_COURSE_MODULE = "createCourseModule",
  UPDATE_COURSE_MODULE = "updateCourseModule",
  DELETE_COURSE_MODULE = "deleteCourseModule",
  CREATE_COURSE_MODULE_ELEMENT = "createCourseModuleElement",
  UPDATE_CUSTOMER = "updateCustomer",
  UPDATE_CUSTOMER_EMAIL = "updateCustomerEmail",
  UPDATE_COURSE_MODULE_ELEMENT = "updateCourseModuleElement",
  DELETE_COURSE_MODULE_ELEMENT = "deleteCourseModuleElement",
  CHARGE_COURSE_TO_CUSTOMER = "chargeCourseToCustomer",
  UPDATE_USER_PROGRESS = "updateUserProgress",
  REPORT_USER_COURSE_ACTION = "reportUserCourseAction",
  CREATE_RESOURCE = "createResource",
  UPDATE_RESOURCE = "updateResource",
  DELETE_RESOURCE = "deleteResource",
  SET_RESOURCE_AS_VISITED = "setResourceAsVisited",

}

/* the area endpoint map */
export const AreaEndpointMap = new Map<string, Set<string>>([
  /* home map */
  [Area.ROOT, new Set<string>([
    Endpoints.PLACEHOLDER,
    Endpoints.FRONTEND_ERROR_REPORTER,
    Endpoints.GET_ACCESS_MAP,
    Endpoints.GET_USER_CONFIGURATION,
    Endpoints.PUT_USER_CONFIGURATION,
    Endpoints.CONFIGURE_USER_PUBSUB,
    Endpoints.GET_USER_PROGRESS_BY_TYPE,
    Endpoints.GET_PARENT_PROGRESS_BY_TEXTVALUE,
    Endpoints.UPDATE_USER_PROGRESS
  ])],
  /* courses map */
  [Area.COURSES, new Set<string>([
    Endpoints.PLACEHOLDER,
    Endpoints.GET_COURSES,
    Endpoints.GET_COURSE_BY_ID,
    Endpoints.GET_MEDIA_ACCESS_TOKEN,
    Endpoints.CHARGE_COURSE_TO_CUSTOMER,
    Endpoints.REPORT_USER_COURSE_ACTION
  ])],
  /* account map */
  [Area.ACCOUNT, new Set<string>([
    Endpoints.PLACEHOLDER
  ])],
  [Area.ACCOUNT_PROFILE, new Set<string>([
    Endpoints.PLACEHOLDER,
    Endpoints.UPDATE_CUSTOMER,
    Endpoints.UPDATE_CUSTOMER_EMAIL
  ])],
  [Area.ACCOUNT_SUBSCRIPTIONS, new Set<string>([
    Endpoints.PLACEHOLDER,
    Endpoints.GET_CUSTOMER_SUBSCRIPTION,
    Endpoints.CREATE_PREMIUM_SUBSCRIPTION,
    Endpoints.TOP_UP_PREMIUM_SUBSCRIPTION,
    Endpoints.CANCEL_PREMIUM_SUBSCRIPTION
  ])],
  [Area.ACCOUNT_WALLET, new Set<string>([
    Endpoints.PLACEHOLDER,
    Endpoints.LIST_PAYMENT_METHODS,
    Endpoints.GET_BANK_ACCOUNT_SETUP_INTENT,
    Endpoints.GET_CARD_SETUP_INTENT,
    Endpoints.LIST_PENDING_BANK_ACCOUNTS,
    Endpoints.ADD_CARD_TO_CUSTOMER,
    Endpoints.SET_DEFAULT_PAYMENT_METHOD,
    Endpoints.DELETE_PAYMENT_METHOD
  ])],
  [Area.ACCOUNT_SETTINGS, new Set<string>([
    Endpoints.PLACEHOLDER
  ])],
  /* admin map */
  [Area.ADMIN, new Set<string>([
    Endpoints.PLACEHOLDER
  ])],
  [Area.ADMIN_CONTENT, new Set<string>([
    Endpoints.PLACEHOLDER,
  ])],
  [Area.ADMIN_CONTENT_COURSES, new Set<string>([
    Endpoints.PLACEHOLDER,
    Endpoints.CREATE_COURSE,
    Endpoints.UPDATE_COURSE,
    Endpoints.DELETE_COURSE,
    Endpoints.CREATE_COURSE_MODULE,
    Endpoints.UPDATE_COURSE_MODULE,
    Endpoints.DELETE_COURSE_MODULE,
    Endpoints.CREATE_COURSE_MODULE_ELEMENT,
    Endpoints.UPDATE_COURSE_MODULE_ELEMENT,
    Endpoints.DELETE_COURSE_MODULE_ELEMENT,
    Endpoints.GET_COURSES,
    Endpoints.GET_COURSE_BY_ID
  ])],
  [Area.ADMIN_CONTENT_EDUCATION, new Set<string>([
    Endpoints.PLACEHOLDER,
    Endpoints.GET_RESOURCES,
    Endpoints.GET_RESOURCE_BY_ID,
    Endpoints.CREATE_RESOURCE,
    Endpoints.UPDATE_RESOURCE,
    Endpoints.DELETE_RESOURCE,
    Endpoints.SET_RESOURCE_AS_VISITED
  ])],
  [Area.ADMIN_USERS, new Set<string>([
    Endpoints.PLACEHOLDER
  ])],
  [Area.ADMIN_ACCESS, new Set<string>([
    Endpoints.PLACEHOLDER,
    Endpoints.GET_ACCESS_ENTRIES,
    Endpoints.PUT_ACCESS_ENTRIES
  ])],
  [Area.ADMIN_SETTINGS, new Set<string>([
    Endpoints.PLACEHOLDER
  ])],
  /* education map */
  [Area.EDUCATION, new Set<string>([ 
    Endpoints.PLACEHOLDER,
    Endpoints.GET_RESOURCES,
    Endpoints.GET_RESOURCE_BY_ID,
    Endpoints.SET_RESOURCE_AS_VISITED
  ])],
  /* home map */
  [Area.HOME, new Set<string>([
    Endpoints.PLACEHOLDER
  ])],
  /* home map */
  [Area.COMMUNITY, new Set<string>([
    Endpoints.PLACEHOLDER
  ])],
  /* regiter map */
  [Area.REGISTER, new Set<string>([
    Endpoints.PLACEHOLDER,
    Endpoints.VALIDATE_DISCORD_USER
  ])],
]);
/* files access levels */
export enum FILE_ACCESS_LEVEL {
  PUBLIC = "public",
  PROTECTED = "protected",
  PRIVATE = "private"
}