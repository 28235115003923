import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import { AccessClient } from '../../../../lib/api';
import {  User, AccessMapping, UserConfiguration, ProfileImage } from '../../../../models';
/* define state */
interface AuthState {
  user: User | null,
  access: AccessMapping | null,
  config: UserConfiguration | null
}
/* create initial state object */
const initialState: AuthState = {
  user: null,
  access: null,
  config: null
}
/* create slice with reducers */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetSession: () => {
      return {...initialState};
    },
    setUser: (state, action: PayloadAction<User|null>) => {
      state.user = action.payload;
    },
    setProfileImage: (state, action: PayloadAction<ProfileImage>) => {
      state.user = {
        ...state.user as User,
        profileImage: action.payload
      }
    },
    setAccess: (state, action: PayloadAction<AccessMapping>) => {
      state.access = action.payload;
    },
    setUserConfig: (state, action: PayloadAction<UserConfiguration>) => {
      state.config = action.payload;
    },
  },
})
/* export actions */
export const { setUser,setAccess, setUserConfig, resetSession } = authSlice.actions
/* export reducer */
export default authSlice.reducer